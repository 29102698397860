import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import "./index.css";
import App from "./App";
import { Provider } from "react-redux";
import { ProSidebarProvider } from "react-pro-sidebar";
import { store } from './config/store';
import "./config/validacionFormularioConfig";
import registerServiceWorker from './serviceWorker';
import axios from "axios";
import { fetchClearUserSession } from "./action";

const root = ReactDOM.createRoot(document.getElementById("root"));

const UNAUTHORIZED = 401;
const {dispatch} = store; // direct access to redux store.

axios.interceptors.response.use(function (response) {
  // Any status code that lie within the range of 2xx cause this function to trigger
  // Do something with response data
  return response;
}, function (error) {
  // Any status codes that falls outside the range of 2xx cause this function to trigger
  // Do something with response error
  const {status} = error.response;
  if (status === UNAUTHORIZED) {
    localStorage.removeItem("user");
    localStorage.removeItem("bearer");
  
    localStorage.removeItem("filtroPropiedadTipoOperacion");
    localStorage.removeItem("filtroPropiedadTipoPropiedad");
    localStorage.removeItem("filtroPropiedadComuna");
    localStorage.removeItem("filtroPropiedadCodigo");
    localStorage.removeItem("filtroPropiedadEstado");
    localStorage.removeItem("filtroPropiedadTipoMoneda");
    localStorage.removeItem("filtroPropiedadBuscador");
    dispatch(fetchClearUserSession());
    setTimeout(() => {
      window.location.href = "/login";
    }, 500);
  }
  return Promise.reject(error);
});

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Provider store={store}>
        <ProSidebarProvider>
          <App />
        </ProSidebarProvider>
      </Provider>
    </BrowserRouter>
  </React.StrictMode>
);

registerServiceWorker();
