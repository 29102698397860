//TODO: Cambiar nombre de este componente
//TODO: juntar ambos componentes prospectos en tabs
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import FiltroProspectos from "../FiltroProspectos";
import { getProspectos, putProspectoVA } from "../../../action";
import { useEffect } from "react";
import { useState } from "react";
import {
  Backdrop,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  styled,
} from "@mui/material";
import FormProspecto from "../FormProspecto";
import ProspectoNavegacionMobile from "../ProspectoNavegacionMobile";
import Swal from "sweetalert2";
import { FilaProspecto } from "../FilaProspecto";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";

const isMobile =
  !!navigator.userAgent.match(/iphone|android|blackberry/gi) || false;

const columnsNonMobile = [
  { id: "nombre", label: "Nombre", minWidth: 170, align: "center" },
  { id: "tipo", label: "Tipo", minWidth: 80, align: "center" },
  { id: "estado", label: "Estado", minWidth: 100, align: "center" },
  { id: "contacto", label: "Contacto", minWidth: 140, align: "center" },
  { id: "propietario", label: "Propietario", minWidth: 70, align: "center" },
  { id: "perfil", label: "Perfil", minWidth: 80, align: "center" },
  { id: "acciones", label: "Acciones", minWidth: 80, align: "center" },
];

const columnsMobile = [
  { id: "nombre", label: "Nombre", minWidth: 90, align: "center" },
  { id: "contacto", label: "Contacto", minWidth: 160, align: "center" },
  { id: "acciones", label: "Acciones", minWidth: 1, align: "center" },
];

const columns = isMobile ? columnsMobile : columnsNonMobile;

const StyledTableHead = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#00a62b",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const ListPropsPropietario = () => {  
  const [filtroValues, setFiltroValues] = useState({
    tipoOperacion: localStorage.getItem('filtroProspectoTipoOperacion')
      ? localStorage.getItem('filtroProspectoTipoOperacion') : "",
    tipoPropiedad: localStorage.getItem('filtroProspectoTipoPropiedad')
    ? localStorage.getItem('filtroProspectoTipoPropiedad') : "",
    comuna: localStorage.getItem('filtroProspectoComuna')
    ? localStorage.getItem('filtroProspectoComuna') : "",
    dormitorios: localStorage.getItem('filtroProspectoDormitorios')
      ? localStorage.getItem('filtroProspectoDormitorios') : 0,
    banios: localStorage.getItem('filtroProspectoBanios')
    ? localStorage.getItem('filtroProspectoBanios') : 0,
  });
  const dispatch = useDispatch();
  const prospectos = useSelector(
    (state) => state.app.prospectosVA && state.app.prospectosVA.results
  );
  const requestGetProspectos = useSelector(
    (state) => state.app.requestGetProspectosVA
  );
  const data = useSelector((state) => state.app.prospectosVA);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [isFormModalOpen, setFormModalOpen] = useState(false);

  const handleOpenFormModal = () => {
    setFormModalOpen(true);
  };
  const handleCloseFormModal = () => {
    setFormModalOpen(false);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  const requestStateUpdate = useSelector(
    (state) => state.app.requestUpdateProspecto
  );

  const requestStateDelete = useSelector(
    (state) => state.app.requestDeleteProspecto
  );

  useEffect(() => {
    dispatch(getProspectos("va", page, rowsPerPage, filtroValues.tipoOperacion, filtroValues.tipoPropiedad, filtroValues.comuna, filtroValues.dormitorios, filtroValues.banios));
  }, [page, rowsPerPage]);

  const reloadProspectos = () => {
    dispatch(getProspectos("va", page, rowsPerPage, filtroValues.tipoOperacion, filtroValues.tipoPropiedad, filtroValues.comuna, filtroValues.dormitorios, filtroValues.banios));
  };

  const [openBackdrop, setOpenBackdrop] = useState(true);
  const handleCloseBackdrop = () => setOpenBackdrop(false);

  const pausarProspecto = (prospecto) => {
    Swal.fire({
      title: `Pausar prospecto`,
      text: `Confirma que desea pausar el prospecto ${prospecto.nombres} ${prospecto.apellidos}`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Confirmar",
    }).then((result) => {
      if (result.value) {
        const data = {
          ...prospecto,
          estadoProcesoProspecto: 4,
        };
        dispatch(putProspectoVA(prospecto.id, data));
      }
    });
  };

  if (requestStateUpdate === "SUCCESS") {
    Swal.fire({
      title: "Prospecto actualizado",
      text: "Se ha actualizado el prospecto exitosamente",
      icon: "success",
    }).then((result) => {
      if (result.value || result.dismiss === "backdrop") {
        reloadProspectos();
        handleCloseBackdrop();
      }
    });
  }

  if (requestStateDelete === "SUCCESS") {
    Swal.fire({
      title: "Prospecto eliminado",
      text: "Se ha eliminado el prospecto exitosamente",
      icon: "success",
    }).then((result) => {
      if (result.value || result.dismiss === "backdrop") {
        reloadProspectos();
        handleCloseBackdrop();
      }
    });
  }

  return (
    <div className="contenido">
      <div className="header">
        <h2>Prospectos</h2>
        <button className="boton" onClick={() => handleOpenFormModal()}>
          Agregar Prospecto
        </button>
      </div>
      {isMobile ? (
        <ProspectoNavegacionMobile />
      ) : (
        <div className="opciones" style={{ borderBottom: "1px solid" }}>
          <h3 className="vendedor">
            <Link
              id={
                window.location.pathname.includes("comprador")
                  ? "notActive1"
                  : "active1"
              }
              to="/prospectos/vendedor"
            >
              Vendedor/Arrendador
            </Link>
          </h3>
          <h3 className="comprador">
            <Link
              id={
                window.location.pathname.includes("comprador")
                  ? "active1"
                  : "notActive1"
              }
              to="/prospectos/comprador"
            >
              Comprador/Arrendatario
            </Link>
          </h3>
        </div>
      )}

      <FiltroProspectos
        page={page}
        pageSize={rowsPerPage}
        tipoProspecto={"va"}
        filtroValues={filtroValues}
        setFiltroValues={setFiltroValues}
      />
      <div className="main2">
        {/*
        <div className="barraNav2">
          <div>
            <select className="seleccionador">
              <option>Ordenar por </option>
            </select>{" "}
            <input type="text" placeholder="Buscar" className="buscar"></input>
            <SearchIcon />
          </div>
        </div>
            */}

        {(requestStateUpdate === "LOADING" || requestStateDelete === "LOADING") && (
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={openBackdrop}
            onClick={handleCloseBackdrop}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        )}
        {requestGetProspectos === "LOADING" && <CircularProgress />}
        {requestGetProspectos === "IDLE" && prospectos && (
          <Paper component={Paper} style={{ marginBottom: "2rem" }}>
            <TableContainer>
              <Table size={isMobile ? "small" : "medium"}>
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <StyledTableHead
                        key={column.id}
                        align={column.align}
                        style={{ minWidth: column.minWidth }}
                      >
                        {column.label}
                      </StyledTableHead>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {prospectos.length === 0 ? (
                    <div>No se han encontrado resultados para su búsqueda</div>
                  ) : (
                    prospectos.map((prospecto, i) => (
                      <FilaProspecto
                        prospecto={prospecto}
                        i={i}
                        isMobile={isMobile}
                        tipo="va"
                        handleOpenFormModal={handleOpenFormModal}
                        pausarProspecto={pausarProspecto}
                      />
                    ))
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            {requestGetProspectos === "IDLE" &&
              prospectos &&
              prospectos.length > 0 && (
                <TablePagination
                  component="div"
                  count={data && data.totalResults}
                  page={page}
                  onPageChange={handleChangePage}
                  rowsPerPage={rowsPerPage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  SelectProps={{
                    inputProps: { "aria-label": "Cantidad" },
                    native: true,
                  }}
                  labelRowsPerPage={""}
                />
              )}
          </Paper>
        )}

        <FormProspecto
          isOpen={isFormModalOpen}
          handleClose={handleCloseFormModal}
          reloadMethod={reloadProspectos}
        />
      </div>
    </div>
  );
};
export default ListPropsPropietario;
