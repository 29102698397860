import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Grid,
  Modal,
} from "@mui/material";
import { Formik, Form, Field } from "formik";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { postRequerimiento, putRequerimiento } from "../../../action";
import Swal from "sweetalert2";

const isMobile =
  !!navigator.userAgent.match(/iphone|android|blackberry/gi) || false;

const style = {
  position: "absolute",
  top: "0px",
  left: "50%",
  transform: "translate(-50%, 0%)",
  width: 500,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  //maxHeight: '90%'
};

const styleMobile = {
  position: "absolute",
  top: "0px",
  left: "0px",
  //transform: "translate(-50%, 0%)",
  width: "100vw",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  //maxHeight: '90%'
};

const FormRequerimiento = ({
  isOpen,
  idProspecto,
  handleClose,
  reloadMethod,
  initialValues,
}) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const request = useSelector((state) => state.app.requestPostRequerimiento);
  const requestUpdate = useSelector(
    (state) => state.app.requestPutRequerimiento
  );
  const regiones = useSelector(
    (state) =>
      state.app.itemRegiones &&
      state.app.itemRegiones.length > 0 &&
      state.app.itemRegiones[0].regiones
  );
  const requestGetRegiones = useSelector(
    (state) => state.app.requestGetRegiones
  );
  const handleSubmit = (formData) => {
    if (initialValues && initialValues.id) {
      dispatch(putRequerimiento(initialValues.id, formData));
    } else {
      dispatch(postRequerimiento(formData));
    }
  };
  const [showBackdrop, setShowBackdrop] = useState(false);
  const handleShowBackdrop = () => {
    setShowBackdrop(false);
  };

  useEffect(() => {
    if (request === "LOADING" || requestUpdate === "LOADING") {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [request, requestUpdate]);

  if (request === "SUCCESS") {
    //handleClose();
    Swal.fire({
      title: "Requerimiento agregado",
      text: "Se ha agregado el requerimiento exitosamente",
      icon: "success",
    }).then((result) => {
      if (result.value || result.dismiss === "backdrop") {
        handleClose();
        reloadMethod();
      }
    });
  }

  if (requestUpdate === "SUCCESS") {
    //handleClose();
    Swal.fire({
      title: "Requerimiento actualizado",
      text: "Se ha actualizado el requerimiento exitosamente",
      icon: "success",
    }).then((result) => {
      if (result.value || result.dismiss === "backdrop") {
        handleClose();
        reloadMethod();
      }
    });
  }

  return (
    <Modal
      disableScrollLock={false}
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{
        overflowY: "auto",
        top: isMobile ? "0px" : "10px",
        maxHeight: isMobile ? "100%" : "95%",
      }}
    >
      <Box sx={isMobile ? styleMobile : style}>
        <h4>Agregar requerimiento</h4>
        {isLoading && (
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={true}
            onClick={handleShowBackdrop}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        )}
        <Formik
          initialValues={{
            idProspecto,
            tipoPropiedad: initialValues && initialValues.tipoPropiedad,
            tipoOperacion: initialValues && initialValues.tipoOperacion,
            dormitorios: initialValues && initialValues.dormitorios,
            banios: initialValues && initialValues.banios,
            estacionamientos: initialValues && initialValues.estacionamientos,
            superficieUtil: initialValues && initialValues.superficieUtil,
            superficieTotal: initialValues && initialValues.superficieTotal,
            idRegion: initialValues && initialValues.idRegion,
            comunaPreferencialUno:
              initialValues && initialValues.comunaPreferencialUno,
            comunaPreferencialDos:
              initialValues && initialValues.comunaPreferencialDos,
            comunaPreferencialTres:
              initialValues && initialValues.comunaPreferencialTres,
            precio: initialValues && initialValues.precio,
            tipoMoneda: initialValues && initialValues.tipoMoneda,
          }}
          onSubmit={handleSubmit}
        >
          {({ values, handleSubmit }) => (
            <Form onSubmit={handleSubmit}>
              <Grid container spacing={1} className="alignLeft" sx={{ mt: 2 }}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <h5>Características</h5>
                </Grid>
              </Grid>
              <Grid container spacing={1}>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <label className="milabel">Tipo propiedad</label>
                  <Field name="tipoPropiedad" as="select" className="miSelect">
                    <option value={""}>- Seleccione -</option>
                    <option value={"Departamento"}>Departamento</option>
                    <option value={"Casa"}>Casa</option>
                  </Field>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <label className="milabel">Operación</label>
                  <Field name="tipoOperacion" as="select" className="miSelect">
                    <option value={""}>- Seleccione -</option>
                    <option value={0}>Arriendo</option>
                    <option value={1}>Venta</option>
                  </Field>
                </Grid>
              </Grid>
              <Grid container spacing={1}>
                <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                  <label className="milabel">Dormitorios</label>
                  <Field name="dormitorios" type="text" className="miSelect" />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                  <label className="milabel">Baños</label>
                  <Field name="banios" type="text" className="miSelect" />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                  <label className="milabel">Estacionamientos</label>
                  <Field
                    name="estacionamientos"
                    type="text"
                    className="miSelect"
                  />
                </Grid>
              </Grid>
              <Grid container spacing={1}>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <label className="milabel">Superficie útil</label>
                  <Field
                    name="superficieUtil"
                    type="text"
                    className="miSelect"
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <label className="milabel">Superficie total</label>
                  <Field
                    name="superficieTotal"
                    type="text"
                    className="miSelect"
                  />
                </Grid>
              </Grid>
              <Grid container spacing={1} className="alignLeft" sx={{ mt: 2 }}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <h5>Ubicación</h5>
                </Grid>
              </Grid>
              {requestGetRegiones == "IDLE" && (
                <>
                  <Grid container spacing={1}>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                      <label className="milabel">Región</label>
                      <Field name="idRegion" as="select" className="miSelect">
                        <option value={""}>- Seleccione -</option>
                        {regiones &&
                          regiones.map((r) => (
                            <option value={r.numero}>{r.region}</option>
                          ))}
                      </Field>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                      <label className="milabel">
                        Comuna de preferencia uno
                      </label>
                      <Field
                        name="comunaPreferencialUno"
                        as="select"
                        className="miSelect"
                      >
                        <option value={""}>- Seleccione -</option>
                        {regiones &&
                          values &&
                          values.idRegion &&
                          regiones.some((u) => u.numero == values.idRegion) &&
                          regiones.find((u) => u.numero == values.idRegion)
                            .comunas &&
                          regiones.find((u) => u.numero == values.idRegion)
                            .comunas != undefined &&
                          regiones.find((u) => u.numero == values.idRegion)
                            .comunas != null &&
                          regiones.find((u) => u.numero == values.idRegion)
                            .comunas.length > 0 &&
                          regiones
                            .find((u) => u.numero == values.idRegion)
                            .comunas.map((c) => <option value={c}>{c}</option>)}
                      </Field>
                    </Grid>
                  </Grid>
                  <Grid container spacing={1}>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                      <label className="milabel">
                        Comuna de preferencia dos
                      </label>
                      <Field
                        name="comunaPreferencialDos"
                        as="select"
                        className="miSelect"
                      >
                        <option value={""}>- Seleccione -</option>
                        {regiones &&
                          values &&
                          values.idRegion &&
                          regiones.some((u) => u.numero == values.idRegion) &&
                          regiones.find((u) => u.numero == values.idRegion)
                            .comunas &&
                          regiones.find((u) => u.numero == values.idRegion)
                            .comunas &&
                          regiones.find((u) => u.numero == values.idRegion)
                            .comunas.length > 0 &&
                          regiones
                            .find((u) => u.numero == values.idRegion)
                            .comunas.map((c) => <option value={c}>{c}</option>)}
                      </Field>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                      <label className="milabel">
                        Comuna de preferencia tres
                      </label>
                      <Field
                        name="comunaPreferencialTres"
                        as="select"
                        className="miSelect"
                      >
                        <option value={""}>- Seleccione -</option>
                        {regiones &&
                          values &&
                          values.idRegion &&
                          regiones.some((u) => u.numero == values.idRegion) &&
                          regiones.find((u) => u.numero == values.idRegion)
                            .comunas &&
                          regiones.find((u) => u.numero == values.idRegion)
                            .comunas &&
                          regiones.find((u) => u.numero == values.idRegion)
                            .comunas.length > 0 &&
                          regiones
                            .find((u) => u.numero == values.idRegion)
                            .comunas.map((c) => <option value={c}>{c}</option>)}
                      </Field>
                    </Grid>
                  </Grid>
                </>
              )}
              <Grid container spacing={1} className="alignLeft" sx={{ mt: 2 }}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <h5>Precio propiedad</h5>
                </Grid>
              </Grid>
              <Grid container spacing={1}>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <label className="milabel">Tipo moneda</label>
                  <Field name="tipoMoneda" as="select" className="miSelect">
                    <option value={""}>- Seleccione -</option>
                    <option value={"CLP"}>CLP</option>
                    <option value={"UF"}>UF</option>
                  </Field>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <label className="milabel">Precio</label>
                  <Field name="precio" type="text" className="miSelect" />
                </Grid>
              </Grid>
              <Grid container justifyContent="flex-end" sx={{ mt: 2 }}>
                <Grid item>
                  <Button onClick={handleClose} className="main-btn">
                    Cancelar
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    type="submit"
                    className="main-btn"
                    variant="contained"
                    color="success"
                  >
                    Guardar
                  </Button>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </Box>
    </Modal>
  );
};

export default FormRequerimiento;
