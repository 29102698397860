/** @format */

import api from "../api";
import { REQUEST_STATE, STANDARD_ERROR_MESSAGES } from "../utils/constants";

export const RECEIVE_SUBIR_PROPIEDAD = "RECEIVE_SUBIR_PROPIEDAD",
  REQUEST_SUBIR_PROPIEDAD = "REQUEST_SUBIR_PROPIEDAD",
  ADD_CLIENTE = "ADD_CLIENTE",
  BEGIN_ALL_CLIENTES = "BEGIN_ALL_CLIENTES",
  GET_ALL_CLIENTES = "GET_ALL_CLIENTES",
  ADD_USUARIO = "ADD_USUARIO",
  GET_USER_BY_ROL = "GET_USER_BY_ROL",
  ADD_HORARIO_EMP = "ADD_HORARIO_EMP",
  GET_ALL_PROPIEDADES = "GET_ALL_PROPIEDADES",
  GET_ALL_VISITAS = "GET_ALL_VISITAS",
  GET_ALL_REGIONES = "GET_ALL_REGIONES",
  REQUEST_GET_REGIONES = "REQUEST_GET_REGIONES",
  GET_HORARIO_BY_USER = "GET_HORARIO_BY_USER",
  CLEAR_ACTION = "CLEAR_ACTION",
  POST_UPDATE_PROPIEDAD = "POST_UPDATE_PROPIEDAD",
  POST_LOGIN_USUARIO = "POST_LOGIN_USUARIO",
  POST_INSERT_INMOBILIARIA = "POST_INSERT_INMOBILIARIA",
  GET_ALL_INMOBILIARIAS = "GET_ALL_INMOBILIARIAS",
  REQUEST_GET_ALL_INMOBILIARIAS = "REQUEST_GET_ALL_INMOBILIARIAS",
  PUT_UPDATE_INMOBILIARIA = "PUT_UPDATE_INMOBILIARIA",
  USUARIO_LOGOUT = "USUARIO_LOGOUT",
  RESET_INITIAL_VALUES = "RESET_INITIAL_VALUES",
  PUT_UPDATE_CLIENTE = "PUT_UPDATE_CLIENTE",
  GET_TIPOS_CONTRATOS = "GET_TIPOS_CONTRATOS",
  GET_ALL_USUARIOS = "GET_ALL_USUARIOS",
  POST_INSERT_TIPO_CONTRATO = "POST_INSERT_TIPO_CONTRATO",
  ADD_SERVICIO_BASE = "ADD_SERVICIO_BASE",
  GET_ALL_SERVICIOS_BASE = "GET_ALL_SERVICIOS_BASE",
  ADD_PLAN = "ADD_PLAN",
  INITIAL_MENSAJE = "INITIAL_MENSAJE",
  GET_ALL_PLANES = "GET_ALL_PLANES",
  REQUEST_GET_ALL_PLANES = "REQUEST_GET_ALL_PLANES",
  GET_PLAN_BY_ID = "GET_PLAN_BY_ID",
  GET_OFERTAS_BY_PUBLICACION = "GET_OFERTAS_BY_PUBLICACION",
  ADD_PROPIEDADES_IMAGES = "ADD_PROPIEDADES_IMAGES",
  RESET_PROPIEDADES_IMAGES = "RESET_PROPIEDADES_IMAGES",
  SET_LOADING = "SET_LOADING",
  ADD_VALORACION = "ADD_VALORACION",
  UPDATE_PLAN = "UPDATE_PLAN",
  UPDATE_PROPIEDAD = "UPDATE_PROPIEDAD",
  DELETE_PLAN = "DELETE_PLAN",
  ADD_SERVICIO_ADICIONAL = "ADD_SERVICIO_ADICIONAL",
  LIST_SERVICIOS_ADICIONALES = "LIST_SERVICIOS_ADICIONALES ",
  DELETE_SERVICIO_ADICIONAL = "DELETE_SERVICIO_ADICIONAL",
  UPDATE_SERVICIO_ADICIONAL = "UPDATE_SERVICIO_ADICIONAL",
  GET_ALL_AGENDAS = "GET_ALL_AGENDAS",
  UPDATE_AGENDAS = "UPDATE_AGENDAS",
  ADD_FOTOGRAFO_AGENDA = "ADD_FOTOGRAFO_AGENDA",
  INIT_PLAN = "INIT_PLAN",
  REQUEST_DELETE_PROPIEDAD = "REQUEST_DELETE_PROPIEDAD",
  GET_BLOQUES_FOTOGRAFO = "GET_BLOQUES_FOTOGRAFO",
  GET_BLOQUES_CLIENTE = "GET_BLOQUES_CLIENTE",
  SIGNATURE = "SIGNATURE",
  ADD_CLIENTE_AGENDA = "ADD_CLIENTE_AGENDA",
  POST_PROYECTO_RES = "POST_PROYECTO_RES",
  REQUEST_POST_PROYECTO = "REQUEST_POST_PROYECTO",
  PUT_PROYECTO_RES = "PUT_PROYECTO_RES",
  REQUEST_PUT_PROYECTO = "REQUEST_PUT_PROYECTO",
  POST_ADD_INMOBILIARIA = "POST_ADD_INMOBILIARIA",
  CHANGE_ROWS = "CHANGE_ROWS",
  GET_ALL_PROYECTOS = "GET_ALL_PROYECTOS",
  SET_POST_TARJETA_PROYECTO = "SET_POST_TARJETA_PROYECTO",
  SET_REQUEST_POST_TARJETA_PROYECTO = "SET_REQUEST_POST_TARJETA_PROYECTO",
  SET_REQUEST_DELETE_TARJETA_PROYECTO = "SET_REQUEST_DELETE_TARJETA_PROYECTO",
  REQUEST_UPDATE_ESTADO_PROPIEDAD = "REQUEST_UPDATE_ESTADO_PROPIEDAD",
  SET_RESPONSE_MSG = "SET_RESPONSE_MSG",
  SET_PROPIEDADES_PAGINADAS = "SET_PROPIEDADES_PAGINADAS",
  REQUEST_GET_PROPIEDADES_PAGINADAS = "REQUEST_GET_PROPIEDADES_PAGINADAS",
  SET_PROPIEDAD = "SET_PROPIEDAD",
  REQUEST_GET_PROPIEDAD = "REQUEST_GET_PROPIEDAD",
  SET_TARJETA_PROP = "SET_TARJETA_PROP",
  REQUEST_POST_TARJETA_PROP = "REQUEST_POST_TARJETA_PROP",
  REQUEST_DELETE_TARJETA_PROP = "REQUEST_DELETE_TARJETA_PROP",
  REQUEST_POST_UPLOAD_DATOS_TASACION = "REQUEST_POST_UPLOAD_DATOS_TASACION",
  REQUEST_DELETE_ALL_DATOS_TASACION = "REQUEST_DELETE_ALL_DATOS_TASACION",
  REQUEST_POST_UPLOAD_PI_PROPIEDADES = "REQUEST_POST_UPLOAD_PI_PROPIEDADES",
  REQUEST_POST_ADD_SUSCRIPCION = "REQUEST_POST_ADD_SUSCRIPCION",
  GET_SUSCRIPCION_EXPORT = "GET_SUSCRIPCION_EXPORT",
  REQUEST_GET_SUSCRIPCION_EXPORT = "REQUEST_GET_SUSCRIPCION_EXPORT",
  REQUEST_GET_EXCEL_YAPO = "REQUEST_GET_EXCEL_YAPO",
  GET_EXCEL_YAPO = "GET_EXCEL_YAPO",
  SET_SUSCRIPCION_LAST_UPDATED = "SET_SUSCRIPCION_LAST_UPDATED",
  REQUEST_SUSCRIPCION_LAST_UPDATED = "REQUEST_SUSCRIPCION_LAST_UPDATED",
  REQUEST_ADD_USUARIO = "REQUEST_ADD_USUARIO",
  REQUEST_UPDATE_USUARIO = "REQUEST_UPDATE_USUARIO",
  SET_VISITA_FOTOGRAFO = "SET_VISITA_FOTOGRAFO",
  SET_REQUEST_LOADING_VISITA_FOTOGRAFO = "SET_REQUEST_LOADING_VISITA_FOTOGRAFO",
  SET_AGENDA_FOTOGRAFO = "SET_AGENDA_FOTOGRAFO",
  SET_REQUEST_LOADING_AGENDA_FOTOGRAFO = "SET_REQUEST_LOADING_AGENDA_FOTOGRAFO",
  SET_USUARIOS_FILTRADOS = "SET_USUARIOS_FILTRADOS",
  SET_REQUEST_USUARIOS_FILTRADOS = "SET_REQUEST_USUARIOS_FILTRADOS",
  SET_ASIGNAR_BROKER = "SET_ASIGNAR_BROKER",
  SET_REQUEST_ASIGNAR_BROKER = "SET_REQUEST_ASIGNAR_BROKER",
  SET_SUSCRIPCIONES = "SET_SUSCRIPCIONES",
  SET_REQUEST_SUSCRIPCIONES = "SET_REQUEST_SUSCRIPCIONES",
  SET_SUSCRIPCION = "SET_SUSCRIPCION",
  SET_REQUEST_SUSCRIPCION = "SET_REQUEST_SUSCRIPCION",
  SET_UPDATE_SUSCRIPCION = "SET_UPDATE_SUSCRIPCION",
  SET_REQUEST_UPDATE_SUSCRIPCION = "SET_REQUEST_UPDATE_SUSCRIPCION",
  SET_AGENDA_CLIENTE = "SET_AGENDA_CLIENTE",
  SET_REQUEST_AGENDA_CLIENTE = "SET_REQUEST_AGENDA_CLIENTE",
  SET_VISITAS_BY_FECHA_AND_ID = "SET_VISITAS_BY_FECHA_AND_ID",
  SET_REQUEST_VISITAS_BY_FECHA_AND_ID = "SET_REQUEST_VISITAS_BY_FECHA_AND_ID",
  SET_REQUEST_AGENDAR_VISITA_BROKER_SUSCRIPTOR =
    "SET_REQUEST_AGENDAR_VISITA_BROKER_SUSCRIPTOR",
  SET_VISITAS_BROKER_SUSCRIPTOR = "SET_VISITAS_BROKER_SUSCRIPTOR",
  SET_REQUEST_VISITAS_BROKER_SUSCRIPTOR =
    "SET_REQUEST_VISITAS_BROKER_SUSCRIPTOR",
  SET_EMBAJADOR = "SET_EMBAJADOR",
  SET_REQUEST_REFERIR_EMBAJADOR = "SET_REQUEST_REFERIR_EMBAJADOR",
  SET_DATOS_BANCARIOS = "SET_DATOS_BANCARIOS",
  SET_BROKER = "SET_BROKER",
  SET_REQUEST_POST_BROKER = "SET_REQUEST_POST_BROKER",
  SET_REQUEST_GET_BROKER = "SET_REQUEST_GET_BROKER",
  SET_REQUEST_PUT_BROKER = "SET_REQUEST_PUT_BROKER",
  SET_REQUEST_CAMBIO_CONTRASEÑA = "SET_REQUEST_CAMBIO_CONTRASEÑA",
  SET_USUARIO = "SET_USUARIO",
  SET_REQUEST_GET_USUARIO = "SET_REQUEST_GET_USUARIO",
  SET_ERROR_MESSAGE = "SET_ERROR_MESSAGE",
  SET_REQUEST_CONFIRMAR_VISITA = "SET_REQUEST_CONFIRMAR_VISITA",
  SET_REFERIR_VENDEDOR = "SET_REFERIR_VENDEDOR",
  SET_REQUEST_REFERIR_VENDEDOR = "SET_REQUEST_REFERIR_VENDEDOR",
  SET_VENDEDORES = "SET_VENDEDORES",
  SET_REQUEST_GET_VENDEDORES = "SET_REQUEST_GET_VENDEDORES",
  SET_REQUEST_USUARIO_CAMBIAR_CONTACTADO =
    "SET_REQUEST_USUARIO_CAMBIAR_CONTACTADO",
  SET_BROKERS = "SET_BROKERS",
  SET_REQUEST_GET_ALL_BROKERS = "SET_REQUEST_GET_ALL_BROKERS",
  SET_REQUEST_UPDATE_AGENDA_CLIENTE = "SET_REQUEST_UPDATE_AGENDA_CLIENTE",
  SET_REQUEST_AGENDAR_VISITA = "SET_REQUEST_AGENDAR_VISITA",
  SET_REQUEST_PUT_AGENDAR_VISITA = "SET_REQUEST_PUT_AGENDAR_VISITA",
  SET_REQUEST_CANCELAR_VISITA = "SET_REQUEST_CANCELAR_VISITA",
  SET_REQUEST_UPDATE_PLAN = "SET_REQUEST_UPDATE_PLAN",
  SET_REQUEST_ADD_PLAN = "SET_REQUEST_ADD_PLAN",
  SET_REQUEST_DELETE_PLAN = "SET_REQUEST_DELETE_PLAN",
  SET_REQUEST_ADD_SERVICIO_ADICIONAL = "SET_REQUEST_ADD_SERVICIO_ADICIONAL",
  SET_REQUEST_UPDATE_SERVICIO_ADICIONAL =
    "SET_REQUEST_UPDATE_SERVICIO_ADICIONAL",
  SET_REQUEST_DELETE_SERVICIO_ADICIONAL =
    "SET_REQUEST_DELETE_SERVICIO_ADICIONAL",
  SET_REQUEST_ADD_SERVICIO_BASE = "SET_REQUEST_ADD_SERVICIO_BASE",
  SET_USUARIOS_PAGINADOS = "SET_USUARIOS_PAGINADOS",
  SET_REQUEST_GET_USUARIOS_PAGINADOS = "SET_REQUEST_GET_USUARIOS_PAGINADOS",
  SET_LANDING_INMOBILIARIA = "SET_LANDING_INMOBILIARIA",
  SET_REQUEST_GET_LANDING_INMOBILIARIA = "SET_REQUEST_GET_LANDING_INMOBILIARIA",
  SET_REQUEST_POST_LANDING_INMOBILIARIA =
    "SET_REQUEST_POST_LANDING_INMOBILIARIA",
  SET_LANDING_INMOBILIARIAS = "SET_LANDING_INMOBILIARIAS",
  SET_REQUEST_GET_ALL_LANDING_INMOBILIARIAS =
    "SET_REQUEST_GET_ALL_LANDING_INMOBILIARIAS",
  SET_REQUEST_UPDATE_LANDING_INMOBILIARIA =
    "SET_REQUEST_UPDATE_LANDING_INMOBILIARIA",
  SET_NEWSLETTER = "SET_NEWSLETTER",
  SET_NEWSLETTERS = "SET_NEWSLETTERS",
  SET_REQUEST_POST_NEWSLETTER = "SET_REQUEST_POST_NEWSLETTER",
  SET_REQUEST_GET_ALL_NEWSLETTERS = "SET_REQUEST_GET_ALL_NEWSLETTERS",
  SET_REQUEST_DELETE_NEWSLETTER_BY_ID = "SET_REQUEST_DELETE_NEWSLETTER_BY_ID",
  SET_REQUEST_SEND_EMAILS_NEWSLETTER = "SET_REQUEST_SEND_EMAILS_NEWSLETTER",
  SET_CONTACTO_NEWSLETTER = "SET_CONTACTO_NEWSLETTER",
  SET_POSIBLES_LEADS = "SET_POSIBLES_LEADS",
  SET_REQUEST_UPLOAD_POSIBLES_LEADS = "SET_REQUEST_UPLOAD_POSIBLES_LEADS",
  SET_REQUEST_GET_POSIBLES_LEADS = "SET_REQUEST_GET_POSIBLES_LEADS",
  SET_REQUEST_UPDATE_POSIBLE_LEAD = "SET_REQUEST_UPDATE_POSIBLE_LEAD",
  SET_REQUEST_CAMBIAR_CONTACTADO_POSIBLE_LEAD =
    "SET_REQUEST_CAMBIAR_CONTACTADO_POSIBLE_LEAD",
  RECOVER_PASSWORD = "RECOVER_PASSWORD",
  REQUEST_RECOVER_PASSWORD = "REQUEST_RECOVER_PASSWORD",
  VALIDATE_TOKEN = "VALIDATE_TOKEN",
  RESTABLECER_PASSWORD = "RESTABLECER_PASSWORD",
  SET_REQUEST_DELETE_USER = "SET_REQUEST_DELETE_USER",
  SET_REQUEST_LOGIN = "SET_REQUEST_LOGIN",
  SET_REQUEST_POST_YAPO = "SET_REQUEST_POST_YAPO",
  SET_REQUEST_PUT_YAPO = "SET_REQUEST_PUT_YAPO",
  SET_REQUEST_DELETE_YAPO = "SET_REQUEST_DELETE_YAPO",
  SET_REQUEST_POST_PI = "SET_REQUEST_POST_PI",
  SET_REQUEST_PUT_PI = "SET_REQUEST_PUT_PI",
  SET_REQUEST_DELETE_PI = "SET_REQUEST_DELETE_PI",
  SET_REQUEST_POST_PORTFOLIO = "SET_REQUEST_POST_PORTFOLIO",
  SET_REQUEST_GET_PORTFOLIOS = "SET_REQUEST_GET_PORTFOLIOS",
  SET_REQUEST_PUT_SIGNER = "SET_REQUEST_PUT_SIGNER",
  SET_REQUEST_PUT_DOCUMENTS_PORTFOLIO = "SET_REQUEST_PUT_DOCUMENTS_PORTFOLIO",
  SET_REQUEST_PUT_SIGNER_DOCUMENTS_PORTFOLIO = "SET_REQUEST_PUT_SIGNER_DOCUMENTS_PORTFOLIO",
  SET_REQUEST_PORTFOLIO_RETRY = "SET_REQUEST_PORTFOLIO_RETRY",
  SET_PORTFOLIOS = "SET_PORTFOLIOS",
  SET_USER_BY_ID = "SET_USER_BY_ID",
  SET_REQUEST_USER_BY_ID = "SET_REQUEST_USER_BY_ID",
  SET_CLIENTE = "SET_CLIENTE",
  SET_REQUEST_GET_CLIENTE = "SET_REQUEST_GET_CLIENTE",
  REQUEST_UPDATE_PROPIEDAD = "REQUEST_UPDATE_PROPIEDAD",
  SET_ACTIVIDAD = "SET_ACTIVIDAD",
  REQUEST_GET_ACTIVIDAD = "REQUEST_GET_ACTIVIDAD",
  SET_ACTIVIDADES = "SET_ACTIVIDADES",
  REQUEST_GET_ACTIVIDADES = "REQUEST_GET_ACTIVIDADES",
  REQUEST_POST_ACTIVIDAD = "REQUEST_POST_ACTIVIDAD",
  REQUEST_PUT_ACTIVIDAD = "REQUEST_PUT_ACTIVIDAD",
  SET_CLIENTES = "SET_CLIENTES",
  REQUEST_GET_CLIENTES = "REQUEST_GET_CLIENTES",
  SET_USUARIO_BY_FILTER = "SET_USUARIO_BY_FILTER",
  REQUEST_GET_USUARIO_BY_EMAIL = "REQUEST_GET_USUARIO_BY_EMAIL",
  SET_PROSPECTOS_VA = "SET_PROSPECTOS_VA",
  REQUEST_GET_PROSPECTOS_VA = "REQUEST_GET_PROSPECTOS_VA",
  SET_PROSPECTOS_CA = "SET_PROSPECTOS_CA",
  REQUEST_GET_PROSPECTOS_CA = "REQUEST_GET_PROSPECTOS_CA",
  SET_PROSPECTO = "SET_PROSPECTO",
  REQUEST_UPDATE_PROSPECTO = "REQUEST_UPDATE_PROSPECTO",
  REQUEST_GET_PROSPECTO = "REQUEST_GET_PROSPECTO",
  REQUEST_POST_PROSPECTO = "REQUEST_POST_PROSPECTO",
  REQUEST_DELETE_PROSPECTO = "REQUEST_DELETE_PROSPECTO",
  SET_REQUERIMIENTOS = "SET_REQUERIMIENTOS",
  REQUEST_GET_REQUERIMIENTOS = "REQUEST_GET_REQUERIMIENTOS",
  SET_REQUERIMIENTO = "SET_REQUERIMIENTO",
  REQUEST_POST_REQUERIMIENTO = "REQUEST_POST_REQUERIMIENTO",
  REQUEST_PUT_REQUERIMIENTO = "REQUEST_PUT_REQUERIMIENTO",
  REQUEST_ADD_PROPIEDAD = "REQUEST_ADD_PROPIEDAD",
  SET_DOCUMENT = "SET_DOCUMENT",
  REQUEST_GENERATE_DOCUMENT = "REQUEST_GENERATE_DOCUMENT",
  SET_VALORES_PRELIMINARES_TASACION = "SET_VALORES_PRELIMINARES_TASACION",
  SET_REQUEST_VALORES_PRELIMINARES_TASACION = "SET_REQUEST_VALORES_PRELIMINARES_TASACION",
  SET_RESULTADO_TASACION = "SET_RESULTADO_TASACION",
  SET_REQUEST_TASACION = "SET_REQUEST_TASACION",
  SET_PROPIEDADES_SIMILARES_TASACION = "SET_PROPIEDADES_SIMILARES_TASACION",
  SET_REQUEST_PROPIEDADES_SIMILARES_TASACION = "SET_REQUEST_PROPIEDADES_SIMILARES_TASACION",
  SET_REPORTE = "SET_REPORTE",
  REQUEST_POST_REPORTE = "REQUEST_POST_REPORTE",
  SET_REPORTES = "SET_REPORTES",
  REQUEST_GET_REPORTES = "REQUEST_GET_REPORTES",
  SET_COMUNAS = "SET_COMUNAS",
  SET_GRAFICA = "SET_GRAFICA",
  SET_GRAFICAS = "SET_GRAFICAS",
  REQUEST_GET_GRAFICA = "REQUEST_GET_GRAFICA",
  REQUEST_GET_GRAFICAS = "REQUEST_GET_GRAFICAS",
  REQUEST_POST_GRAFICA = "REQUEST_POST_GRAFICA",
  REQUEST_PUT_GRAFICA = "REQUEST_PUT_GRAFICA",
  SET_CAPACITACION = "SET_CAPACITACION",
  SET_CAPACITACIONES = "SET_CAPACITACIONES",
  REQUEST_GET_CAPACITACION = "REQUEST_GET_CAPACITACION",
  REQUEST_GET_CAPACITACIONES = "REQUEST_GET_CAPACITACIONES",
  REQUEST_POST_CAPACITACION = "REQUEST_POST_CAPACITACION",
  REQUEST_PUT_CAPACITACION = "REQUEST_PUT_CAPACITACION",
  REQUEST_DELETE_CAPACITACION = "REQUEST_DELETE_CAPACITACION",
  REQUEST_SOLICITAR_CANJE = "REQUEST_SOLICITAR_CANJE",
  SET_PREVIEW_DOCUMENT = "SET_PREVIEW_DOCUMENT",
  REQUEST_PREVIEW_DOCUMENT = "REQUEST_PREVIEW_DOCUMENT",
  SET_EXPORT_TASACION = "SET_EXPORT_TASACION",
  REQUEST_EXPORT_TASACION = "REQUEST_EXPORT_TASACION",
  FICHA_PROPIEDAD = "FICHA_PROPIEDAD",
  REQUEST_DESCARGA_FICHA_PROPIEDAD = "REQUEST_DESCARGA_FICHA_PROPIEDAD",
  SET_REUNIONES = "SET_REUNIONES",
  REQUEST_GET_REUNIONES = "REQUEST_GET_REUNIONES",
  REQUEST_ACTUALIZAR_REUNION = "REQUEST_ACTUALIZAR_REUNION",
  SET_PROYECTO = "SET_PROYECTO",
  SET_PROYECTOS = "SET_PROYECTOS",
  REQUEST_GET_PROYECTO = "REQUEST_GET_PROYECTO",
  REQUEST_GET_PROYECTOS = "REQUEST_GET_PROYECTOS",
  REQUEST_ADD_INMOBILIARIA = "REQUEST_ADD_INMOBILIARIA",
  REQUEST_PUT_INMOBILIARIA = "REQUEST_PUT_INMOBILIARIA",
  REQUEST_UPLOAD_ARCHIVO_PROSPECTOS = "REQUEST_UPLOAD_ARCHIVO_PROSPECTOS",
  SET_METAS = "SET_METAS",
  REQUEST_GET_METAS = "REQUEST_GET_METAS",
  REQUEST_UPLOAD_ARCHIVO_METAS = "SET_METAS",
  SET_ASDF = "SET_ASDF";

// * DISPATCH FUNCS

const setLoading = (loading) => ({
  type: SET_LOADING,
  loading,
});

const postInsertTipocontrato = (mensaje) => ({
  type: POST_INSERT_TIPO_CONTRATO,
  mensaje,
});

const getsTiposDeContrato = (tiposContrato) => ({
  type: GET_TIPOS_CONTRATOS,
  tiposContrato,
});

const resetInitialstate = (initialState) => ({
  type: RESET_INITIAL_VALUES,
  initialState,
});

const receiveSubirPropiedad = (mensaje) => ({
  type: RECEIVE_SUBIR_PROPIEDAD,
  mensaje,
});

const addCliente = (mensaje) => ({
  type: ADD_CLIENTE,
  mensaje,
});

const addUsuario = (addUsuario) => ({
  type: ADD_USUARIO,
  addUsuario,
});

const addInmobiliaria = (mensaje) => ({
  type: POST_ADD_INMOBILIARIA,
  mensaje,
});

const setRequestAddInmobiliaria = (requestAddInmobiliaria) => ({
  type: REQUEST_ADD_INMOBILIARIA,
  requestAddInmobiliaria,
})

const setRequestPutInmobiliaria = (requestPutInmobiliaria) => ({
  type: REQUEST_PUT_INMOBILIARIA,
  requestPutInmobiliaria,
})

const getClientes = (itemsClientes) => ({
  type: GET_ALL_CLIENTES,
  itemsClientes,
});

const getPropiedades = (itemPropiedades) => ({
  type: GET_ALL_PROPIEDADES,
  itemPropiedades,
});

const getVisitas = (itemVisitas) => ({
  type: GET_ALL_VISITAS,
  itemVisitas,
});

const getUserByRol = (itemsUsuarios) => ({
  type: GET_USER_BY_ROL,
  itemsUsuarios,
});

const addHorarioEmp = (mensaje) => ({
  type: ADD_HORARIO_EMP,
  mensaje,
});

const getRegiones = (itemRegiones) => ({
  type: GET_ALL_REGIONES,
  itemRegiones,
});

const requestGetRegiones = (requestGetRegiones) => ({
  type: REQUEST_GET_REGIONES,
  requestGetRegiones,
});

const loginUsuario = (itemUsuario) => ({
  type: POST_LOGIN_USUARIO,
  itemUsuario,
});

const getHorarioByUser = (horario) => ({
  type: GET_HORARIO_BY_USER,
  horario,
});

const clearAction = (mensaje) => ({
  type: CLEAR_ACTION,
  mensaje,
});

const updatePropiedad = (mensaje) => ({
  type: POST_UPDATE_PROPIEDAD,
  mensaje,
});

const requestUpdatePropiedad = (requestUpdatePropiedad) => ({
  type: REQUEST_UPDATE_PROPIEDAD,
  requestUpdatePropiedad,
});

const insertInmobiliaria = (mensaje) => ({
  type: POST_INSERT_INMOBILIARIA,
  mensaje,
});

const getAllInmobiliarias = (itemInmobiliarias) => ({
  type: GET_ALL_INMOBILIARIAS,
  itemInmobiliarias,
});

const setRequestGetAllInmobiliarias = (requestGetAllInmobiliarias) => ({
  type: REQUEST_GET_ALL_INMOBILIARIAS,
  requestGetAllInmobiliarias,
});

const getUsuarios = (itemsUsuarios) => ({
  type: GET_ALL_USUARIOS,
  itemsUsuarios,
});

const logoutUsuario = (itemUsuario) => ({
  type: USUARIO_LOGOUT,
  itemUsuario,
});

const updateInmobiliaria = (mensaje) => ({
  type: PUT_UPDATE_INMOBILIARIA,
  mensaje,
});

const updateCliente = (payload) => ({
  type: PUT_UPDATE_CLIENTE,
  payload,
});

const addServicioBase = (mensaje) => ({
  type: ADD_SERVICIO_BASE,
  mensaje,
});

const getServiciosBase = (itemServicios) => ({
  type: GET_ALL_SERVICIOS_BASE,
  itemServicios,
});

const addPlan = (mensaje) => ({
  type: ADD_PLAN,
  mensaje,
});

const initialMensaje = (mensaje) => ({
  type: INITIAL_MENSAJE,
  mensaje,
});

const getPlanes = (itemPlanes) => ({
  type: GET_ALL_PLANES,
  itemPlanes,
});

const setRequestGetPlanes = (requestGetPlanes) => ({
  type: REQUEST_GET_ALL_PLANES,
  requestGetPlanes,
});

const getPlan = (plan) => ({
  type: GET_PLAN_BY_ID,
  plan,
});

const initPlan = () => ({
  type: GET_PLAN_BY_ID,
  plan: undefined,
});

const getOfertasByPublicacion = (itemOfertas) => ({
  type: GET_OFERTAS_BY_PUBLICACION,
  itemOfertas,
});

const addImagesPropiedad = (imagenesPropiedad) => ({
  type: ADD_PROPIEDADES_IMAGES,
  imagenesPropiedad,
});

const addValoracion = (valoracion) => ({
  type: ADD_VALORACION,
  valoracion,
});

const udpdatePlan = (mensaje) => ({
  type: UPDATE_PLAN,
  mensaje,
});
const udpdatePPropiedad = (mensaje) => ({
  type: UPDATE_PROPIEDAD,
  mensaje,
});

const postProyectoResAction = (mensaje) => ({
  type: POST_PROYECTO_RES,
  mensaje,
});

const setRequestPostProyecto = (requestPostProyecto) => ({
  type: REQUEST_POST_PROYECTO,
  requestPostProyecto,
});

const setRequestPutProyecto = (requestPutProyecto) => ({
  type: REQUEST_PUT_PROYECTO,
  requestPutProyecto,
});

const putProyectoResAction = (mensaje) => ({
  type: PUT_PROYECTO_RES,
  mensaje,
});

const setPostTarjetaProyecto = (tarjetaProyecto) => ({
  type: SET_POST_TARJETA_PROYECTO,
  tarjetaProyecto,
});

const setRequestPostTarjetaProyecto = (requestPostTarjetaProyecto) => ({
  type: SET_REQUEST_POST_TARJETA_PROYECTO,
  requestPostTarjetaProyecto,
});

const setRequestDeleteTarjetaProyecto = (requestDeleteTarjetaProyecto) => ({
  type: SET_REQUEST_DELETE_TARJETA_PROYECTO,
  requestDeleteTarjetaProyecto,
});

const deletePlan = (mensaje, id) => ({
  type: DELETE_PLAN,
  payload: {
    mensaje,
    id,
  },
});

const addServicioAdcional = ({ data, mensaje }) => ({
  type: ADD_SERVICIO_ADICIONAL,
  payload: { mensaje, data },
});

const listServiciosAdicionales = (payload) => ({
  type: LIST_SERVICIOS_ADICIONALES,
  payload,
});
const DeleteServicioAdicional = (id) => ({
  type: DELETE_SERVICIO_ADICIONAL,
  id,
});
const UpdateServicioAdicional = (payload) => ({
  type: UPDATE_SERVICIO_ADICIONAL,
  payload,
});

const getAllAgendas = (agendas) => ({
  type: GET_ALL_AGENDAS,
  agendas,
});

const updateAgendasWithAnfitrion = (payload) => ({
  type: UPDATE_AGENDAS,
  payload,
});

const setRequestDeletePropiedad = (requestDeletePropiedad) => ({
  type: REQUEST_DELETE_PROPIEDAD,
  requestDeletePropiedad,
});

const getAllProyectosAction = (itemProyectos) => ({
  type: GET_ALL_PROYECTOS,
  itemProyectos,
});

const cambiarNumeroTipologiasAction = (rowsNumberProps) => ({
  type: CHANGE_ROWS,
  rowsNumberProps,
});

const setRequestUpdateEstadoPropiedad = (requestUpdateEstadoPropiedad) => ({
  type: REQUEST_UPDATE_ESTADO_PROPIEDAD,
  requestUpdateEstadoPropiedad,
});

const setResponseMessage = (responseMessage) => ({
  type: SET_RESPONSE_MSG,
  responseMessage,
});

export const setPropiedadesPaginadas = (propiedadesPaginadas) => ({
  type: SET_PROPIEDADES_PAGINADAS,
  propiedadesPaginadas,
});

const setRequestPropiedadesPaginadas = (requestPropiedadesPaginadas) => ({
  type: REQUEST_GET_PROPIEDADES_PAGINADAS,
  requestPropiedadesPaginadas,
});

export const setPropiedad = (propiedad) => ({
  type: SET_PROPIEDAD,
  propiedad,
});

const setRequestGetPropiedad = (requestGetPropiedad) => ({
  type: REQUEST_GET_PROPIEDAD,
  requestGetPropiedad,
});

const setTarjetaProp = (tarjetaProp) => ({
  type: SET_TARJETA_PROP,
  tarjetaProp,
});

const setRequestPostTarjetaProp = (requestPostTarjetaProp) => ({
  type: REQUEST_POST_TARJETA_PROP,
  requestPostTarjetaProp,
});

const setRequestDeleteTarjetaProp = (requestDeleteTarjetaProp) => ({
  type: REQUEST_DELETE_TARJETA_PROP,
  requestDeleteTarjetaProp,
});

const setRequestPostUploadDatosTasacion = (requestPostUploadDatosTasacion) => ({
  type: REQUEST_POST_UPLOAD_DATOS_TASACION,
  requestPostUploadDatosTasacion,
});

const setRequestDeleteAllDatosTasacion = (requestDeleteAllDatosTasacion) => ({
  type: REQUEST_DELETE_ALL_DATOS_TASACION,
  requestDeleteAllDatosTasacion,
});

const setRequestPostUploadPIPropiedades = (requestPostUploadPIPropiedades) => ({
  type: REQUEST_POST_UPLOAD_PI_PROPIEDADES,
  requestPostUploadPIPropiedades,
});

const setRequestPostAddSuscripcion = (requestPostAddSuscripcion) => ({
  type: REQUEST_POST_ADD_SUSCRIPCION,
  requestPostAddSuscripcion,
});

export const setSuscripcionExcel = (suscripcionExcel) => ({
  type: GET_SUSCRIPCION_EXPORT,
  suscripcionExcel,
});

const setRequestSuscripcionExcel = (requestSuscripcionExcel) => ({
  type: REQUEST_GET_SUSCRIPCION_EXPORT,
  requestSuscripcionExcel,
});

export const setExcelYapo = (excelYapo) => ({
  type: GET_EXCEL_YAPO,
  excelYapo,
});

const setRequestExcelYapo = (requestExcelYapo) => ({
  type: REQUEST_GET_EXCEL_YAPO,
  requestExcelYapo,
});

const setSuscripcionLastUpdated = (suscripcionLastUpdated) => ({
  type: SET_SUSCRIPCION_LAST_UPDATED,
  suscripcionLastUpdated,
});

const setRequestSuscripcionLastUpdated = (requestSuscripcionLastUpdated) => ({
  type: REQUEST_SUSCRIPCION_LAST_UPDATED,
  requestSuscripcionLastUpdated,
});

const setRequestAddUsuario = (requestAddUsuario) => ({
  type: REQUEST_ADD_USUARIO,
  requestAddUsuario,
});

const setRequestUpdateUsuario = (requestUpdateUsuario) => ({
  type: REQUEST_UPDATE_USUARIO,
  requestUpdateUsuario,
});

export const setVisitaFotografo = (visitaFotografo) => ({
  type: SET_VISITA_FOTOGRAFO,
  visitaFotografo,
});

export const setRequestLoadingVisitaFotografo = (
  requestLoadingVisitaFotografo
) => ({
  type: SET_REQUEST_LOADING_VISITA_FOTOGRAFO,
  requestLoadingVisitaFotografo,
});

export const setAgendaFotografo = (agendaFotografo) => ({
  type: SET_AGENDA_FOTOGRAFO,
  agendaFotografo,
});

export const setRequestLoadingAgendaFotografo = (
  requestLoadingAgendaFotografo
) => ({
  type: SET_REQUEST_LOADING_AGENDA_FOTOGRAFO,
  requestLoadingAgendaFotografo,
});

const setUsuariosFiltrados = (usuariosFiltrados) => ({
  type: SET_USUARIOS_FILTRADOS,
  usuariosFiltrados,
});

const setRequestUsuariosFiltrados = (requestUsuarioFiltrados) => ({
  type: SET_REQUEST_USUARIOS_FILTRADOS,
  requestUsuarioFiltrados,
});

const setAsignarBroker = (propiedadBroker) => ({
  type: SET_ASIGNAR_BROKER,
  propiedadBroker,
});

const setRequestAsignarBroker = (requestPropiedadBroker) => ({
  type: SET_REQUEST_ASIGNAR_BROKER,
  requestPropiedadBroker,
});

const setSuscripciones = (suscripciones) => ({
  type: SET_SUSCRIPCIONES,
  suscripciones,
});

const setRequestSuscripciones = (requestSuscripciones) => ({
  type: SET_REQUEST_SUSCRIPCIONES,
  requestSuscripciones,
});

export const setSuscripcion = (suscripcion) => ({
  type: SET_SUSCRIPCION,
  suscripcion,
});

const setRequestSuscripcion = (requestSuscripcion) => ({
  type: SET_REQUEST_SUSCRIPCION,
  requestSuscripcion,
});

export const setUpdateSuscripcion = (updateSuscripcion) => ({
  type: SET_UPDATE_SUSCRIPCION,
  updateSuscripcion,
});

const setRequestUpdateSuscripcion = (requestUpdateSuscripcion) => ({
  type: SET_REQUEST_UPDATE_SUSCRIPCION,
  requestUpdateSuscripcion,
});

export const setAgendaCliente = (agendaCliente) => ({
  type: SET_AGENDA_CLIENTE,
  agendaCliente,
});

const setRequestAgendaCliente = (requestAgendaCliente) => ({
  type: SET_REQUEST_AGENDA_CLIENTE,
  requestAgendaCliente,
});

export const setVisitasByFechaAndId = (visitasCliente) => ({
  type: SET_VISITAS_BY_FECHA_AND_ID,
  visitasCliente,
});

const setRequestVisitasByFechaAndId = (requestVisitasCliente) => ({
  type: SET_REQUEST_VISITAS_BY_FECHA_AND_ID,
  requestVisitasCliente,
});

const setRequestAgendarVisitaBrokerSuscriptor = (
  requestAgendarVisitaBroker
) => ({
  type: SET_REQUEST_AGENDAR_VISITA_BROKER_SUSCRIPTOR,
  requestAgendarVisitaBroker,
});

export const setVisitasBrokerSuscriptor = (visitasBrokerSuscriptor) => ({
  type: SET_VISITAS_BROKER_SUSCRIPTOR,
  visitasBrokerSuscriptor,
});

const setRequestVisitasBrokerSuscriptor = (requestVisitasBrokerSuscriptor) => ({
  type: SET_REQUEST_VISITAS_BROKER_SUSCRIPTOR,
  requestVisitasBrokerSuscriptor,
});

const setEmbajador = (embajador) => ({
  type: SET_EMBAJADOR,
  embajador,
});

const setRequestReferirEmbajador = (requestReferirEmbajador) => ({
  type: SET_REQUEST_REFERIR_EMBAJADOR,
  requestReferirEmbajador,
});

export const setBroker = (broker) => ({
  type: SET_BROKER,
  broker,
});

const setRequestPostBroker = (requestPostBroker) => ({
  type: SET_REQUEST_POST_BROKER,
  requestPostBroker,
});

const setRequestGetBroker = (requestGetBroker) => ({
  type: SET_REQUEST_GET_BROKER,
  requestGetBroker,
});

const setRequestPutBroker = (requestPutBroker) => ({
  type: SET_REQUEST_PUT_BROKER,
  requestPutBroker,
});

const setRequestCambioContraseña = (requestCambioContraseña) => ({
  type: SET_REQUEST_CAMBIO_CONTRASEÑA,
  requestCambioContraseña,
});

export const setUsuario = (usuario) => ({
  type: SET_USUARIO,
  usuario,
});

const setRequestGetUsuario = (requestGetUsuario) => ({
  type: SET_REQUEST_GET_USUARIO,
  requestGetUsuario,
});

export const setErrorMessage = (errorMessage) => ({
  type: SET_ERROR_MESSAGE,
  errorMessage,
});

export const setRequestConfirmarVisita = (requestConfirmarVisita) => ({
  type: SET_REQUEST_CONFIRMAR_VISITA,
  requestConfirmarVisita,
});

const setReferirVendedor = (vendedor) => ({
  type: SET_REFERIR_VENDEDOR,
  vendedor,
});

const setRequestReferirVendedor = (requestReferirVendedor) => ({
  type: SET_REQUEST_REFERIR_VENDEDOR,
  requestReferirVendedor,
});

const setVendedores = (vendedores) => ({
  type: SET_VENDEDORES,
  vendedores,
});

const setRequestGetVendedores = (requestGetVendedores) => ({
  type: SET_REQUEST_GET_VENDEDORES,
  requestGetVendedores,
});

export const setRequestUsuarioCambiarContactado = (
  requestCambiarContactado
) => ({
  type: SET_REQUEST_USUARIO_CAMBIAR_CONTACTADO,
  requestCambiarContactado,
});

const setBrokers = (brokers) => ({
  type: SET_BROKERS,
  brokers,
});

const setRequestGetAllBrokers = (requestGetAllBrokers) => ({
  type: SET_REQUEST_GET_ALL_BROKERS,
  requestGetAllBrokers,
});

const setRequestUpdateAgendaCliente = (requestUpdateAgendaCliente) => ({
  type: SET_REQUEST_UPDATE_AGENDA_CLIENTE,
  requestUpdateAgendaCliente,
});

const setRequestAgendarVisita = (requestAgendarVisita) => ({
  type: SET_REQUEST_AGENDAR_VISITA,
  requestAgendarVisita,
});

const setRequestPutAgendarVisita = (requestPutAgendarVisita) => ({
  type: SET_REQUEST_PUT_AGENDAR_VISITA,
  requestPutAgendarVisita,
});

const setRequestCancelarVisita = (requestCancelarVisita) => ({
  type: SET_REQUEST_CANCELAR_VISITA,
  requestCancelarVisita,
});

const setRequestUpdatePlan = (requestUpdatePlan) => ({
  type: SET_REQUEST_UPDATE_PLAN,
  requestUpdatePlan,
});

const setRequestAddPlan = (requestAddPlan) => ({
  type: SET_REQUEST_ADD_PLAN,
  requestAddPlan,
});

const setRequestDeletePlan = (requestDeletePlan) => ({
  type: SET_REQUEST_DELETE_PLAN,
  requestDeletePlan,
});

const setRequestAddServicioAdicional = (requestAddServicioAdicional) => ({
  type: SET_REQUEST_ADD_SERVICIO_ADICIONAL,
  requestAddServicioAdicional,
});

const setRequestUpdateServicioAdicional = (requestUpdateServicioAdicional) => ({
  type: SET_REQUEST_UPDATE_SERVICIO_ADICIONAL,
  requestUpdateServicioAdicional,
});

const setRequestDeleteServicioAdicional = (requestDeleteServicioAdicional) => ({
  type: SET_REQUEST_DELETE_SERVICIO_ADICIONAL,
  requestDeleteServicioAdicional,
});

const setRequestAddServicioBase = (requestAddServicioBase) => ({
  type: SET_REQUEST_ADD_SERVICIO_BASE,
  requestAddServicioBase,
});

const setUsuariosPaginados = (usuariosPaginados) => ({
  type: SET_USUARIOS_PAGINADOS,
  usuariosPaginados,
});

const setRequestGetUsuariosPaginados = (requestGetUsuariosPaginados) => ({
  type: SET_REQUEST_GET_USUARIOS_PAGINADOS,
  requestGetUsuariosPaginados,
});

export const setLandingInmobiliaria = (landingInmobiliaria) => ({
  type: SET_LANDING_INMOBILIARIA,
  landingInmobiliaria,
});

const setRequestGetLandingInmobiliaria = (requestGetLandingInmobiliaria) => ({
  type: SET_REQUEST_GET_LANDING_INMOBILIARIA,
  requestGetLandingInmobiliaria,
});

const setRequestPostLandingInmobiliaria = (requestPostLandingInmobiliaria) => ({
  type: SET_REQUEST_POST_LANDING_INMOBILIARIA,
  requestPostLandingInmobiliaria,
});

const setLandingInmobiliarias = (landingInmobiliarias) => ({
  type: SET_LANDING_INMOBILIARIAS,
  landingInmobiliarias,
});

const setRequestGetAllLandingInmobiliarias = (
  requestGetAllLandingInmobiliarias
) => ({
  type: SET_REQUEST_GET_ALL_LANDING_INMOBILIARIAS,
  requestGetAllLandingInmobiliarias,
});

const setRequestUpdateLandingInmobiliaria = (
  requestUpdateLandingInmobiliaria
) => ({
  type: SET_REQUEST_UPDATE_LANDING_INMOBILIARIA,
  requestUpdateLandingInmobiliaria,
});

export const setNewsletter = (newsletter) => ({
  type: SET_NEWSLETTER,
  newsletter,
});

const setNewsletters = (newsletters) => ({
  type: SET_NEWSLETTERS,
  newsletters,
});

const setRequestPostNewsletter = (requestPostNewsletter) => ({
  type: SET_REQUEST_POST_NEWSLETTER,
  requestPostNewsletter,
});

const setRequestGetAllNewsletters = (requestGetAllNewsletters) => ({
  type: SET_REQUEST_GET_ALL_NEWSLETTERS,
  requestGetAllNewsletters,
});

const setRequestDeleteNewsletterById = (requestDeleteNewsletterById) => ({
  type: SET_REQUEST_DELETE_NEWSLETTER_BY_ID,
  requestDeleteNewsletterById,
});

const setRequestSendEmailsNewsletter = (requestSendEmailsNewsletter) => ({
  type: SET_REQUEST_SEND_EMAILS_NEWSLETTER,
  requestSendEmailsNewsletter,
});

export const setContactoNewsletter = (contactoNewsletter) => ({
  type: SET_CONTACTO_NEWSLETTER,
  contactoNewsletter,
});

export const setPosiblesLeads = (posiblesLeads) => ({
  type: SET_POSIBLES_LEADS,
  posiblesLeads,
});

export const setRequestUploadPosiblesLeads = (requestUploadPosiblesLeads) => ({
  type: SET_REQUEST_UPLOAD_POSIBLES_LEADS,
  requestUploadPosiblesLeads,
});

export const setRequestGetPosiblesLeads = (requestGetPosiblesLeads) => ({
  type: SET_REQUEST_GET_POSIBLES_LEADS,
  requestGetPosiblesLeads,
});

export const setRequestUpdatePosibleLead = (requestUpdatePosibleLead) => ({
  type: SET_REQUEST_UPDATE_POSIBLE_LEAD,
  requestUpdatePosibleLead,
});

export const setRequestCambiarContactadoPosibleLead = (
  requestCambiarContactadoPosibleLead
) => ({
  type: SET_REQUEST_CAMBIAR_CONTACTADO_POSIBLE_LEAD,
  requestCambiarContactadoPosibleLead,
});

export const setRecoverPassword = (recover) => ({
  type: RECOVER_PASSWORD,
  recover,
});

export const setRequestRecoverPassword = (requestRecover) => ({
  type: REQUEST_RECOVER_PASSWORD,
  requestRecover,
});

export const setValidateToken = (tokenPassword) => ({
  type: VALIDATE_TOKEN,
  tokenPassword,
});

export const setReestablecerPassword = (restablecer) => ({
  type: RESTABLECER_PASSWORD,
  restablecer,
});

const setRequestDeleteUser = (requestDeleteUser) => ({
  type: SET_REQUEST_DELETE_USER,
  requestDeleteUser,
});

const setRequestLogin = (requestLogin) => ({
  type: SET_REQUEST_LOGIN,
  requestLogin,
});

const setRequestPostYapo = (requestPostYapo) => ({
  type: SET_REQUEST_POST_YAPO,
  requestPostYapo,
});

const setRequestPutYapo = (requestPutYapo) => ({
  type: SET_REQUEST_PUT_YAPO,
  requestPutYapo,
});

const setRequestDeleteYapo = (requestDeleteYapo) => ({
  type: SET_REQUEST_DELETE_YAPO,
  requestDeleteYapo,
});

const setRequestPostPI = (requestPostPI) => ({
  type: SET_REQUEST_POST_PI,
  requestPostPI,
});

const setRequestPutPI = (requestPutPI) => ({
  type: SET_REQUEST_PUT_PI,
  requestPutPI,
});

const setRequestDeletePI = (requestDeletePI) => ({
  type: SET_REQUEST_DELETE_PI,
  requestDeletePI,
});

const setRequestPostPortfolio = (requestPostPortfolio) => ({
  type: SET_REQUEST_POST_PORTFOLIO,
  requestPostPortfolio,
});

const setRequestGetPortfolios = (requestGetPortfolios) => ({
  type: SET_REQUEST_GET_PORTFOLIOS,
  requestGetPortfolios,
});

const setPortfolios = (portfolios) => ({
  type: SET_PORTFOLIOS,
  portfolios,
});

const setRequestPutSigner = (requestPutSigner) => ({
  type: SET_REQUEST_PUT_SIGNER,
  requestPutSigner,
});

const setRequestPutDocumentsPortfolio = (requestPutDocumentsPortfolio) => ({
  type: SET_REQUEST_PUT_DOCUMENTS_PORTFOLIO,
  requestPutDocumentsPortfolio,
});

const setRequestPutSignerDocumentsPortfolio = (requestPutSignerDocumentsPortfolio) => ({
  type: SET_REQUEST_PUT_SIGNER_DOCUMENTS_PORTFOLIO,
  requestPutSignerDocumentsPortfolio,
});

const setRequestPortfolioRetry = (requestPortfolioRetry) => ({
  type: SET_REQUEST_PORTFOLIO_RETRY,
  requestPortfolioRetry,
});

const setUserById = (userById) => ({
  type: SET_USER_BY_ID,
  userById,
});

const setRequestUserById = (requestUserById) => ({
  type: SET_REQUEST_USER_BY_ID,
  requestUserById,
});

const setCliente = (cliente) => ({
  type: SET_CLIENTE,
  cliente,
});

const setRequestGetCliente = (requestGetCliente) => ({
  type: SET_REQUEST_GET_CLIENTE,
  requestGetCliente,
});

const setActividad = (actividad) => ({
  type: SET_ACTIVIDAD,
  actividad,
});

const setRequestGetActividad = (requestGetActividad) => ({
  type: REQUEST_GET_ACTIVIDAD,
  requestGetActividad,
});

const setActividades = (actividades) => ({
  type: SET_ACTIVIDADES,
  actividades,
});

const setRequestGetActividades = (requestGetActividades) => ({
  type: REQUEST_GET_ACTIVIDADES,
  requestGetActividades,
});

const setRequestPostActividad = (requestPostActividad) => ({
  type: REQUEST_POST_ACTIVIDAD,
  requestPostActividad,
});

const setRequestPutActividad = (requestPutActividad) => ({
  type: REQUEST_PUT_ACTIVIDAD,
  requestPutActividad,
});

const setClientes = (clientes) => ({
  type: SET_CLIENTES,
  clientes,
});

const setRequestGetClientes = (requestGetClientes) => ({
  type: REQUEST_GET_CLIENTES,
  requestGetClientes,
});

const setUsuarioByFilter = (usuarioByFilter) => ({
  type: SET_USUARIO_BY_FILTER,
  usuarioByFilter,
});

const setRequestGetUsuarioByEmail = (requestGetUsuarioByEmail) => ({
  type: REQUEST_GET_USUARIO_BY_EMAIL,
  requestGetUsuarioByEmail,
});

const setProspectosVA = (prospectosVA) => ({
  type: SET_PROSPECTOS_VA,
  prospectosVA,
});

const setProspectosCA = (prospectosCA) => ({
  type: SET_PROSPECTOS_CA,
  prospectosCA,
});

const setProspecto = (prospecto) => ({
  type: SET_PROSPECTO,
  prospecto,
});

const setRequestGetProspecto = (requestGetProspecto) => ({
  type: REQUEST_GET_PROSPECTO,
  requestGetProspecto,
});

const setRequestUpdateProspecto = (requestUpdateProspecto) => ({
  type: REQUEST_UPDATE_PROSPECTO,
  requestUpdateProspecto,
});

const setRequestGetProspectosVA = (requestGetProspectosVA) => ({
  type: REQUEST_GET_PROSPECTOS_VA,
  requestGetProspectosVA,
});

const setRequestGetProspectosCA = (requestGetProspectosCA) => ({
  type: REQUEST_GET_PROSPECTOS_CA,
  requestGetProspectosCA,
});

const setRequestPostProspecto = (requestPostProspecto) => ({
  type: REQUEST_POST_PROSPECTO,
  requestPostProspecto,
});

const setRequestDeleteProspecto = (requestDeleteProspecto) => ({
  type: REQUEST_DELETE_PROSPECTO,
  requestDeleteProspecto,
});

const setRequerimientos = (requerimientos) => ({
  type: SET_REQUERIMIENTOS,
  requerimientos,
});

const setRequestGetRequerimientos = (requestGetRequerimientos) => ({
  type: REQUEST_GET_REQUERIMIENTOS,
  requestGetRequerimientos,
});

const setRequerimiento = (requerimiento) => ({
  type: SET_REQUERIMIENTO,
  requerimiento,
});

const setRequestPostRequerimiento = (requestPostRequerimiento) => ({
  type: REQUEST_POST_REQUERIMIENTO,
  requestPostRequerimiento,
});

const setRequestPutRequerimiento = (requestPutRequerimiento) => ({
  type: REQUEST_PUT_REQUERIMIENTO,
  requestPutRequerimiento,
});

const setRequestAddPropiedad = (requestAddPropiedad) => ({
  type: REQUEST_ADD_PROPIEDAD,
  requestAddPropiedad,
});

export const setDocument = (document) => ({
  type: SET_DOCUMENT,
  document,
});

const setRequestGenerateDocument = (requestGenerateDocument) => ({
  type: REQUEST_GENERATE_DOCUMENT,
  requestGenerateDocument,
});

export const setValoresPreliminaresTasacion = (
  valoresPreliminaresTasacion
) => ({
  type: SET_VALORES_PRELIMINARES_TASACION,
  valoresPreliminaresTasacion,
});

export const setRequestValoresPreliminaresTasacion = (
  requestStateValoresPreliminaresTasacion
) => ({
  type: SET_REQUEST_VALORES_PRELIMINARES_TASACION,
  requestStateValoresPreliminaresTasacion,
});

export const setResultadoTasacion = (resultadoTasacion) => ({
  type: SET_RESULTADO_TASACION,
  resultadoTasacion,
});

export const setRequestStateTasacion = (requestStateTasacion) => ({
  type: SET_REQUEST_TASACION,
  requestStateTasacion,
});

export const setPropiedadesSimilaresTasacion = (
  propiedadesSimilaresTasacion
) => ({
  type: SET_PROPIEDADES_SIMILARES_TASACION,
  propiedadesSimilaresTasacion,
});

export const setRequestStatePropiedadesSimilaresTasacion = (
  requestStatePropiedadesSimilaresTasacion
) => ({
  type: SET_REQUEST_PROPIEDADES_SIMILARES_TASACION,
  requestStatePropiedadesSimilaresTasacion,
});

export const setReporte = (reporte) => ({
  type: SET_REPORTE,
  reporte,
});

export const setReportes = (reportes) => ({
  type: SET_REPORTES,
  reportes,
});

export const setRequestGetReportes = (requestGetReportes) => ({
  type: REQUEST_GET_REPORTES,
  requestGetReportes,
});

export const setRequestPostReporte = (requestPostReporte) => ({
  type: REQUEST_POST_REPORTE,
  requestPostReporte,
});

export const setComunas = (comunas) => ({
  type: SET_COMUNAS,
  comunas,
});

export const setGrafica = (grafica) => ({
  type: SET_GRAFICA,
  grafica,
});

export const setGraficas = (graficas) => ({
  type: SET_GRAFICAS,
  graficas,
});

export const setRequestGetGrafica = (requestGetGrafica) => ({
  type: REQUEST_GET_GRAFICA,
  requestGetGrafica,
});

export const setRequestGetGraficas = (requestGetGraficas) => ({
  type: REQUEST_GET_GRAFICAS,
  requestGetGraficas,
});

export const setRequestPostGrafica = (requestPostGrafica) => ({
  type: REQUEST_POST_GRAFICA,
  requestPostGrafica,
});

export const setRequestPutGrafica = (requestPutGrafica) => ({
  type: REQUEST_PUT_GRAFICA,
  requestPutGrafica,
});

export const setCapacitacion = (capacitacion) => ({
  type: SET_CAPACITACION,
  capacitacion,
});

export const setCapacitaciones = (capacitaciones) => ({
  type: SET_CAPACITACIONES,
  capacitaciones,
});

export const setRequestGetCapacitacion = (requestGetCapacitacion) => ({
  type: REQUEST_GET_CAPACITACION,
  requestGetCapacitacion,
});

export const setRequestGetCapacitaciones = (requestGetCapacitaciones) => ({
  type: REQUEST_GET_CAPACITACIONES,
  requestGetCapacitaciones,
});

export const setRequestPostCapacitacion = (requestPostCapacitacion) => ({
  type: REQUEST_POST_CAPACITACION,
  requestPostCapacitacion,
});

export const setRequestPutCapacitacion = (requestPutCapacitacion) => ({
  type: REQUEST_PUT_CAPACITACION,
  requestPutCapacitacion,
});

export const setRequestDeleteCapacitacion = (requestDeleteCapacitacion) => ({
  type: REQUEST_DELETE_CAPACITACION,
  requestDeleteCapacitacion,
});

export const setRequestSolicitarCanje = (requestSolicitarCanje) => ({
  type: REQUEST_SOLICITAR_CANJE,
  requestSolicitarCanje,
});

export const setPreviewDocument = (previewDocument) => ({
  type: SET_PREVIEW_DOCUMENT,
  previewDocument,
});

export const setRequestPreviewDocument = (requestPreviewDocument) => ({
  type: REQUEST_PREVIEW_DOCUMENT,
  requestPreviewDocument,
});

export const setExportTasacion = (exportTasacion) => ({
  type: SET_EXPORT_TASACION,
  exportTasacion,
});

export const setRequestExportTasacion = (requestExportTasacion) => ({
  type: REQUEST_EXPORT_TASACION,
  requestExportTasacion,
});

export const setFichaPropiedad = (fichaPropiedad) => ({
  type: FICHA_PROPIEDAD,
  fichaPropiedad,
});

export const setRequestDescargaFichaPropiedad = (requestDescargaFichaPropiedad) => ({
  type: REQUEST_DESCARGA_FICHA_PROPIEDAD,
  requestDescargaFichaPropiedad,
});

export const setReuniones = (reuniones) => ({
  type: SET_REUNIONES,
  reuniones,
});

export const setRequestGetReuniones = (requestGetReuniones) => ({
  type: REQUEST_GET_REUNIONES,
  requestGetReuniones,
});

export const setRequestActualizarReunion = (requestActualizarReunion) => ({
  type: REQUEST_ACTUALIZAR_REUNION,
  requestActualizarReunion,
});

export const setProyecto = (proyecto) => ({
  type: SET_PROYECTO,
  proyecto,
});

export const setProyectos = (proyectos) => ({
  type: SET_PROYECTOS,
  proyectos,
});

export const setRequestGetProyecto = (requestGetProyecto) => ({
  type: REQUEST_GET_PROYECTO,
  requestGetProyecto,
});

export const setRequestGetProyectos = (requestGetProyectos) => ({
  type: REQUEST_GET_PROYECTOS,
  requestGetProyectos,
});

export const setRequestUploadArchivoProspectos = (requestUploadArchivoProspectos) => ({
  type: REQUEST_UPLOAD_ARCHIVO_PROSPECTOS,
  requestUploadArchivoProspectos,
});

export const setMetas = (metas) => ({
  type: SET_METAS,
  metas,
});

export const setRequestGetMetas = (requestGetMetas) => ({
  type: REQUEST_GET_METAS,
  requestGetMetas,
});

export const setRequestUploadArchivoMetas = (requestUploadArchivoMetas) => ({
  type: REQUEST_UPLOAD_ARCHIVO_METAS,
  requestUploadArchivoMetas,
});

export const setAsdf = (asdf) => ({
  type: SET_ASDF,
  asdf,
});
// * FETCHING FUNCS

export const fetchGetTiposContrato = () => async (dispatch) => {
  try {
    const res = await api.apiListTiposDeContratos();
    dispatch(getsTiposDeContrato(res));
  } catch (error) {
    console.error("error: ", error);
  }
};

//updatecliente
export const fecthUpdateCliente = (obj) => async (dispatch) => {
  dispatch(setLoading(true));
  try {
    const res = await api.apiUpdateCliente(obj);

    dispatch(updateCliente(res.value));
  } catch (error) {
    console.error("error: ", error);
  }
  dispatch(setLoading(false));
};

export const fetchCLearInitialstate = () => (dispatch) => {
  dispatch(resetInitialstate());
};

export const cambiarNumeroTipologias = (rowsNumberProps) => (dispatch) => {
  dispatch(cambiarNumeroTipologiasAction(rowsNumberProps));
};

export const fetchClearUserSession = () => (dispatch) => {
  dispatch(logoutUsuario());
};

export const fetchClearAction = () => (dispatch) => {
  dispatch(clearAction(""));
};

export const fetchAddPropiedad = (propiedad) => async (dispatch) => {
  dispatch(setRequestAddPropiedad(REQUEST_STATE.LOADING));
  try {
    const res = await api.fetchAddPropiedad(propiedad);
    if (res && res.value) {
      if (res.value.estado === 1) {
        dispatch(setPropiedad(res.value.data));
        dispatch(receiveSubirPropiedad(res));
        dispatch(setRequestAddPropiedad(REQUEST_STATE.SUCCESS));
      } else {
        dispatch(setErrorMessage(res.value.mensaje));
        dispatch(setRequestAddPropiedad(REQUEST_STATE.ERROR));
      }
    } else {
      if (res.status) {
        dispatch(setErrorMessage(STANDARD_ERROR_MESSAGES[res.status]));
        dispatch(setRequestAddPropiedad(REQUEST_STATE.ERROR));
      }
    }
  } catch (error) {
    console.error("error: ", error);
    dispatch(setErrorMessage("Ha habido un error"));
    dispatch(setRequestAddPropiedad(REQUEST_STATE.ERROR));
  } finally {
    setTimeout(() => {
      dispatch(setRequestAddPropiedad(REQUEST_STATE.IDLE));
    }, 500);
}
};

//addcliente
export const fetchAddCliente = (cliente) => async (dispatch) => {
  dispatch(setLoading(true));
  try {
    const resAdd = await api.apiAddCliente(cliente);

    const resFetch = await api.apiGetAllClientes();
    dispatch(addCliente(resAdd.value.mensaje));
    dispatch(getClientes(resFetch));
    dispatch(setLoading(false));
  } catch (error) {
    console.error("error: ", error);
  }
};

export const fetchAddUsuario = (usuario) => async (dispatch) => {
  dispatch(setRequestAddUsuario(REQUEST_STATE.LOADING));
  try {
    const res = await api.apiAddUsuario(usuario);
    if (res && res.data) {
      if (res.data.estado === 1) {
        dispatch(addUsuario(res.data.data));
        dispatch(setRequestAddUsuario(REQUEST_STATE.SUCCESS));
      } else {
        dispatch(setErrorMessage(res.data.mensaje));
        dispatch(setRequestAddUsuario(REQUEST_STATE.ERROR));
      }
    } else {
      if (res.status) {
        dispatch(setErrorMessage(STANDARD_ERROR_MESSAGES[res.status]));
        dispatch(setRequestAddUsuario(REQUEST_STATE.ERROR));
      }
    }    
  } catch (error) {
    dispatch(setErrorMessage("Ha habido un error"));
    dispatch(setRequestAddUsuario(REQUEST_STATE.ERROR));
  } finally {
    setTimeout(() => {
      dispatch(setRequestAddUsuario(REQUEST_STATE.IDLE));
    }, 500);    
  }
};

export const fetchAddInmobiliaria = (data) => async (dispatch) => {
  dispatch(setRequestAddInmobiliaria(REQUEST_STATE.LOADING));
  try {
    const res = await api.apiAddInmobiliaria(data);
    if (res && res.data && res.estado) {
      if (res.estado === 1) {
        dispatch(addInmobiliaria(res.data));
        dispatch(setRequestAddInmobiliaria(REQUEST_STATE.SUCCESS));
      } else {
        dispatch(setErrorMessage(res.mensaje));
        dispatch(setRequestAddInmobiliaria(REQUEST_STATE.ERROR));
      }
    } else {
      if (res.status) {
        dispatch(setErrorMessage(STANDARD_ERROR_MESSAGES[res.status]));
        dispatch(setRequestAddInmobiliaria(REQUEST_STATE.ERROR));
      }
    }    
  } catch (error) {
    dispatch(setErrorMessage("Ha habido un error"));
    dispatch(setRequestAddInmobiliaria(REQUEST_STATE.ERROR));
  } finally {
    setTimeout(() => {
      dispatch(setRequestAddInmobiliaria(REQUEST_STATE.IDLE));
    }, 500);    
  }
};

export const fetchUpdateInmobiliaria = (id, data) => async (
  dispatch
) => {
  dispatch(setRequestPutInmobiliaria(REQUEST_STATE.LOADING));
  try {
    const res = await api.apiUpdateInmobiliaria(id, data);
    if (res && res.data) {
      if (res.data.estado === 1) {
        dispatch(updateInmobiliaria(res.mensaje));
        dispatch(setRequestPutInmobiliaria(REQUEST_STATE.SUCCESS));
      } else {
        dispatch(setErrorMessage(res.data.mensaje));
        dispatch(setRequestPutInmobiliaria(REQUEST_STATE.ERROR));
      }
    } else {
      if (res.status) {
        dispatch(setErrorMessage(STANDARD_ERROR_MESSAGES[res.status]));
        dispatch(setRequestPutInmobiliaria(REQUEST_STATE.ERROR));
      }
    }    
  } catch (error) {
    dispatch(setErrorMessage("Ha habido un error"));
    dispatch(setRequestPutInmobiliaria(REQUEST_STATE.ERROR));
  } finally {
    setTimeout(() => {
      dispatch(setRequestPutInmobiliaria(REQUEST_STATE.IDLE));
    }, 500);    
  }
};

export const fetchGetAllClientes = () => async (dispatch) => {
  try {
    const res = await api.apiGetAllClientes();
    dispatch(getClientes(res));
  } catch (error) {
    console.error("error: ", error);
  }
};

export const fetchGetAllVisitas = () => async (dispatch) => {
  try {
    const res = await api.apiGetAllVisitas();
    dispatch(getVisitas(res));
  } catch (error) {
    console.error("error: ", error);
  }
};

export const fetchGetAllPropiedades = () => async (dispatch) => {
  try {
    const res = await api.apiGetAllPropiedades();
    dispatch(getPropiedades(res));
  } catch (error) {
    console.error("error: ", error);
  }
};

export const fetchGetAllInmobiliarias = () => async (dispatch) => {
  dispatch(setRequestGetAllInmobiliarias(REQUEST_STATE.LOADING));
  try {
    const res = await api.apiGetAllInmobiliarias();
    dispatch(getAllInmobiliarias(res.data));
    dispatch(setRequestGetAllInmobiliarias(REQUEST_STATE.SUCCESS));
  } catch (error) {
    dispatch(setErrorMessage("Ha habido un error"));
    dispatch(setRequestGetAllInmobiliarias(REQUEST_STATE.ERROR));
  } finally {
    dispatch(setRequestGetAllInmobiliarias(REQUEST_STATE.IDLE));
  }
};

export const fetchGetUsersByRol = (rol) => async (dispatch) => {
  try {
    const res = await api.apiGetUsersByrol(rol);
    dispatch(getUserByRol(res));
  } catch (error) {
    console.error("error: ", error);
  }
};

export const fetchAddHorarioEmp = (horario) => async (dispatch) => {
  try {
    const res = await api.apiInsertHorario(horario);
    dispatch(addHorarioEmp(res));
  } catch (error) {
    console.error("error: ", error);
  }
};

export const fetchAllRegiones = () => async (dispatch) => {
  dispatch(requestGetRegiones(REQUEST_STATE.LOADING));
  try {
    const res = await api.apiGetAllRegiones();    
    dispatch(requestGetRegiones(REQUEST_STATE.SUCCESS));
    dispatch(getRegiones(res));
  } catch (error) {
    dispatch(requestGetRegiones(REQUEST_STATE.ERROR));
  } finally {
    setTimeout(() => {
      dispatch(requestGetRegiones(REQUEST_STATE.IDLE));
    }, 500);
  }
};

export const fetchPostLoginUser = (pass, mail) => async (dispatch) => {
  dispatch(setLoading(true));
  dispatch(setRequestLogin(REQUEST_STATE.LOADING));
  try {
    const res = await api.apiLoginUsuario(pass, mail);
    if (res && res.data) {
      if (res.data.estado === 1) {
        const loginData = {
          nombres: res.data.data.nombres,
          mail: res.data.data.email,
          token: res.data.data.token,
          userId: res.data.data.id,
          // OrdenesCompra: data.ordenesCompra,
          rut: res.data.data.rut,
          tipoCuenta: res.data.data.tipoCuenta,
          inmobiliariaId: res.data.data.inmobiliariaId,
        };
        dispatch(loginUsuario(loginData));
        dispatch(setRequestLogin(REQUEST_STATE.SUCCESS));
      } else {
        dispatch(setErrorMessage(res.data.mensaje));
        dispatch(setRequestLogin(REQUEST_STATE.ERROR));
      }
    } else {
      if (res.status) {
        dispatch(setErrorMessage(STANDARD_ERROR_MESSAGES[res.status]));
        dispatch(setRequestLogin(REQUEST_STATE.ERROR));
      }
    }
  } catch (error) {
    dispatch(setRequestLogin(REQUEST_STATE.ERROR));
    console.error("error: ", error);
  } finally {
    setTimeout(() => {
      dispatch(setRequestLogin(REQUEST_STATE.IDLE));
      dispatch(setLoading(false));
    }, 500);
  }
};

export const fetchHorarioByUser = () => async (dispatch) => {
  try {
    const res = await api.apiGetHorarioByUser();
    dispatch(getHorarioByUser(res));
  } catch (error) {
    console.error("error: ", error);
  }
};

export const fetchPostUpdatePropiedad = (obj) => async (dispatch) => {
  dispatch(requestUpdatePropiedad(REQUEST_STATE.LOADING));
  try {
    const res = await api.apiUpdatePropiedad(obj);
    if (res && res.data) {
      if (res.data.estado === 1) {
        dispatch(updatePropiedad(res));
        dispatch(requestUpdatePropiedad(REQUEST_STATE.SUCCESS));
      } else {
        dispatch(setErrorMessage(res.data.mensaje));
        dispatch(requestUpdatePropiedad(REQUEST_STATE.ERROR));
      }
    } else {
      if (res.status) {
        dispatch(setErrorMessage(STANDARD_ERROR_MESSAGES[res.status]));
        dispatch(requestUpdatePropiedad(REQUEST_STATE.ERROR));
      }
    }
  } catch (error) {
    console.error("error: ", error);
    dispatch(setErrorMessage("Ha habido un error"));
    dispatch(requestUpdatePropiedad(REQUEST_STATE.ERROR));
  } finally {
    setTimeout(() => {
      dispatch(requestUpdatePropiedad(REQUEST_STATE.IDLE));
    }, 500)
  }
};

export const fetchPostInsertInmobiliaria = (params) => async (dispatch) => {
  try {
    const res = await api.apiInsertInmobiliaria(params);
    dispatch(insertInmobiliaria(res));
  } catch (error) {
    console.error("error: ", error);
  }
};

export const fetchGetAllUsuarios = () => async (dispatch) => {
  try {
    const res = await api.apiListUsuarios();

    dispatch(getUsuarios(res.data));
  } catch (error) {
    console.error("error: ", error);
  }
};

export const fetchGetInmoUsuarios = (userId) => async (dispatch) => {
  try {
    const res = await api.apiInmoUsuarios(userId);

    dispatch(getUsuarios(res.data));
  } catch (error) {
    console.error("error: ", error);
  }
};

export const fecthPostTipoContrato = (data) => async (dispatch) => {
  try {
    const res = await api.apiInsertTipoContrato(data);
    dispatch(postInsertTipocontrato(res));
  } catch (error) {
    console.error("error: ", error);
  }
};

export const fetchAddServicioBase = (servicioBase) => async (dispatch) => {
  dispatch(setRequestAddServicioBase(REQUEST_STATE.LOADING));
  try {
    const res = await api.apiAddServicioBase(servicioBase);
    if (res && res.data) {
      if (res.data.estado === 1) {
        dispatch(addServicioBase(res));
        dispatch(setRequestAddServicioBase(REQUEST_STATE.SUCCESS));
      } else {
        dispatch(setErrorMessage(res.data.mensaje));
        dispatch(setRequestAddServicioBase(REQUEST_STATE.ERROR));
      }
    } else {
      if (res.status) {
        dispatch(setErrorMessage(STANDARD_ERROR_MESSAGES[res.status]));
        dispatch(setRequestAddServicioBase(REQUEST_STATE.ERROR));
      }
    }
  } catch (error) {
    dispatch(setErrorMessage("Ha habido un error"));
    dispatch(setRequestAddServicioBase(REQUEST_STATE.ERROR));
  } finally {
    dispatch(setRequestAddServicioBase(REQUEST_STATE.IDLE));
  }
};

export const fetchGetAllServiciosBase = () => async (dispatch) => {
  try {
    const res = await api.apiListServiciosBases();
    dispatch(getServiciosBase(res));
  } catch (error) {
    console.error("error: ", error);
  }
};

export const fetchAddPlan = (plan) => async (dispatch) => {
  dispatch(setRequestAddPlan(REQUEST_STATE.LOADING));
  try {
    const res = await api.apiAddPlan(plan);
    if (res && res.data) {
      if (res.data.estado === 1) {
        dispatch(setRequestAddPlan(REQUEST_STATE.SUCCESS));
      } else {
        dispatch(setErrorMessage(res.data.mensaje));
        dispatch(setRequestAddPlan(REQUEST_STATE.ERROR));
      }
    } else {
      if (res.status) {
        dispatch(setErrorMessage(STANDARD_ERROR_MESSAGES[res.status]));
        dispatch(setRequestAddPlan(REQUEST_STATE.ERROR));
      }
    }
  } catch (error) {
    dispatch(setErrorMessage("Ha habido un error"));
    dispatch(setRequestAddPlan(REQUEST_STATE.ERROR));
  } finally {
    dispatch(setRequestAddPlan(REQUEST_STATE.IDLE));
  }
};

export const initializeMensaje = () => async (dispatch) => {
  dispatch(setLoading(true));
  dispatch(initialMensaje({}));
  dispatch(setLoading(false));
};

export const fetchGetAllPlanes = () => async (dispatch) => {
  dispatch(setRequestGetPlanes(REQUEST_STATE.LOADING));
  try {
    const res = await api.apiListPlanes();

    if (res && res.data) {
      if (res.data.estado === 1) {
        dispatch(getPlanes(res.data.data));
        dispatch(setRequestGetPlanes(REQUEST_STATE.SUCCESS));
      } else {
        dispatch(setErrorMessage(res.data.mensaje));
        dispatch(setRequestGetPlanes(REQUEST_STATE.ERROR));
      }
    } else {
      if (res.status) {
        dispatch(setErrorMessage(STANDARD_ERROR_MESSAGES[res.status]));
        dispatch(setRequestGetPlanes(REQUEST_STATE.ERROR));
      }
    }
  } catch (error) {
    dispatch(setErrorMessage("Ha habido un error"));
    dispatch(setRequestGetPlanes(REQUEST_STATE.ERROR));
  } finally {
    dispatch(setRequestGetPlanes(REQUEST_STATE.IDLE));
  }
};

export const fetchGetPlanById = (id) => async (dispatch) => {
  try {
    const res = await api.apiGetPlanById(id);
    dispatch(getPlan(res));
  } catch (error) {
    console.error("error: ", error);
  }
};

export const fetchGetOfertasByPublicacion = (id) => async (dispatch) => {
  try {
    const res = await api.apiGetOfertasByPublicacion(id);
    dispatch(getOfertasByPublicacion(res));
  } catch (error) {
    console.error("error: ", error);
  }
};

export const fetchAddImages = (imagenes) => (dispatch) => {
  dispatch(addImagesPropiedad(imagenes));
};
export const fetchResetImages = () => (dispatch) => {
  const imagenes = [];
  dispatch(addImagesPropiedad(imagenes));
};

export const fetchAddValoracion = (valoracion) => async (dispatch) => {
  try {
    const res = await api.apiAddValoracion(valoracion);
    dispatch(addValoracion(res));
  } catch (error) {
    console.error("error: ", error);
  }
};

export const fetchUpdatePLan = (plan) => async (dispatch) => {
  dispatch(setRequestUpdatePlan(REQUEST_STATE.LOADING));
  try {
    const res = await api.apiUpdatePlan(plan);

    if (res && res.data) {
      if (res.data.estado === 1) {
        dispatch(setRequestUpdatePlan(REQUEST_STATE.SUCCESS));
      } else {
        dispatch(setErrorMessage(res.data.mensaje));
        dispatch(setRequestUpdatePlan(REQUEST_STATE.ERROR));
      }
    } else {
      if (res.status) {
        dispatch(setErrorMessage(STANDARD_ERROR_MESSAGES[res.status]));
        dispatch(setRequestUpdatePlan(REQUEST_STATE.ERROR));
      }
    }
  } catch (error) {
    console.error("error: ", error);
    dispatch(setErrorMessage("Ha habido un error"));
    dispatch(setRequestUpdatePlan(REQUEST_STATE.ERROR));
  } finally {
    dispatch(setRequestUpdatePlan(REQUEST_STATE.IDLE));
  }
};

export const fetchUpdatePropiedad = (propiedad) => async (dispatch) => {
  try {
    const res = await api.apiUpdatePropiedad(propiedad);
    dispatch(udpdatePPropiedad(res));
  } catch (error) {
    console.error("error: ", error);
  }
};

export const fetchDeletePlan = (id) => async (dispatch) => {
  dispatch(setRequestDeletePlan(REQUEST_STATE.LOADING));
  try {
    const res = await api.apiDeletePlan(id);
    if (res && res.data) {
      if (res.data.estado === 1) {
        dispatch(deletePlan("Plan eliminado", id));
        dispatch(setRequestDeletePlan(REQUEST_STATE.SUCCESS));
      } else {
        dispatch(setErrorMessage(res.data.mensaje));
        dispatch(setRequestDeletePlan(REQUEST_STATE.ERROR));
      }
    } else {
      if (res.status) {
        dispatch(setErrorMessage(STANDARD_ERROR_MESSAGES[res.status]));
        dispatch(setRequestDeletePlan(REQUEST_STATE.ERROR));
      }
    }
  } catch (error) {
    dispatch(setErrorMessage("Ha habido un error"));
    dispatch(setRequestDeletePlan(REQUEST_STATE.ERROR));
  } finally {
    dispatch(setRequestDeletePlan(REQUEST_STATE.IDLE));
  }
};

export const fetchAddServicioAdicional = (ServicioAdicional) => async (
  dispatch
) => {
  dispatch(setRequestAddServicioAdicional(REQUEST_STATE.LOADING));
  try {
    const res = await api.apiAddServicioAdicional(ServicioAdicional);
    if (res && res.data) {
      if (res.data.estado === 1) {
        dispatch(addServicioAdcional(res.data));
        dispatch(setRequestAddServicioAdicional(REQUEST_STATE.SUCCESS));
      } else {
        dispatch(setErrorMessage(res.data.mensaje));
        dispatch(setRequestAddServicioAdicional(REQUEST_STATE.ERROR));
      }
    } else {
      if (res.status) {
        dispatch(setErrorMessage(STANDARD_ERROR_MESSAGES[res.status]));
        dispatch(setRequestAddServicioAdicional(REQUEST_STATE.ERROR));
      }
    }
  } catch (error) {
    dispatch(setErrorMessage("Ha habido un error"));
    dispatch(setRequestAddServicioAdicional(REQUEST_STATE.ERROR));
  } finally {
    dispatch(setRequestAddServicioAdicional(REQUEST_STATE.IDLE));
  }
};

export const fetchListServicioAdicionals = () => async (dispatch) => {
  try {
    const res = await api.apiListServicioAdicional();
    dispatch(listServiciosAdicionales(res.data.value));
  } catch (error) {
    console.error("error: ", error);
  }
};

export const fetchDeleteServicioAdicional = (id) => async (dispatch) => {
  dispatch(setRequestDeleteServicioAdicional(REQUEST_STATE.LOADING));
  try {
    const res = await api.apiDeleteServicioAdicional(id);
    if (res && res.data) {
      if (res.data.estado === 1) {
        dispatch(DeleteServicioAdicional(id));
        dispatch(setRequestDeleteServicioAdicional(REQUEST_STATE.SUCCESS));
      } else {
        dispatch(setErrorMessage(res.data.mensaje));
        dispatch(setRequestDeleteServicioAdicional(REQUEST_STATE.ERROR));
      }
    } else {
      if (res.status) {
        dispatch(setErrorMessage(STANDARD_ERROR_MESSAGES[res.status]));
        dispatch(setRequestDeleteServicioAdicional(REQUEST_STATE.ERROR));
      }
    }
  } catch (error) {
    dispatch(setErrorMessage("Ha habido un error"));
    dispatch(setRequestDeleteServicioAdicional(REQUEST_STATE.ERROR));
  } finally {
    dispatch(setRequestDeleteServicioAdicional(REQUEST_STATE.IDLE));
  }
};

export const fetchUpdateServicioAdicional = (ServicioAdicional) => async (
  dispatch
) => {
  dispatch(setRequestUpdateServicioAdicional(REQUEST_STATE.LOADING));
  try {
    const res = await api.apiUpdateServicioAdicional(ServicioAdicional);
    if (res && res.data) {
      if (res.data.estado === 1) {
        dispatch(UpdateServicioAdicional(res.data));
        dispatch(setRequestUpdateServicioAdicional(REQUEST_STATE.SUCCESS));
      } else {
        dispatch(setErrorMessage(res.data.mensaje));
        dispatch(setRequestUpdateServicioAdicional(REQUEST_STATE.ERROR));
      }
    } else {
      if (res.status) {
        dispatch(setErrorMessage(STANDARD_ERROR_MESSAGES[res.status]));
        dispatch(setRequestUpdateServicioAdicional(REQUEST_STATE.ERROR));
      }
    }
  } catch (error) {
    dispatch(setErrorMessage("Ha habido un error"));
    dispatch(setRequestUpdateServicioAdicional(REQUEST_STATE.ERROR));
  } finally {
    dispatch(setRequestUpdateServicioAdicional(REQUEST_STATE.IDLE));
  }
};

export const fetchGetAllAgendas = () => async (dispatch) => {
  try {
    const res = await api.apiGetAllAgendas();
    dispatch(getAllAgendas(res.data.value.data));
  } catch (error) {
    console.error("error: ", error);
  }
};
export const fetchUpdateAgendasWithAnfitrion = (agenda) => async (dispatch) => {
  try {
    const res = await api.apiUpdateAgendaWithAnfitrion(agenda);

    dispatch(updateAgendasWithAnfitrion(res.data.value));
  } catch (error) {
    console.error("error: ", error);
  }
};
export const initializePlan = () => async (dispatch) => {
  try {
    dispatch(initPlan());
  } catch (error) {
    console.error("error: ", error);
  }
};
export const fetchDeletePropiedad = (id) => async (dispatch) => {
  dispatch(setRequestDeletePropiedad(REQUEST_STATE.LOADING));
  try {
    const res = await api.apiDeletePropiedad(id);
    if (res && res.data) {
      if (res.data.estado === 1) {
        dispatch(setRequestDeletePropiedad(REQUEST_STATE.SUCCESS));
      } else {
        dispatch(setErrorMessage(res.data.mensaje));
        dispatch(setRequestDeletePropiedad(REQUEST_STATE.ERROR));
      }
    } else {
      if (res.status) {
        dispatch(setErrorMessage(STANDARD_ERROR_MESSAGES[res.status]));
        dispatch(setRequestDeletePropiedad(REQUEST_STATE.ERROR));
      }
    }
  } catch (error) {
    console.error("error: ", error);
    dispatch(setErrorMessage("Ha habido un error"));
    dispatch(setRequestDeletePropiedad(REQUEST_STATE.ERROR));
  } finally {
    setTimeout(() => {
      dispatch(setRequestDeletePropiedad(REQUEST_STATE.IDLE));
    }, 500);
  }
};

export const fetchGetSignature = (meetingNumber, role, proyectoId) => async (
  dispatch
) => {
  try {
    const res = await api.apiGetSignature(meetingNumber, role, proyectoId);
    dispatch({
      type: SIGNATURE,
      signature: res,
    });
  } catch (error) {
    console.error("error: ", error);
  }
};

export const postProyecto = (proyecto) => async (dispatch) => {
  dispatch(setRequestPostProyecto(REQUEST_STATE.LOADING));
  try {
    const res = await api.apiPostProyecto(proyecto, proyecto.inmobiliariaId);
    if (res && res.data) {
      if (res.data.estado === 1) {
        dispatch(postProyectoResAction(res));
        dispatch(setRequestPostProyecto(REQUEST_STATE.SUCCESS));
      } else {
        dispatch(setErrorMessage(res.data.mensaje));
        dispatch(setRequestPostProyecto(REQUEST_STATE.ERROR));
      }
    } else {
      if (res.status) {
        dispatch(setErrorMessage(STANDARD_ERROR_MESSAGES[res.status]));
        dispatch(setRequestPostProyecto(REQUEST_STATE.ERROR));
      }
    }
  } catch (e) {
    console.error("e: ", e);
    dispatch(setErrorMessage("Ha habido un error"));
    dispatch(setRequestPostProyecto(REQUEST_STATE.ERROR));
  } finally {
    setTimeout(() => {
      dispatch(setRequestPostProyecto(REQUEST_STATE.IDLE));
    }, 500);    
  }
};

export const putProyecto = (proyecto) => async (dispatch) => {
  dispatch(setRequestPutProyecto(REQUEST_STATE.LOADING));
  try {
    const res = await api.apiPutProyecto(proyecto, proyecto.inmobiliariaId);
    if (res && res.data) {
      if (res.data.estado === 1) {
        dispatch(putProyectoResAction(res));
        dispatch(setRequestPutProyecto(REQUEST_STATE.SUCCESS));
      } else {
        dispatch(setErrorMessage(res.data.mensaje));
        dispatch(setRequestPutProyecto(REQUEST_STATE.ERROR));
      }
    } else {
      if (res.status) {
        dispatch(setErrorMessage(STANDARD_ERROR_MESSAGES[res.status]));
        dispatch(setRequestPutProyecto(REQUEST_STATE.ERROR));
      }
    }
  } catch (error) {
    console.error("error: ", error);
    dispatch(setErrorMessage("Ha habido un error"));
    dispatch(setRequestPutProyecto(REQUEST_STATE.ERROR));
  } finally {
    setTimeout(() => {
      dispatch(setRequestPutProyecto(REQUEST_STATE.IDLE));
    }, 500);
  }
};

export const getAllProyectos = () => async (dispatch) => {
  try {
    const res = await api.getAllProyectosApi();
    dispatch(getAllProyectosAction(res));
  } catch (error) {
    console.error("error: ", error);
  }
};

export const getUserProyectos = (userId) => async (dispatch) => {
  try {
    const res = await api.getUserProyectosApi(userId);
    dispatch(getAllProyectosAction(res));
  } catch (error) {
    console.error("error: ", error);
  }
};

export const createTarjetaProyecto = (idProyecto) => async (dispatch) => {
  dispatch(setRequestPostTarjetaProyecto(REQUEST_STATE.LOADING));
  try {
    const res = await api.apiPostTarjetaProyecto(idProyecto);
    if (res && res.data) {
      if (res.data.estado === 1) {
        dispatch(setPostTarjetaProyecto(res.data));
        dispatch(setResponseMessage(res));
        dispatch(setRequestPostTarjetaProyecto(REQUEST_STATE.SUCCESS));
      } else {
        dispatch(setErrorMessage(res.data.mensaje));
        dispatch(setRequestPostTarjetaProyecto(REQUEST_STATE.ERROR));
      }
    } else {
      if (res.status) {
        dispatch(setErrorMessage(STANDARD_ERROR_MESSAGES[res.status]));
        dispatch(setRequestPostTarjetaProyecto(REQUEST_STATE.ERROR));
      }
    }
  } catch (error) {
    console.error("error: ", error);
    dispatch(setErrorMessage("Ha habido un error"));
    dispatch(setRequestPostTarjetaProyecto(REQUEST_STATE.ERROR));
  } finally {
    setTimeout(() => {
      dispatch(setRequestPostTarjetaProyecto(REQUEST_STATE.IDLE));
    }, 500)
  }
};

export const deleteTarjetaProyectoByIdProyecto = (idProyecto) => async (
  dispatch
) => {
  dispatch(setRequestDeleteTarjetaProyecto(REQUEST_STATE.LOADING));
  try {
    const res = await api.apiDeleteTarjetaProyectoByIdProyecto(idProyecto);
    if (res && res.data) {
      if (res.data.estado === 1) {
        dispatch(setResponseMessage(res));
        dispatch(setRequestDeleteTarjetaProyecto(REQUEST_STATE.SUCCESS));
      } else {
        dispatch(setErrorMessage(res.data.mensaje));
        dispatch(setRequestDeleteTarjetaProyecto(REQUEST_STATE.ERROR));
      }
    } else {
      if (res.status) {
        dispatch(setErrorMessage(STANDARD_ERROR_MESSAGES[res.status]));
        dispatch(setRequestDeleteTarjetaProyecto(REQUEST_STATE.ERROR));
      }
    }
  } catch (error) {
    console.error("error: ", error);
    dispatch(setErrorMessage("Ha habido un error"));
    dispatch(setRequestDeleteTarjetaProyecto(REQUEST_STATE.ERROR));
  } finally {
    setTimeout(() => {
      dispatch(setRequestDeleteTarjetaProyecto(REQUEST_STATE.IDLE));
    }, 500)
  }
};

export const updateEstadoPropiedad = (id, data) => async (dispatch) => {
  dispatch(setRequestUpdateEstadoPropiedad(REQUEST_STATE.LOADING));
  try {
    const res = await api.apiUpdateEstadoPropiedad(id, data);
    if (res && res.data) {
      if (res.data.estado === 1) {
        dispatch(setResponseMessage(res));
        dispatch(setRequestUpdateEstadoPropiedad(REQUEST_STATE.SUCCESS));
      } else {
        dispatch(setErrorMessage(res.data.mensaje));
        dispatch(setRequestUpdateEstadoPropiedad(REQUEST_STATE.ERROR));
      }
    } else {
      if (res.status) {
        dispatch(setErrorMessage(STANDARD_ERROR_MESSAGES[res.status]));
        dispatch(setRequestUpdateEstadoPropiedad(REQUEST_STATE.ERROR));
      }
    }
  } catch (error) {
    console.error("error: ", error);
    dispatch(setErrorMessage("Ha habido un error"));
    dispatch(setRequestUpdateEstadoPropiedad(REQUEST_STATE.ERROR));
  } finally {
    dispatch(setRequestUpdateEstadoPropiedad(REQUEST_STATE.IDLE));
  }
};

export const getPropiedadesPaginadas = (
  estado,
  idBroker,
  page,
  pageSize,
  tieneBroker,
  sortBy,
  comuna, tipoPropiedad, tipoOperacion, valorDesde, valorHasta, banioDesde, banioHasta, dormitorioDesde, dormitorioHasta, superficieUtilDesde,
  superficieUtilHasta, superficieTotalDesde, superficieTotalHasta, codigoPropiedad, tipoMoneda, idCliente, direccion, estacionamiento, bodega
) => async (dispatch) => {
  dispatch(setRequestPropiedadesPaginadas(REQUEST_STATE.LOADING));
  try {
    const res = await api.apiGetAllPropiedadesPaginadas(
      estado,
      idBroker,
      page,
      pageSize,
      tieneBroker,
      sortBy,
      comuna, tipoPropiedad, tipoOperacion, valorDesde, valorHasta, banioDesde, banioHasta, dormitorioDesde, dormitorioHasta, superficieUtilDesde,
      superficieUtilHasta, superficieTotalDesde, superficieTotalHasta, codigoPropiedad, tipoMoneda, idCliente, direccion,
      estacionamiento, bodega
    );
    dispatch(setRequestPropiedadesPaginadas(REQUEST_STATE.SUCCESS));
    dispatch(setPropiedadesPaginadas(res.data));
  } catch (error) {
    console.error("error: ", error);
    dispatch(setRequestPropiedadesPaginadas(REQUEST_STATE.ERROR));
  } finally {
    setTimeout(() => {
      console.log("Delayed for 1 second.");
      dispatch(setRequestPropiedadesPaginadas(REQUEST_STATE.IDLE));
    }, 500)
    
  }
};

export const getPropiedadById = (id) => async (dispatch) => {
  dispatch(setRequestGetPropiedad(REQUEST_STATE.LOADING));
  try {
    const res = await api.apiGetPropiedadById(id);
    dispatch(setRequestGetPropiedad(REQUEST_STATE.SUCCESS));
    dispatch(setPropiedad(res));
  } catch (error) {
    console.error("error: ", error);
    dispatch(setRequestGetPropiedad(REQUEST_STATE.ERROR));
  } finally {
    dispatch(setRequestGetPropiedad(REQUEST_STATE.IDLE));
  }
};

export const postTarjetaProp = (id) => async (dispatch) => {
  dispatch(setRequestPostTarjetaProp(REQUEST_STATE.LOADING));
  try {
    const res = await api.apiPostGenerarTarjetaProp(id);
    if (res && res.data) {
      if (res.data.estado === 1) {
        dispatch(setTarjetaProp(res));
        dispatch(setRequestPostTarjetaProp(REQUEST_STATE.SUCCESS));
      } else {
        dispatch(setErrorMessage(res.data.mensaje));
        dispatch(setRequestPostTarjetaProp(REQUEST_STATE.ERROR));
      }
    } else {
      if (res.status) {
        dispatch(setErrorMessage(STANDARD_ERROR_MESSAGES[res.status]));
        dispatch(setRequestPostTarjetaProp(REQUEST_STATE.ERROR));
      }
    }
  } catch (error) {
    dispatch(setErrorMessage("Ha habido un error"));
    dispatch(setRequestPostTarjetaProp(REQUEST_STATE.ERROR));
  } finally {
    setTimeout(() => {
      dispatch(setRequestPostTarjetaProp(REQUEST_STATE.IDLE));
    }, 500);
  }
};

export const deleteTarjetaProp = (id) => async (dispatch) => {
  dispatch(setRequestDeleteTarjetaProp(REQUEST_STATE.LOADING));
  try {
    const res = await api.apiDeleteTarjetaProp(id);
    if (res && res.data) {
      if (res.data.estado === 1) {
        dispatch(setRequestDeleteTarjetaProp(REQUEST_STATE.SUCCESS));
      } else {
        dispatch(setErrorMessage(res.data.mensaje));
        dispatch(setRequestDeleteTarjetaProp(REQUEST_STATE.ERROR));
      }
    } else {
      if (res.status) {
        dispatch(setErrorMessage(STANDARD_ERROR_MESSAGES[res.status]));
        dispatch(setRequestDeleteTarjetaProp(REQUEST_STATE.ERROR));
      }
    }
  } catch (error) {
    dispatch(setErrorMessage("Ha habido un error"));
    dispatch(setRequestDeleteTarjetaProp(REQUEST_STATE.ERROR));
  } finally {
    setTimeout(() => {
      dispatch(setRequestDeleteTarjetaProp(REQUEST_STATE.IDLE));
    }, 500);    
  }
};

export const postUploadDatosTasacion = (file, tipo) => async (dispatch) => {
  dispatch(setRequestPostUploadDatosTasacion(REQUEST_STATE.LOADING));
  try {
    const res = await api.apiPostUploadDatosTasacion(file, tipo);
    if (res && res.data) {
      if (res.data.estado === 1) {
        dispatch(setRequestPostUploadDatosTasacion(REQUEST_STATE.SUCCESS));
      } else {
        dispatch(setErrorMessage(res.data.mensaje));
        dispatch(setRequestPostUploadDatosTasacion(REQUEST_STATE.ERROR));
      }
    } else {
      if (res.status) {
        dispatch(setErrorMessage(STANDARD_ERROR_MESSAGES[res.status]));
        dispatch(setRequestPostUploadDatosTasacion(REQUEST_STATE.ERROR));
      }
    }
  } catch (error) {
    console.error("error: ", error);
    dispatch(setErrorMessage("Ha habido un error"));
    dispatch(setRequestPostUploadDatosTasacion(REQUEST_STATE.ERROR));
  } finally {
    dispatch(setRequestPostUploadDatosTasacion(REQUEST_STATE.IDLE));
  }
};

export const deleteAllDatosTasacion = () => async (dispatch) => {
  dispatch(setRequestDeleteAllDatosTasacion(REQUEST_STATE.LOADING));
  try {
    const res = await api.apiDeleteAllDatosTasacion();
    dispatch(setRequestDeleteAllDatosTasacion(REQUEST_STATE.SUCCESS));
  } catch (error) {
    console.error("error: ", error);
    dispatch(setRequestDeleteAllDatosTasacion(REQUEST_STATE.ERROR));
  } finally {
    dispatch(setRequestDeleteAllDatosTasacion(REQUEST_STATE.IDLE));
  }
};

export const postUploadPropiedadesPI = (tipo, file) => async (dispatch) => {
  dispatch(setRequestPostUploadPIPropiedades(REQUEST_STATE.LOADING));
  try {
    const res = await api.apiPostUploadPIPropiedades(tipo, file);

    if (res && res.data) {
      if (res.data.estado === 1) {
        dispatch(setRequestPostUploadPIPropiedades(REQUEST_STATE.SUCCESS));
      } else {
        dispatch(setErrorMessage(res.data.mensaje));
        dispatch(setRequestPostUploadPIPropiedades(REQUEST_STATE.ERROR));
      }
    } else {
      if (res.status) {
        dispatch(setErrorMessage(STANDARD_ERROR_MESSAGES[res.status]));
        dispatch(setRequestPostUploadPIPropiedades(REQUEST_STATE.ERROR));
      }
    }
  } catch (error) {
    dispatch(setErrorMessage("Ha habido un error"));
    dispatch(setRequestPostUploadPIPropiedades(REQUEST_STATE.ERROR));
  } finally {
    dispatch(setRequestPostUploadPIPropiedades(REQUEST_STATE.IDLE));
  }
};

export const postAddSuscripcion = (data) => async (dispatch) => {
  dispatch(setRequestPostAddSuscripcion(REQUEST_STATE.LOADING));
  try {
    const res = await api.apiPostSuscripcion(data);
    dispatch(setRequestPostAddSuscripcion(REQUEST_STATE.SUCCESS));
  } catch (error) {
    console.error("error: ", error);
    dispatch(setRequestPostAddSuscripcion(REQUEST_STATE.ERROR));
  } finally {
    dispatch(setRequestPostAddSuscripcion(REQUEST_STATE.IDLE));
  }
};

export const getSuscripcionExport = () => async (dispatch) => {
  dispatch(setRequestSuscripcionExcel(REQUEST_STATE.LOADING));
  try {
    const res = await api.apiGetSuscripcionExportar();
    dispatch(setSuscripcionExcel(res));
    dispatch(setRequestSuscripcionExcel(REQUEST_STATE.SUCCESS));
  } catch (error) {
    console.error("error: ", error);
    dispatch(setRequestSuscripcionExcel(REQUEST_STATE.ERROR));
  } finally {
    dispatch(setRequestSuscripcionExcel(REQUEST_STATE.IDLE));
  }
};

export const getExcelYapo = () => async (dispatch) => {
  dispatch(setRequestExcelYapo(REQUEST_STATE.LOADING));
  try {
    const res = await api.apiGetExcelYapo();
    dispatch(setExcelYapo(res));
    dispatch(setRequestExcelYapo(REQUEST_STATE.SUCCESS));
  } catch (error) {
    console.error("error: ", error);
    dispatch(setRequestExcelYapo(REQUEST_STATE.ERROR));
  } finally {
    dispatch(setRequestExcelYapo(REQUEST_STATE.IDLE));
  }
};

export const getSuscripcionLastUpdated = () => async (dispatch) => {
  dispatch(setRequestSuscripcionLastUpdated(REQUEST_STATE.LOADING));
  try {
    const res = await api.apiGetSuscripcionLastUpdated();
    dispatch(setSuscripcionLastUpdated(res));
    dispatch(setRequestSuscripcionLastUpdated(REQUEST_STATE.SUCCESS));
  } catch (error) {
    console.error("error: ", error);
    dispatch(setRequestSuscripcionLastUpdated(REQUEST_STATE.ERROR));
  } finally {
    dispatch(setRequestSuscripcionLastUpdated(REQUEST_STATE.IDLE));
  }
};

export const updateUsuario = (data) => async (dispatch) => {
  dispatch(setRequestUpdateUsuario(REQUEST_STATE.LOADING));
  try {
    const res = await api.apiPostUpdateUser(data);
    if (res && res.data) {
      if (res.data.estado === 1) {
        dispatch(setUsuarioByFilter(res.data))
        dispatch(setRequestUpdateUsuario(REQUEST_STATE.SUCCESS));
      } else {
        dispatch(setErrorMessage(res.data.mensaje));
        dispatch(setRequestUpdateUsuario(REQUEST_STATE.ERROR));
      }
    } else {
      if (res.status) {
        dispatch(setErrorMessage(STANDARD_ERROR_MESSAGES[res.status]));
        dispatch(setRequestUpdateUsuario(REQUEST_STATE.ERROR));
      }
    }
  } catch (error) {
    dispatch(setErrorMessage("Ha habido un error"));
    dispatch(setRequestUpdateUsuario(REQUEST_STATE.ERROR));
  } finally {
    setTimeout(() => {
      dispatch(setRequestUpdateUsuario(REQUEST_STATE.IDLE));
    }, 500);
  }
};

export const getVisitasFotografoByFecha = (fecha) => async (dispatch) => {
  dispatch(setRequestLoadingVisitaFotografo(true));
  try {
    const res = await api.apiGetVisitasFotografoByFecha(fecha);
    dispatch(setVisitaFotografo(res.data));
  } catch (error) {
    console.error("error: ", error);
  } finally {
    dispatch(setRequestLoadingVisitaFotografo(false));
  }
};

export const getAgendaFotografo = () => async (dispatch) => {
  dispatch(setRequestLoadingAgendaFotografo(true));
  try {
    const res = await api.apiGetAgendaFotografo();
    dispatch(setAgendaFotografo(res.data));
  } catch (error) {
    console.error("error: ", error);
  } finally {
    dispatch(setRequestLoadingAgendaFotografo(false));
  }
};

export const getUsuariosFiltrados = (tipoCuenta) => async (dispatch) => {
  dispatch(setRequestUsuariosFiltrados(REQUEST_STATE.LOADING));
  try {
    const res = await api.apiGetUsuariosFiltrar(tipoCuenta);
    dispatch(setUsuariosFiltrados(res.data));
    dispatch(setRequestUsuariosFiltrados(REQUEST_STATE.SUCCESS));
  } catch (error) {
    console.error("error: ", error);
    dispatch(setRequestUsuariosFiltrados(REQUEST_STATE.ERROR));
  } finally {
    dispatch(setRequestUsuariosFiltrados(REQUEST_STATE.IDLE));
  }
};

export const putPropiedadAsignarBroker = (idPropiedad, idBroker) => async (
  dispatch
) => {
  dispatch(setRequestAsignarBroker(REQUEST_STATE.LOADING));
  try {
    const res = await api.apiPutPropiedadAsignarBroker(idPropiedad, idBroker);
    if (res && res.data) {
      if (res.data.estado === 1) {
        dispatch(setAsignarBroker(res.data));
        dispatch(setRequestAsignarBroker(REQUEST_STATE.SUCCESS));
      } else {
        dispatch(setErrorMessage(res.data.mensaje));
        dispatch(setRequestAsignarBroker(REQUEST_STATE.ERROR));
      }
    } else {
      if (res.status) {
        dispatch(setErrorMessage(STANDARD_ERROR_MESSAGES[res.status]));
        dispatch(setRequestAsignarBroker(REQUEST_STATE.ERROR));
      }
    }
  } catch (error) {
    dispatch(setErrorMessage("Ha habido un error"));
    dispatch(setRequestAsignarBroker(REQUEST_STATE.ERROR));
  } finally {    
    setTimeout(() => {
      dispatch(setRequestAsignarBroker(REQUEST_STATE.IDLE));
    }, 500);
  }
};

export const getSuscripciones = (page, pageSize) => async (dispatch) => {
  dispatch(setRequestSuscripciones(REQUEST_STATE.LOADING));
  try {
    const res = await api.apiGetSuscripciones(page, pageSize);
    dispatch(setSuscripciones(res.data));
    dispatch(setRequestSuscripciones(REQUEST_STATE.SUCCESS));
  } catch (error) {
    console.error("error: ", error);
    dispatch(setRequestSuscripciones(REQUEST_STATE.ERROR));
  } finally {
    dispatch(setRequestSuscripciones(REQUEST_STATE.IDLE));
  }
};

export const updateSuscripcion = (id, suscripcion) => async (dispatch) => {
  dispatch(setRequestUpdateSuscripcion(REQUEST_STATE.LOADING));
  try {
    const res = await api.apiUpdateSuscripcion(id, suscripcion);
    dispatch(setUpdateSuscripcion(res.data));
    dispatch(setRequestUpdateSuscripcion(REQUEST_STATE.SUCCESS));
  } catch (error) {
    console.error("error: ", error);
    dispatch(setRequestUpdateSuscripcion(REQUEST_STATE.ERROR));
  } finally {
    dispatch(setRequestUpdateSuscripcion(REQUEST_STATE.IDLE));
  }
};

export const getAgendaClienteByPropiedadId = (idPropiedad) => async (
  dispatch
) => {
  dispatch(setRequestAgendaCliente(REQUEST_STATE.LOADING));
  try {
    const res = await api.apiGetAgendaClienteByPropiedadId(idPropiedad);
    dispatch(setAgendaCliente(res.data));
    dispatch(setRequestAgendaCliente(REQUEST_STATE.SUCCESS));
  } catch (error) {
    console.error("error: ", error);
    dispatch(setRequestAgendaCliente(REQUEST_STATE.ERROR));
  } finally {
    dispatch(setRequestAgendaCliente(REQUEST_STATE.IDLE));
  }
};

export const getVisitasClienteByFechaAndId = (fecha, id) => async (
  dispatch
) => {
  dispatch(setRequestVisitasByFechaAndId(REQUEST_STATE.LOADING));
  try {
    const res = await api.apiGetVisitasClienteByFechaYId(id, fecha);
    dispatch(setVisitasByFechaAndId(res.data));
    dispatch(setRequestVisitasByFechaAndId(REQUEST_STATE.SUCCESS));
  } catch (error) {
    console.error("error: ", error);
    dispatch(setRequestVisitasByFechaAndId(REQUEST_STATE.ERROR));
  } finally {
    dispatch(setRequestVisitasByFechaAndId(REQUEST_STATE.IDLE));
  }
};

export const postAgendarVisitaBrokerSuscriptor = (data) => async (dispatch) => {
  dispatch(setRequestAgendarVisitaBrokerSuscriptor(REQUEST_STATE.LOADING));
  try {
    const res = await api.apiPostAgendarVisitaBrokerSuscriptor(data);
    dispatch(setRequestAgendarVisitaBrokerSuscriptor(REQUEST_STATE.SUCCESS));
  } catch (error) {
    console.error("error: ", error);
    dispatch(setRequestAgendarVisitaBrokerSuscriptor(REQUEST_STATE.ERROR));
  } finally {
    dispatch(setRequestAgendarVisitaBrokerSuscriptor(REQUEST_STATE.IDLE));
  }
};

export const getVisitasFiltradas = (
  fechaInicial,
  fechaFinal,
  idBroker,
  idSuscripcion,
  mostrarSoloSinConfirmar,
  mostrarSoloTramos,
  incluirVisitasPasadas,
  incluirVisitasFuturas
) => async (dispatch) => {
  dispatch(setRequestVisitasBrokerSuscriptor(REQUEST_STATE.LOADING));
  try {
    const res = await api.apiGetVisitasFiltradas(
      fechaInicial,
      fechaFinal,
      idBroker,
      idSuscripcion,
      mostrarSoloSinConfirmar,
      mostrarSoloTramos,
      incluirVisitasPasadas,
      incluirVisitasFuturas
    );
    dispatch(setVisitasBrokerSuscriptor(res.data));
    dispatch(setRequestVisitasBrokerSuscriptor(REQUEST_STATE.SUCCESS));
  } catch (error) {
    console.error("error: ", error);
    dispatch(setRequestVisitasBrokerSuscriptor(REQUEST_STATE.ERROR));
  } finally {
    dispatch(setRequestVisitasBrokerSuscriptor(REQUEST_STATE.IDLE));
  }
};

export const getSuscripcion = (id) => async (dispatch) => {
  dispatch(setRequestSuscripcion(REQUEST_STATE.LOADING));
  try {
    const res = await api.apiGetSuscripcion(id);
    dispatch(setSuscripcion(res.data));
    dispatch(setRequestSuscripcion(REQUEST_STATE.SUCCESS));
  } catch (error) {
    console.error("error: ", error);
    dispatch(setRequestSuscripcion(REQUEST_STATE.ERROR));
  } finally {
    dispatch(setRequestSuscripcion(REQUEST_STATE.IDLE));
  }
};

export const postReferirEmbajador = (data) => async (dispatch) => {
  dispatch(setRequestReferirEmbajador(REQUEST_STATE.LOADING));
  try {
    const res = await api.apiPostReferirEmbajador(data);
    if (res && res.data) {
      if (res.data.estado === 1) {
        dispatch(setEmbajador(res.data));
        dispatch(setRequestReferirEmbajador(REQUEST_STATE.SUCCESS));
      } else {
        dispatch(setErrorMessage(res.data.mensaje));
        dispatch(setRequestReferirEmbajador(REQUEST_STATE.ERROR));
      }
    } else {
      if (res.status) {
        dispatch(setErrorMessage(STANDARD_ERROR_MESSAGES[res.status]));
        dispatch(setRequestReferirEmbajador(REQUEST_STATE.ERROR));
      }
    }
  } catch (error) {
    console.error("error: ", error);
    dispatch(setErrorMessage("Ha habido un error"));
    dispatch(setRequestReferirEmbajador(REQUEST_STATE.ERROR));
  } finally {
    dispatch(setRequestReferirEmbajador(REQUEST_STATE.IDLE));
  }
};

export const postBroker = (data) => async (dispatch) => {
  dispatch(setRequestPostBroker(REQUEST_STATE.LOADING));
  try {
    const res = await api.apiPostBroker(data);
    dispatch(setBroker(res.data));
    dispatch(setRequestPostBroker(REQUEST_STATE.SUCCESS));
  } catch (error) {
    console.error("error: ", error);
    dispatch(setRequestPostBroker(REQUEST_STATE.ERROR));
  } finally {
    dispatch(setRequestPostBroker(REQUEST_STATE.IDLE));
  }
};

export const getBrokerByEmail = (email) => async (dispatch) => {
  dispatch(setRequestGetBroker(REQUEST_STATE.LOADING));
  try {
    const res = await api.apiGetBrokerByEmail(email);
    dispatch(setBroker(res.data));
    dispatch(setRequestGetBroker(REQUEST_STATE.SUCCESS));
  } catch (error) {
    console.error("error: ", error);
    dispatch(setRequestGetBroker(REQUEST_STATE.ERROR));
  } finally {
    dispatch(setRequestGetBroker(REQUEST_STATE.IDLE));
  }
};

export const putBroker = (id, broker) => async (dispatch) => {
  dispatch(setRequestPutBroker(REQUEST_STATE.LOADING));
  try {
    const res = await api.apiPutBroker(id, broker);
    dispatch(setBroker(res.data));
    dispatch(setRequestPutBroker(REQUEST_STATE.SUCCESS));
  } catch (error) {
    console.error("error: ", error);
    dispatch(setRequestPutBroker(REQUEST_STATE.ERROR));
  } finally {
    dispatch(setRequestPutBroker(REQUEST_STATE.IDLE));
  }
};

export const postCambiarContraseña = (data) => async (dispatch) => {
  dispatch(setRequestCambioContraseña(REQUEST_STATE.LOADING));
  try {
    const res = await api.apiPostCambiarContraseña(data);
    if (res !== null && res.estado !== null && res.estado === 1) {
      dispatch(setRequestCambioContraseña(REQUEST_STATE.SUCCESS));
    } else {
      dispatch(setRequestCambioContraseña(REQUEST_STATE.ERROR));
      if (res !== null && res.mensaje !== null && res.mensaje !== undefined) {
        dispatch(setErrorMessage(res.mensaje));
      } else {
        dispatch(
          setErrorMessage(
            "Ha habido un error cambiando su contraseña. Verifique que la contraseña antigua ingresada sea la correcta e inténtelo de nuevo"
          )
        );
      }
    }
  } catch (error) {
    console.error("error: ", error);
    dispatch(
      setErrorMessage(
        "Ha habido un error cambiando su contraseña. Verifique que la contraseña antigua ingresada sea la correcta e inténtelo de nuevo"
      )
    );
    dispatch(setRequestCambioContraseña(REQUEST_STATE.ERROR));
  } finally {
    dispatch(setRequestCambioContraseña(REQUEST_STATE.IDLE));
  }
};

export const getUsuario = () => async (dispatch) => {
  dispatch(setRequestGetUsuario(REQUEST_STATE.LOADING));
  try {
    const res = await api.apiGetUsuario();
    dispatch(setUsuario(res.data));
    dispatch(setRequestGetUsuario(REQUEST_STATE.SUCCESS));
  } catch (error) {
    console.error("error: ", error);
    dispatch(setRequestGetUsuario(REQUEST_STATE.ERROR));
  } finally {
    dispatch(setRequestGetUsuario(REQUEST_STATE.IDLE));
  }
};

export const confirmarVisita = (idVisita, visitaRealizada) => async (
  dispatch
) => {
  dispatch(setRequestConfirmarVisita(REQUEST_STATE.LOADING));
  try {
    const res = await api.apiPutConfirmarVisita(idVisita, visitaRealizada);
    dispatch(setRequestConfirmarVisita(REQUEST_STATE.SUCCESS));
  } catch (error) {
    console.error("error: ", error);
    dispatch(setRequestConfirmarVisita(REQUEST_STATE.ERROR));
  } finally {
    dispatch(setRequestConfirmarVisita(REQUEST_STATE.IDLE));
  }
};

export const postReferirVendedor = (data) => async (dispatch) => {
  dispatch(setRequestReferirVendedor(REQUEST_STATE.LOADING));
  try {
    const res = await api.apiPostReferirVendedor(data);

    if (res && res.data) {
      if (res.data.estado === 1) {
        dispatch(setReferirVendedor(res.data));
        dispatch(setRequestReferirVendedor(REQUEST_STATE.SUCCESS));
      } else {
        dispatch(setErrorMessage(res.data.mensaje));
        dispatch(setRequestReferirVendedor(REQUEST_STATE.ERROR));
      }
    } else {
      if (res.status) {
        dispatch(setErrorMessage(STANDARD_ERROR_MESSAGES[res.status]));
        dispatch(setRequestReferirVendedor(REQUEST_STATE.ERROR));
      }
    }
  } catch (error) {
    console.error("error: ", error);
    dispatch(setErrorMessage("Ha habido un error"));
    dispatch(setRequestReferirVendedor(REQUEST_STATE.ERROR));
  } finally {
    dispatch(setRequestReferirVendedor(REQUEST_STATE.IDLE));
  }
};

export const getVendedores = (page, rowsPerPage) => async (dispatch) => {
  dispatch(setRequestGetVendedores(REQUEST_STATE.LOADING));
  try {
    const res = await api.apiGetVendedores(page, rowsPerPage);
    dispatch(setVendedores(res.data));
    dispatch(setRequestGetVendedores(REQUEST_STATE.SUCCESS));
  } catch (error) {
    console.error("error: ", error);
    dispatch(setRequestGetVendedores(REQUEST_STATE.ERROR));
  } finally {
    dispatch(setRequestGetVendedores(REQUEST_STATE.IDLE));
  }
};

export const putUsuarioCambiarContactado = (idUsuario) => async (dispatch) => {
  dispatch(setRequestUsuarioCambiarContactado(REQUEST_STATE.LOADING));
  try {
    const res = await api.apiPutUsuarioCambiarContactado(idUsuario);

    if (res && res.data) {
      if (res.data.estado === 1) {
        dispatch(setRequestUsuarioCambiarContactado(REQUEST_STATE.SUCCESS));
      } else {
        dispatch(setErrorMessage(res.data.mensaje));
        dispatch(setRequestUsuarioCambiarContactado(REQUEST_STATE.ERROR));
      }
    } else {
      if (res.status) {
        dispatch(setErrorMessage(STANDARD_ERROR_MESSAGES[res.status]));
        dispatch(setRequestUsuarioCambiarContactado(REQUEST_STATE.ERROR));
      }
    }
  } catch (error) {
    console.error("error: ", error);
    dispatch(setErrorMessage("Ha habido un error"));
    dispatch(setRequestUsuarioCambiarContactado(REQUEST_STATE.ERROR));
  } finally {
    dispatch(setRequestUsuarioCambiarContactado(REQUEST_STATE.IDLE));
  }
};

export const getAllBrokers = () => async (dispatch) => {
  dispatch(setRequestGetAllBrokers(REQUEST_STATE.LOADING));
  try {
    const res = await api.apiGetAllBrokers();
    if (res !== null && res.estado !== null && res.estado === 1) {
      dispatch(setRequestGetAllBrokers(REQUEST_STATE.SUCCESS));
      dispatch(setBrokers(res.data));
    } else {
      dispatch(setRequestGetAllBrokers(REQUEST_STATE.ERROR));
      if (res !== null && res.mensaje !== null && res.mensaje !== undefined) {
        dispatch(setErrorMessage(res.mensaje));
      } else {
        dispatch(setErrorMessage("Ha habido un error con tu solicitud"));
      }
    }
  } catch (error) {
    console.error("error: ", error);
    dispatch(setErrorMessage("Ha habido un error con tu solicitud"));
    dispatch(setRequestGetAllBrokers(REQUEST_STATE.ERROR));
  } finally {
    dispatch(setRequestGetAllBrokers(REQUEST_STATE.IDLE));
  }
};

export const updateAgendaCliente = (data) => async (dispatch) => {
  dispatch(setRequestUpdateAgendaCliente(REQUEST_STATE.LOADING));
  try {
    const res = await api.apiUpdateClienteAgenda(data);
    if (res !== null && res.estado !== null && res.estado === 1) {
      dispatch(setRequestUpdateAgendaCliente(REQUEST_STATE.SUCCESS));
    } else {
      dispatch(setRequestUpdateAgendaCliente(REQUEST_STATE.ERROR));
      if (res !== null && res.mensaje !== null && res.mensaje !== undefined) {
        dispatch(setErrorMessage(res.mensaje));
      } else {
        dispatch(
          setErrorMessage(
            "Ha habido un error actualizando la agenda. Inténtelo de nuevo más tarde"
          )
        );
      }
    }
  } catch (error) {
    console.error("error: ", error);
    dispatch(
      setErrorMessage(
        "Ha habido un error actualizando la agenda. Inténtelo de nuevo más tarde"
      )
    );
    dispatch(setRequestUpdateAgendaCliente(REQUEST_STATE.ERROR));
  } finally {
    dispatch(setRequestUpdateAgendaCliente(REQUEST_STATE.IDLE));
  }
};

export const postAgendarVisitaUsuario = (data) => async (dispatch) => {
  dispatch(setRequestAgendarVisita(REQUEST_STATE.LOADING));
  try {
    const res = await api.apiPostAgendaVisitaUsuario(data);

    if (res && res.data) {
      if (res.data.estado === 1) {
        dispatch(setRequestAgendarVisita(REQUEST_STATE.SUCCESS));
      } else {
        dispatch(setErrorMessage(res.data.mensaje));
        dispatch(setRequestAgendarVisita(REQUEST_STATE.ERROR));
      }
    } else {
      if (res.status) {
        dispatch(setErrorMessage(STANDARD_ERROR_MESSAGES[res.status]));
        dispatch(setRequestAgendarVisita(REQUEST_STATE.ERROR));
      }
    }
  } catch (error) {
    console.error("error: ", error);
    dispatch(
      setErrorMessage(
        "Ha habido un error actualizando la agenda. Inténtelo de nuevo más tarde"
      )
    );
    dispatch(setRequestAgendarVisita(REQUEST_STATE.ERROR));
  } finally {
    dispatch(setRequestAgendarVisita(REQUEST_STATE.IDLE));
  }
};

export const getUsuariosPaginados = (
  pageSize,
  page,
  tipoCuenta,
  referidoPor,
  soloEmbajadores
) => async (dispatch) => {
  dispatch(setRequestGetUsuariosPaginados(REQUEST_STATE.LOADING));
  try {
    const res = await api.apiGetUsuariosPaginados(
      pageSize,
      page,
      tipoCuenta,
      referidoPor,
      soloEmbajadores
    );
    if (res !== null && res.estado !== null && res.estado === 1) {
      dispatch(setUsuariosPaginados(res.data));
      dispatch(setRequestGetUsuariosPaginados(REQUEST_STATE.SUCCESS));
    } else {
      dispatch(setRequestGetUsuariosPaginados(REQUEST_STATE.ERROR));
      if (res !== null && res.mensaje !== null && res.mensaje !== undefined) {
        dispatch(setErrorMessage(res.mensaje));
      } else {
        dispatch(
          setErrorMessage(
            "Ha habido un error consultando los usuarios. Vuelva a intentarlo más tarde"
          )
        );
      }
    }
  } catch (error) {
    console.error("error: ", error);
    dispatch(
      setErrorMessage(
        "Ha habido un error consultando los usuarios. Vuelva a intentarlo más tarde"
      )
    );
    dispatch(setRequestGetUsuariosPaginados(REQUEST_STATE.ERROR));
  } finally {
    dispatch(setRequestGetUsuariosPaginados(REQUEST_STATE.IDLE));
  }
};

export const getLandingInmobiliariaById = (id) => async (dispatch) => {
  dispatch(setRequestGetLandingInmobiliaria(REQUEST_STATE.LOADING));
  try {
    const res = await api.apiGetLandingInmobiliariaById(id);

    if (res && res.data) {
      if (res.data.estado === 1) {
        dispatch(setLandingInmobiliaria(res.data));
        dispatch(setRequestGetLandingInmobiliaria(REQUEST_STATE.SUCCESS));
      } else {
        dispatch(setErrorMessage(res.data.mensaje));
        dispatch(setRequestGetLandingInmobiliaria(REQUEST_STATE.ERROR));
      }
    } else {
      if (res.status) {
        dispatch(setErrorMessage(STANDARD_ERROR_MESSAGES[res.status]));
        dispatch(setRequestGetLandingInmobiliaria(REQUEST_STATE.ERROR));
      }
    }
  } catch (error) {
    console.error("error: ", error);
    dispatch(setErrorMessage("Ha habido un error"));
    dispatch(setRequestGetLandingInmobiliaria(REQUEST_STATE.ERROR));
  } finally {
    dispatch(setRequestGetLandingInmobiliaria(REQUEST_STATE.IDLE));
  }
};

export const postLandingInmobiliaria = (data) => async (dispatch) => {
  dispatch(setRequestPostLandingInmobiliaria(REQUEST_STATE.LOADING));
  try {
    const res = await api.apiPostLandingInmobiliaria(data);

    if (res && res.data) {
      if (res.data.estado === 1) {
        dispatch(setLandingInmobiliaria(res.data));
        dispatch(setRequestPostLandingInmobiliaria(REQUEST_STATE.SUCCESS));
      } else {
        dispatch(setErrorMessage(res.data.mensaje));
        dispatch(setRequestPostLandingInmobiliaria(REQUEST_STATE.ERROR));
      }
    } else {
      if (res.status) {
        dispatch(setErrorMessage(STANDARD_ERROR_MESSAGES[res.status]));
        dispatch(setRequestPostLandingInmobiliaria(REQUEST_STATE.ERROR));
      }
    }
  } catch (error) {
    console.error("error: ", error);
    dispatch(setErrorMessage("Ha habido un error"));
    dispatch(setRequestPostLandingInmobiliaria(REQUEST_STATE.ERROR));
  } finally {
    dispatch(setRequestPostLandingInmobiliaria(REQUEST_STATE.IDLE));
  }
};

export const getAllLandingInmobiliarias = () => async (dispatch) => {
  dispatch(setRequestGetAllLandingInmobiliarias(REQUEST_STATE.LOADING));
  try {
    const res = await api.apiGetLandingInmobiliarias();

    if (res && res.data) {
      if (res.data.estado === 1) {
        dispatch(setLandingInmobiliarias(res.data.data));
        dispatch(setRequestGetAllLandingInmobiliarias(REQUEST_STATE.SUCCESS));
      } else {
        dispatch(setErrorMessage(res.data.mensaje));
        dispatch(setRequestGetAllLandingInmobiliarias(REQUEST_STATE.ERROR));
      }
    } else {
      if (res.status) {
        dispatch(setErrorMessage(STANDARD_ERROR_MESSAGES[res.status]));
        dispatch(setRequestGetAllLandingInmobiliarias(REQUEST_STATE.ERROR));
      }
    }
  } catch (error) {
    console.error("error: ", error);
    dispatch(setErrorMessage("Ha habido un error"));
    dispatch(setRequestGetAllLandingInmobiliarias(REQUEST_STATE.ERROR));
  } finally {
    dispatch(setRequestGetAllLandingInmobiliarias(REQUEST_STATE.IDLE));
  }
};

export const updateLandingInmobiliaria = (id, data) => async (dispatch) => {
  dispatch(setRequestUpdateLandingInmobiliaria(REQUEST_STATE.LOADING));
  try {
    const res = await api.apiPutLandingInmobiliaria(id, data);

    if (res && res.data) {
      if (res.data.estado === 1) {
        dispatch(setLandingInmobiliaria(res.data));
        dispatch(setRequestUpdateLandingInmobiliaria(REQUEST_STATE.SUCCESS));
      } else {
        dispatch(setErrorMessage(res.data.mensaje));
        dispatch(setRequestUpdateLandingInmobiliaria(REQUEST_STATE.ERROR));
      }
    } else {
      if (res.status) {
        dispatch(setErrorMessage(STANDARD_ERROR_MESSAGES[res.status]));
        dispatch(setRequestUpdateLandingInmobiliaria(REQUEST_STATE.ERROR));
      }
    }
  } catch (error) {
    console.error("error: ", error);
    dispatch(setErrorMessage("Ha habido un error"));
    dispatch(setRequestUpdateLandingInmobiliaria(REQUEST_STATE.ERROR));
  } finally {
    dispatch(setRequestUpdateLandingInmobiliaria(REQUEST_STATE.IDLE));
  }
};

export const postNewsletter = (data) => async (dispatch) => {
  dispatch(setRequestPostNewsletter(REQUEST_STATE.LOADING));
  try {
    const res = await api.apiPostNewsletter(data);

    if (res && res.data) {
      if (res.data.estado === 1) {
        dispatch(setNewsletter(res.data));
        dispatch(setRequestPostNewsletter(REQUEST_STATE.SUCCESS));
      } else {
        dispatch(setErrorMessage(res.data.mensaje));
        dispatch(setRequestPostNewsletter(REQUEST_STATE.ERROR));
      }
    } else {
      if (res.status) {
        dispatch(setErrorMessage(STANDARD_ERROR_MESSAGES[res.status]));
        dispatch(setRequestPostNewsletter(REQUEST_STATE.ERROR));
      }
    }
  } catch (error) {
    console.error("error: ", error);
    dispatch(setErrorMessage("Ha habido un error"));
    dispatch(setRequestPostNewsletter(REQUEST_STATE.ERROR));
  } finally {
    dispatch(setRequestPostNewsletter(REQUEST_STATE.IDLE));
  }
};

export const getAllNewsletters = (anio, mes, comuna, pageSize, page) => async (
  dispatch
) => {
  dispatch(setRequestGetAllNewsletters(REQUEST_STATE.LOADING));
  try {
    const res = await api.apiGetAllNewsletters(
      anio,
      mes,
      comuna,
      pageSize,
      page
    );

    if (res && res.data) {
      if (res.data.estado === 1) {
        dispatch(setNewsletters(res.data.data));
        dispatch(setRequestGetAllNewsletters(REQUEST_STATE.SUCCESS));
      } else {
        dispatch(setErrorMessage(res.data.mensaje));
        dispatch(setRequestGetAllNewsletters(REQUEST_STATE.ERROR));
      }
    } else {
      if (res.status) {
        dispatch(setErrorMessage(STANDARD_ERROR_MESSAGES[res.status]));
        dispatch(setRequestGetAllNewsletters(REQUEST_STATE.ERROR));
      }
    }
  } catch (error) {
    console.error("error: ", error);
    dispatch(setErrorMessage("Ha habido un error"));
    dispatch(setRequestGetAllNewsletters(REQUEST_STATE.ERROR));
  } finally {
    dispatch(setRequestGetAllNewsletters(REQUEST_STATE.IDLE));
  }
};

export const deleteNewsletterById = (id) => async (dispatch) => {
  dispatch(setRequestDeleteNewsletterById(REQUEST_STATE.LOADING));
  try {
    const res = await api.apiDeleteNewsletterById(id);

    if (res && res.data) {
      if (res.data.estado === 1) {
        dispatch(setRequestDeleteNewsletterById(REQUEST_STATE.SUCCESS));
      } else {
        dispatch(setErrorMessage(res.data.mensaje));
        dispatch(setRequestDeleteNewsletterById(REQUEST_STATE.ERROR));
      }
    } else {
      if (res.status) {
        dispatch(setErrorMessage(STANDARD_ERROR_MESSAGES[res.status]));
        dispatch(setRequestDeleteNewsletterById(REQUEST_STATE.ERROR));
      }
    }
  } catch (error) {
    console.error("error: ", error);
    dispatch(setErrorMessage("Ha habido un error"));
    dispatch(setRequestDeleteNewsletterById(REQUEST_STATE.ERROR));
  } finally {
    dispatch(setRequestDeleteNewsletterById(REQUEST_STATE.IDLE));
  }
};

export const sendEmailsNewsletter = (data) => async (dispatch) => {
  dispatch(setRequestSendEmailsNewsletter(REQUEST_STATE.LOADING));
  try {
    const res = await api.apiSendEmailsNewsletter(data);

    if (res && res.data) {
      if (res.data.estado === 1) {
        dispatch(setRequestSendEmailsNewsletter(REQUEST_STATE.SUCCESS));
      } else {
        dispatch(setErrorMessage(res.data.mensaje));
        dispatch(setRequestSendEmailsNewsletter(REQUEST_STATE.ERROR));
      }
    } else {
      if (res.status) {
        dispatch(setErrorMessage(STANDARD_ERROR_MESSAGES[res.status]));
        dispatch(setRequestSendEmailsNewsletter(REQUEST_STATE.ERROR));
      }
    }
  } catch (error) {
    console.error("error: ", error);
    dispatch(setErrorMessage("Ha habido un error"));
    dispatch(setRequestSendEmailsNewsletter(REQUEST_STATE.ERROR));
  } finally {
    dispatch(setRequestSendEmailsNewsletter(REQUEST_STATE.IDLE));
  }
};

export const uploadPosiblesLeads = (pageSize, page) => async (dispatch) => {
  dispatch(setRequestUploadPosiblesLeads(REQUEST_STATE.LOADING));
  try {
    const res = await api.apiUploadPosiblesLeads(pageSize, page);

    if (res && res.data) {
      if (res.data.estado === 1) {
        dispatch(setPosiblesLeads(res.data.data));
        dispatch(setRequestUploadPosiblesLeads(REQUEST_STATE.SUCCESS));
      } else {
        dispatch(setErrorMessage(res.data.mensaje));
        dispatch(setRequestUploadPosiblesLeads(REQUEST_STATE.ERROR));
      }
    } else {
      if (res.status) {
        dispatch(setErrorMessage(STANDARD_ERROR_MESSAGES[res.status]));
        dispatch(setRequestUploadPosiblesLeads(REQUEST_STATE.ERROR));
      }
    }
  } catch (error) {
    console.error("error: ", error);
    dispatch(setErrorMessage("Ha habido un error"));
    dispatch(setRequestUploadPosiblesLeads(REQUEST_STATE.ERROR));
  } finally {
    dispatch(setRequestUploadPosiblesLeads(REQUEST_STATE.IDLE));
  }
};

export const getPosiblesLeads = (
  nombre,
  contacto,
  ultimoContacto,
  idBroker,
  sortBy,
  page,
  pageSize
) => async (dispatch) => {
  dispatch(setRequestGetPosiblesLeads(REQUEST_STATE.LOADING));
  try {
    const res = await api.apiGetPosiblesLeads(
      nombre,
      contacto,
      ultimoContacto,
      idBroker,
      sortBy,
      page,
      pageSize
    );

    if (res && res.data) {
      if (res.data.estado === 1) {
        dispatch(setPosiblesLeads(res.data.data));
        dispatch(setRequestGetPosiblesLeads(REQUEST_STATE.SUCCESS));
      } else {
        dispatch(setErrorMessage(res.data.mensaje));
        dispatch(setRequestGetPosiblesLeads(REQUEST_STATE.ERROR));
      }
    } else {
      if (res.status) {
        dispatch(setErrorMessage(STANDARD_ERROR_MESSAGES[res.status]));
        dispatch(setRequestGetPosiblesLeads(REQUEST_STATE.ERROR));
      }
    }
  } catch (error) {
    console.error("error: ", error);
    dispatch(setErrorMessage("Ha habido un error"));
    dispatch(setRequestGetPosiblesLeads(REQUEST_STATE.ERROR));
  } finally {
    dispatch(setRequestGetPosiblesLeads(REQUEST_STATE.IDLE));
  }
};

export const updatePosibleLead = (id, data) => async (dispatch) => {
  dispatch(setRequestUpdatePosibleLead(REQUEST_STATE.LOADING));
  try {
    const res = await api.apiUpdatePosibleLead(id, data);

    if (res && res.data) {
      if (res.data.estado === 1) {
        dispatch(setRequestUpdatePosibleLead(REQUEST_STATE.SUCCESS));
      } else {
        dispatch(setErrorMessage(res.data.mensaje));
        dispatch(setRequestUpdatePosibleLead(REQUEST_STATE.ERROR));
      }
    } else {
      if (res.status) {
        dispatch(setErrorMessage(STANDARD_ERROR_MESSAGES[res.status]));
        dispatch(setRequestUpdatePosibleLead(REQUEST_STATE.ERROR));
      }
    }
  } catch (error) {
    console.error("error: ", error);
    dispatch(setErrorMessage("Ha habido un error"));
    dispatch(setRequestUpdatePosibleLead(REQUEST_STATE.ERROR));
  } finally {
    dispatch(setRequestUpdatePosibleLead(REQUEST_STATE.IDLE));
  }
};

export const cambiarContactadoPosibleLead = (id) => async (dispatch) => {
  dispatch(setRequestCambiarContactadoPosibleLead(REQUEST_STATE.LOADING));
  try {
    const res = await api.apiCambiarContactadoPosibleLead(id);

    if (res && res.data) {
      if (res.data.estado === 1) {
        dispatch(setRequestCambiarContactadoPosibleLead(REQUEST_STATE.SUCCESS));
      } else {
        dispatch(setErrorMessage(res.data.mensaje));
        dispatch(setRequestCambiarContactadoPosibleLead(REQUEST_STATE.ERROR));
      }
    } else {
      if (res.status) {
        dispatch(setErrorMessage(STANDARD_ERROR_MESSAGES[res.status]));
        dispatch(setRequestCambiarContactadoPosibleLead(REQUEST_STATE.ERROR));
      }
    }
  } catch (error) {
    console.error("error: ", error);
    dispatch(setErrorMessage("Ha habido un error"));
    dispatch(setRequestCambiarContactadoPosibleLead(REQUEST_STATE.ERROR));
  } finally {
    dispatch(setRequestCambiarContactadoPosibleLead(REQUEST_STATE.IDLE));
  }
};

export const fetchRecuperaPassword = (data) => async (dispatch) => {
  dispatch(setRequestRecoverPassword(REQUEST_STATE.LOADING));
  dispatch(setReestablecerPassword(null));
  try {
    const res = await api.apiPostRecuperar(data);
    dispatch(setRecoverPassword(res));
    dispatch(setRequestRecoverPassword(REQUEST_STATE.SUCCESS));
  } catch (error) {
    console.error("error: ", error);
    dispatch(setErrorMessage("Ha habido un error"));
    dispatch(setRequestCambiarContactadoPosibleLead(REQUEST_STATE.ERROR));
  } finally {
    dispatch(setRequestRecoverPassword(REQUEST_STATE.IDLE));
  }
};

export const fetchValidaToken = (id) => async (dispatch) => {
  try {
    const res = await api.apiValidaToken(id);
    dispatch(setValidateToken(res.data));
    dispatch(setRecoverPassword(null));
    /*
    dispatch({
      type: VALIDATE_TOKEN,
      tokenPassword: res.data,
      recover: {},
    });
    */
  } catch (error) {
    console.error("error: ", error);
  }
};

export const restablecerPassword = (values) => async (dispatch) => {
  dispatch(setRequestCambioContraseña(REQUEST_STATE.LOADING));
  try {
    const res = await api.apiPostRestablecer(values);
    dispatch(setReestablecerPassword(res.value));
    dispatch(setValidateToken(null));
    dispatch(setRequestCambioContraseña(REQUEST_STATE.SUCCESS));

    /*
    dispatch({
      type: RESTABLECER_PASSWORD,
      restablecer: res.value,
      tokenPassword: {},
    });
    */
  } catch (error) {
    console.error("error: ", error);
    dispatch(setRequestCambioContraseña(REQUEST_STATE.ERROR));
    dispatch(setErrorMessage("Ha habido un error"));
  } finally {
    dispatch(setRequestCambioContraseña(REQUEST_STATE.IDLE));
  }
};

export const deleteUser = (id) => async (dispatch) => {
  dispatch(setRequestDeleteUser(REQUEST_STATE.LOADING));
  try {
    const res = await api.apiDeleteUser(id);

    if (res && res.data) {
      if (res.data.estado === 1) {
        dispatch(setRequestDeleteUser(REQUEST_STATE.SUCCESS));
      } else {
        dispatch(setErrorMessage(res.data.mensaje));
        dispatch(setRequestDeleteUser(REQUEST_STATE.ERROR));
      }
    } else {
      if (res.status) {
        dispatch(setErrorMessage(STANDARD_ERROR_MESSAGES[res.status]));
        dispatch(setRequestDeleteUser(REQUEST_STATE.ERROR));
      }
    }
  } catch (error) {
    console.error("error: ", error);
    dispatch(setErrorMessage("Ha habido un error"));
    dispatch(setRequestDeleteUser(REQUEST_STATE.ERROR));
  } finally {
    dispatch(setRequestDeleteUser(REQUEST_STATE.IDLE));
  }
};

export const publicarEnYapo = (id) => async (dispatch) => {
  dispatch(setRequestPostYapo(REQUEST_STATE.LOADING));
  try {
    const res = await api.apiPublicarEnYapo(id);

    if (res && res.data) {
      if (res.data.estado === 1) {
        dispatch(setRequestPostYapo(REQUEST_STATE.SUCCESS));
      } else {
        dispatch(setErrorMessage(res.data.mensaje));
        dispatch(setRequestPostYapo(REQUEST_STATE.ERROR));
      }
    } else {
      if (res.status) {
        dispatch(setErrorMessage(STANDARD_ERROR_MESSAGES[res.status]));
        dispatch(setRequestPostYapo(REQUEST_STATE.ERROR));
      }
    }
  } catch (error) {
    console.error("error: ", error);
    dispatch(setErrorMessage("Ha habido un error"));
    dispatch(setRequestPostYapo(REQUEST_STATE.ERROR));
  } finally {
    setTimeout(() => {
      dispatch(setRequestPostYapo(REQUEST_STATE.IDLE));
    }, 500);    
  }
};

export const actualizarEnYapo = (id) => async (dispatch) => {
  dispatch(setRequestPutYapo(REQUEST_STATE.LOADING));
  try {
    const res = await api.apiActualizarEnYapo(id);

    if (res && res.data) {
      if (res.data.estado === 1) {
        dispatch(setRequestPutYapo(REQUEST_STATE.SUCCESS));
      } else {
        dispatch(setErrorMessage(res.data.mensaje));
        dispatch(setRequestPutYapo(REQUEST_STATE.ERROR));
      }
    } else {
      if (res.status) {
        dispatch(setErrorMessage(STANDARD_ERROR_MESSAGES[res.status]));
        dispatch(setRequestPutYapo(REQUEST_STATE.ERROR));
      }
    }
  } catch (error) {
    console.error("error: ", error);
    dispatch(setErrorMessage("Ha habido un error"));
    dispatch(setRequestPutYapo(REQUEST_STATE.ERROR));
  } finally {
    setTimeout(() => {
      dispatch(setRequestPutYapo(REQUEST_STATE.IDLE));
    }, 500);    
  }
};

export const eliminarEnYapo = (id) => async (dispatch) => {
  dispatch(setRequestDeleteYapo(REQUEST_STATE.LOADING));
  try {
    const res = await api.apiEliminarEnYapo(id);

    if (res && res.data) {
      if (res.data.estado === 1) {
        dispatch(setRequestDeleteYapo(REQUEST_STATE.SUCCESS));
      } else {
        dispatch(setErrorMessage(res.data.mensaje));
        dispatch(setRequestDeleteYapo(REQUEST_STATE.ERROR));
      }
    } else {
      if (res.status) {
        dispatch(setErrorMessage(STANDARD_ERROR_MESSAGES[res.status]));
        dispatch(setRequestDeleteYapo(REQUEST_STATE.ERROR));
      }
    }
  } catch (error) {
    console.error("error: ", error);
    dispatch(setErrorMessage("Ha habido un error"));
    dispatch(setRequestDeleteYapo(REQUEST_STATE.ERROR));
  } finally {
    setTimeout(() => {
      dispatch(setRequestDeleteYapo(REQUEST_STATE.IDLE));
    }, 500);    
  }
};
export const publicarEnPI = (id) => async (dispatch) => {
  dispatch(setRequestPostPI(REQUEST_STATE.LOADING));
  try {
    const res = await api.apiPublicarEnPortalInmobiliario(id);
    if (res && res.data) {
      if (res.data.estado === 1) {
        dispatch(setRequestPostPI(REQUEST_STATE.SUCCESS));
      } else {
        dispatch(setErrorMessage(res.data.mensaje));
        dispatch(setRequestPostPI(REQUEST_STATE.ERROR));
      }
    } else {
      if (res.status) {
        dispatch(setErrorMessage(STANDARD_ERROR_MESSAGES[res.status]));
        dispatch(setRequestPostPI(REQUEST_STATE.ERROR));
      }
    }
  } catch (error) {
    console.error("error: ", error);
    dispatch(setErrorMessage("Ha habido un error"));
    dispatch(setRequestPostPI(REQUEST_STATE.ERROR));
  } finally {
    setTimeout(() => {
      dispatch(setRequestPostPI(REQUEST_STATE.IDLE));
    }, 500);    
  }
};

export const actualizarEnPI = (id) => async (dispatch) => {
  dispatch(setRequestPutPI(REQUEST_STATE.LOADING));
  try {
    const res = await api.apiActualizarEnPortalInmobiliario(id);
    if (res && res.data) {
      if (res.data.estado === 1) {
        dispatch(setRequestPutPI(REQUEST_STATE.SUCCESS));
      } else {
        dispatch(setErrorMessage(res.data.mensaje));
        dispatch(setRequestPutPI(REQUEST_STATE.ERROR));
      }
    } else {
      if (res.status) {
        dispatch(setErrorMessage(STANDARD_ERROR_MESSAGES[res.status]));
        dispatch(setRequestPutPI(REQUEST_STATE.ERROR));
      }
    }
  } catch (error) {
    console.error("error: ", error);
    dispatch(setErrorMessage("Ha habido un error"));
    dispatch(setRequestPutPI(REQUEST_STATE.ERROR));
  } finally {
    setTimeout(() => {
      dispatch(setRequestPutPI(REQUEST_STATE.IDLE));
    }, 500);
  }
};

export const eliminarEnPI = (id) => async (dispatch) => {
  dispatch(setRequestDeletePI(REQUEST_STATE.LOADING));
  try {
    const res = await api.apiEliminarEnPortalInmobiliario(id);
    if (res && res.data) {
      if (res.data.estado === 1) {
        dispatch(setRequestDeletePI(REQUEST_STATE.SUCCESS));
      } else {
        dispatch(setErrorMessage(res.data.mensaje));
        dispatch(setRequestDeletePI(REQUEST_STATE.ERROR));
      }
    } else {
      if (res.status) {
        dispatch(setErrorMessage(STANDARD_ERROR_MESSAGES[res.status]));
        dispatch(setRequestDeletePI(REQUEST_STATE.ERROR));
      }
    }
  } catch (error) {
    console.error("error: ", error);
    dispatch(setErrorMessage("Ha habido un error"));
    dispatch(setRequestDeletePI(REQUEST_STATE.ERROR));
  } finally {
    setTimeout(() => {
      dispatch(setRequestDeletePI(REQUEST_STATE.IDLE));
    }, 500);
  }
};

export const crearPortafolio = (data) => async (dispatch) => {
  dispatch(setRequestPostPortfolio(REQUEST_STATE.LOADING));
  try {
    const res = await api.apiCrearPortafolio(data);
    if (res && res.data) {
      if (res.data.estado === 1) {
        dispatch(setRequestPostPortfolio(REQUEST_STATE.SUCCESS));
      } else {
        dispatch(setErrorMessage(res.data.mensaje));
        dispatch(setRequestPostPortfolio(REQUEST_STATE.ERROR));
      }
    } else {
      if (res.status) {
        dispatch(setErrorMessage(STANDARD_ERROR_MESSAGES[res.status]));
        dispatch(setRequestPostPortfolio(REQUEST_STATE.ERROR));
      }
    }
  } catch (error) {
    console.error("error: ", error);
    dispatch(setErrorMessage("Ha habido un error"));
    dispatch(setRequestPostPortfolio(REQUEST_STATE.ERROR));
  } finally {
    setTimeout(() => {
      dispatch(setRequestPostPortfolio(REQUEST_STATE.IDLE));
    }, 500);    
  }
};

export const getPortfolios = (page, pageSize) => async (dispatch) => {
  dispatch(setRequestGetPortfolios(REQUEST_STATE.LOADING));
  try {
    const res = await api.apiGetPortfolios(page, pageSize);
    if (res && res.data) {
      if (res.data.estado === 1) {
        dispatch(setPortfolios(res.data.data));
        dispatch(setRequestGetPortfolios(REQUEST_STATE.SUCCESS));
      } else {
        dispatch(setErrorMessage(res.data.mensaje));
        dispatch(setRequestGetPortfolios(REQUEST_STATE.ERROR));
      }
    } else {
      if (res.status) {
        dispatch(setErrorMessage(STANDARD_ERROR_MESSAGES[res.status]));
        dispatch(setRequestGetPortfolios(REQUEST_STATE.ERROR));
      }
    }
  } catch (error) {
    console.error("error: ", error);
    dispatch(setErrorMessage("Ha habido un error"));
    dispatch(setRequestGetPortfolios(REQUEST_STATE.ERROR));
  } finally {
    setTimeout(() => {
      dispatch(setRequestGetPortfolios(REQUEST_STATE.IDLE));
    }, 500);
  }
};

export const putSignerPortfolio = (data) => async (dispatch) => {
  dispatch(setRequestPutSigner(REQUEST_STATE.LOADING));
  try {
    const res = await api.apiPortfolioUpdateSigner(data);
    if (res && res.data) {
      if (res.data.estado === 1) {
        dispatch(setRequestPutSigner(REQUEST_STATE.SUCCESS));
      } else {
        dispatch(setErrorMessage(res.data.mensaje));
        dispatch(setRequestPutSigner(REQUEST_STATE.ERROR));
      }
    } else {
      if (res.status) {
        dispatch(setErrorMessage(STANDARD_ERROR_MESSAGES[res.status]));
        dispatch(setRequestPutSigner(REQUEST_STATE.ERROR));
      }
    }
  } catch (error) {
    console.error("error: ", error);
    dispatch(setErrorMessage("Ha habido un error"));
    dispatch(setRequestPutSigner(REQUEST_STATE.ERROR));
  } finally {
    dispatch(setRequestPutSigner(REQUEST_STATE.IDLE));
  }
};

export const putDocumentsPortfolio = (data) => async (dispatch) => {
  dispatch(setRequestPutDocumentsPortfolio(REQUEST_STATE.LOADING));
  try {
    const res = await api.apiPortfolioUpdateDocuments(data);
    if (res && res.data) {
      if (res.data.estado === 1) {
        dispatch(setRequestPutDocumentsPortfolio(REQUEST_STATE.SUCCESS));
      } else {
        dispatch(setErrorMessage(res.data.mensaje));
        dispatch(setRequestPutDocumentsPortfolio(REQUEST_STATE.ERROR));
      }
    } else {
      if (res.status) {
        dispatch(setErrorMessage(STANDARD_ERROR_MESSAGES[res.status]));
        dispatch(setRequestPutDocumentsPortfolio(REQUEST_STATE.ERROR));
      }
    }
  } catch (error) {
    console.error("error: ", error);
    dispatch(setErrorMessage("Ha habido un error"));
    dispatch(setRequestPutDocumentsPortfolio(REQUEST_STATE.ERROR));
  } finally {
    dispatch(setRequestPutDocumentsPortfolio(REQUEST_STATE.IDLE));
  }
};

export const putSignerDocumentsPortfolio = (data) => async (dispatch) => {
  dispatch(setRequestPutSignerDocumentsPortfolio(REQUEST_STATE.LOADING));
  try {
    const res = await api.apiPortfolioUpdateSignerDocuments(data);
    if (res && res.data) {
      if (res.data.estado === 1) {
        dispatch(setRequestPutSignerDocumentsPortfolio(REQUEST_STATE.SUCCESS));
      } else {
        dispatch(setErrorMessage(res.data.mensaje));
        dispatch(setRequestPutSignerDocumentsPortfolio(REQUEST_STATE.ERROR));
      }
    } else {
      if (res.status) {
        dispatch(setErrorMessage(STANDARD_ERROR_MESSAGES[res.status]));
        dispatch(setRequestPutSignerDocumentsPortfolio(REQUEST_STATE.ERROR));
      }
    }
  } catch (error) {
    console.error("error: ", error);
    dispatch(setErrorMessage("Ha habido un error"));
    dispatch(setRequestPutSignerDocumentsPortfolio(REQUEST_STATE.ERROR));
  } finally {
    dispatch(setRequestPutSignerDocumentsPortfolio(REQUEST_STATE.IDLE));
  }
};

export const postRetryPortfolio = (idPortfolio) => async (dispatch) => {
  dispatch(setRequestPortfolioRetry(REQUEST_STATE.LOADING));
  try {
    const res = await api.apiPortfolioRetry(idPortfolio);
    if (res && res.data) {
      if (res.data.estado === 1) {
        dispatch(setRequestPortfolioRetry(REQUEST_STATE.SUCCESS));
      } else {
        dispatch(setErrorMessage(res.data.mensaje));
        dispatch(setRequestPortfolioRetry(REQUEST_STATE.ERROR));
      }
    } else {
      if (res.status) {
        dispatch(setErrorMessage(STANDARD_ERROR_MESSAGES[res.status]));
        dispatch(setRequestPortfolioRetry(REQUEST_STATE.ERROR));
      }
    }
  } catch (error) {
    console.error("error: ", error);
    dispatch(setErrorMessage("Ha habido un error"));
    dispatch(setRequestPortfolioRetry(REQUEST_STATE.ERROR));
  } finally {
    setTimeout(() => {
      dispatch(setRequestPortfolioRetry(REQUEST_STATE.IDLE));
    }, 500);    
  }
};

export const getUsuarioById = (id) => async (dispatch) => {
  dispatch(setRequestUserById(REQUEST_STATE.LOADING));
  try {
    const res = await api.apiGetUsuarioById(id);

    if (res && res.data) {
      if (res.estado === 1) {
        dispatch(setUserById(res.data));
        dispatch(setRequestUserById(REQUEST_STATE.SUCCESS));
      } else {
        dispatch(setUserById(res.mensaje));
        dispatch(setRequestUserById(REQUEST_STATE.ERROR));
      }
    } else {
      if (res.status) {
        dispatch(setErrorMessage(STANDARD_ERROR_MESSAGES[res.status]));
        dispatch(setRequestUserById(REQUEST_STATE.ERROR));
      }
    }
  } catch (error) {
    console.error("error: ", error);
    dispatch(setErrorMessage("Ha habido un error"));
    dispatch(setRequestUserById(REQUEST_STATE.ERROR));
  } finally {
    dispatch(setRequestUserById(REQUEST_STATE.IDLE));
  }
};

export const getCliente = (id) => async (dispatch) => {
  dispatch(setRequestGetCliente(REQUEST_STATE.LOADING));
  try {
    const res = await api.apiGetCliente(id);

    if (res && res.data) {
      if (res.estado === 1) {
        dispatch(setCliente(res.data));
        dispatch(setRequestGetCliente(REQUEST_STATE.SUCCESS));
      } else {
        dispatch(setErrorMessage(res.mensaje));
        dispatch(setRequestGetCliente(REQUEST_STATE.ERROR));
      }
    } else {
      if (res.status) {
        dispatch(setErrorMessage(STANDARD_ERROR_MESSAGES[res.status]));
        dispatch(setRequestGetCliente(REQUEST_STATE.ERROR));
      }
    }
  } catch (error) {
    console.error("error: ", error);
    dispatch(setErrorMessage("Ha habido un error"));
    dispatch(setRequestGetCliente(REQUEST_STATE.ERROR));
  } finally {
    dispatch(setRequestGetCliente(REQUEST_STATE.IDLE));
  }
};

export const getActividad = (id) => async (dispatch) => {
  dispatch(setRequestGetActividad(REQUEST_STATE.LOADING));
  try {
    const res = await api.apiGetActividad(id);
    if (res && res.data) {
      if (res.estado === 1) {
        dispatch(setActividad(res.data));
        dispatch(setRequestGetActividad(REQUEST_STATE.SUCCESS));
      } else {
        dispatch(setErrorMessage(res.mensaje));
        dispatch(setRequestGetActividad(REQUEST_STATE.ERROR));
      }
    } else {
      if (res.status) {
        dispatch(setErrorMessage(STANDARD_ERROR_MESSAGES[res.status]));
        dispatch(setRequestGetActividad(REQUEST_STATE.ERROR));
      }
    }
  } catch (error) {
    console.error("error: ", error);
    dispatch(setErrorMessage("Ha habido un error"));
    dispatch(setRequestGetActividad(REQUEST_STATE.ERROR));
  } finally {
    dispatch(setRequestGetActividad(REQUEST_STATE.IDLE));
  }
};

export const getActividades = (page, pageSize, idPropiedad, idCliente, idProspecto) => async (dispatch) => {
  dispatch(setRequestGetActividades(REQUEST_STATE.LOADING));
  try {
    const res = await api.apiGetActividadesPaginadas(page, pageSize, idPropiedad, idCliente, idProspecto);
    if (res && res.data) {
      if (res.data.estado === 1) {
        dispatch(setActividades(res.data.data));
        dispatch(setRequestGetActividades(REQUEST_STATE.SUCCESS));
      } else {
        dispatch(setErrorMessage(res.data.mensaje));
        dispatch(setRequestGetActividades(REQUEST_STATE.ERROR));
      }
    } else {
      if (res.status) {
        dispatch(setErrorMessage(STANDARD_ERROR_MESSAGES[res.status]));
        dispatch(setRequestGetActividades(REQUEST_STATE.ERROR));
      }
    }
  } catch (error) {
    console.error("error: ", error);
    dispatch(setErrorMessage("Ha habido un error"));
    dispatch(setRequestGetActividades(REQUEST_STATE.ERROR));
  } finally {
    dispatch(setRequestGetActividades(REQUEST_STATE.IDLE));
  }
};

export const postActividad = (data) => async (dispatch) => {
  dispatch(setRequestPostActividad(REQUEST_STATE.LOADING));
  try {
    const res = await api.apiPostActividad(data);

    if (res && res.data) {
      if (res.estado === 1) {
        dispatch(setActividad(res.data));
        dispatch(setRequestPostActividad(REQUEST_STATE.SUCCESS));
      } else {
        dispatch(setErrorMessage(res.mensaje));
        dispatch(setRequestPostActividad(REQUEST_STATE.ERROR));
      }
    } else {
      if (res.status) {
        dispatch(setErrorMessage(STANDARD_ERROR_MESSAGES[res.status]));
        dispatch(setRequestPostActividad(REQUEST_STATE.ERROR));
      }
    }
  } catch (error) {
    console.error("error: ", error);
    dispatch(setErrorMessage("Ha habido un error"));
    dispatch(setRequestPostActividad(REQUEST_STATE.ERROR));
  } finally {
    setTimeout(() => {
      dispatch(setRequestPostActividad(REQUEST_STATE.IDLE));
    }, 500);
  }
};

export const putActividad = (id, data) => async (dispatch) => {
  dispatch(setRequestPutActividad(REQUEST_STATE.LOADING));
  try {
    const res = await api.apiPutActividad(id, data);

    if (res && res.data) {
      if (res.estado === 1) {
        dispatch(setActividad(res.data));
        dispatch(setRequestPutActividad(REQUEST_STATE.SUCCESS));
      } else {
        dispatch(setErrorMessage(res.mensaje));
        dispatch(setRequestPutActividad(REQUEST_STATE.ERROR));
      }
    } else {
      if (res.status) {
        dispatch(setErrorMessage(STANDARD_ERROR_MESSAGES[res.status]));
        dispatch(setRequestPutActividad(REQUEST_STATE.ERROR));
      }
    }
  } catch (error) {
    console.error("error: ", error);
    dispatch(setErrorMessage("Ha habido un error"));
    dispatch(setRequestPutActividad(REQUEST_STATE.ERROR));
  } finally {
    setTimeout(() => {
      dispatch(setRequestPutActividad(REQUEST_STATE.IDLE));
    }, 500);
  }
};

export const getClientesPaginados = (page, pageSize, filtrarSoloPropietarios, busqueda) => async (dispatch) => {
  dispatch(setRequestGetClientes(REQUEST_STATE.LOADING));
  try {
    const res = await api.apiGetClientes(page, pageSize, filtrarSoloPropietarios, busqueda);
    if (res && res.data) {
      if (res.data.estado === 1) {
        dispatch(setClientes(res.data.data));
        dispatch(setRequestGetClientes(REQUEST_STATE.SUCCESS));
      } else {
        dispatch(setErrorMessage(res.data.mensaje));
        dispatch(setRequestGetClientes(REQUEST_STATE.ERROR));
      }
    } else {
      if (res.status) {
        dispatch(setErrorMessage(STANDARD_ERROR_MESSAGES[res.status]));
        dispatch(setRequestGetClientes(REQUEST_STATE.ERROR));
      }
    }
  } catch (error) {
    console.error("error: ", error);
    dispatch(setErrorMessage("Ha habido un error"));
    dispatch(setRequestGetClientes(REQUEST_STATE.ERROR));
  } finally {
    dispatch(setRequestGetClientes(REQUEST_STATE.IDLE));
  }
};

export const getUsuarioByEmail = (email) => async (dispatch) => {
  dispatch(setRequestGetUsuarioByEmail(REQUEST_STATE.LOADING));
  try {
    const res = await api.apiGetUsuarioByEmail(email);
    if (res && res.data) {
      if (res.data.estado === 1) {
        dispatch(setUsuarioByFilter(res.data.data));
        dispatch(setRequestGetUsuarioByEmail(REQUEST_STATE.SUCCESS));
      } else {
        dispatch(setErrorMessage(res.data.mensaje));
        dispatch(setRequestGetUsuarioByEmail(REQUEST_STATE.ERROR));
      }
    } else {
      if (res.status) {
        dispatch(setErrorMessage(STANDARD_ERROR_MESSAGES[res.status]));
        dispatch(setRequestGetUsuarioByEmail(REQUEST_STATE.ERROR));
      }
    }
  } catch (error) {
    console.error("error: ", error);
    dispatch(setErrorMessage("Ha habido un error"));
    dispatch(setRequestGetUsuarioByEmail(REQUEST_STATE.ERROR));
  } finally {
    setTimeout(() => {
      dispatch(setRequestGetUsuarioByEmail(REQUEST_STATE.IDLE));
    }, 500);
  }
};

export const postProspecto = (tipoProspecto, data) => async (dispatch) => {
  if (tipoProspecto === "VA" || tipoProspecto === "va") {
    dispatch(setRequestPostProspecto(REQUEST_STATE.LOADING));
    try {
      const res = await api.apiPostProspecto(tipoProspecto, data);
      if (res && res.data) {
        if (res.data.estado === 1) {
          dispatch(setProspecto(res.data.data));
          dispatch(setRequestPostProspecto(REQUEST_STATE.SUCCESS));
        } else {
          dispatch(setErrorMessage(res.data.mensaje));
          dispatch(setRequestPostProspecto(REQUEST_STATE.ERROR));
        }
      } else {
        if (res.status) {
          dispatch(setErrorMessage(STANDARD_ERROR_MESSAGES[res.status]));
          dispatch(setRequestPostProspecto(REQUEST_STATE.ERROR));
        }
      }
    } catch (error) {
      console.error("error: ", error);
      dispatch(setErrorMessage("Ha habido un error"));
      dispatch(setRequestPostProspecto(REQUEST_STATE.ERROR));
    } finally {
      setTimeout(() => {
        dispatch(setRequestPostProspecto(REQUEST_STATE.IDLE));
      }, 500);
    }
  } else {
    dispatch(setRequestPostProspecto(REQUEST_STATE.LOADING));
    try {
      const res = await api.apiPostProspecto(tipoProspecto, data);
      if (res && res.data) {
        if (res.data.estado === 1) {
          dispatch(setProspecto(res.data.data));
          dispatch(setRequestPostProspecto(REQUEST_STATE.SUCCESS));
        } else {
          dispatch(setErrorMessage(res.data.mensaje));
          dispatch(setRequestPostProspecto(REQUEST_STATE.ERROR));
        }
      } else {
        if (res.status) {
          dispatch(setErrorMessage(STANDARD_ERROR_MESSAGES[res.status]));
          dispatch(setRequestPostProspecto(REQUEST_STATE.ERROR));
        }
      }
    } catch (error) {
      console.error("error: ", error);
      dispatch(setErrorMessage("Ha habido un error"));
      dispatch(setRequestPostProspecto(REQUEST_STATE.ERROR));
    } finally {
      setTimeout(() => {
        dispatch(setRequestPostProspecto(REQUEST_STATE.IDLE));
      }, 500);
    }
  }
};

export const getProspectos = (tipoProspecto, page, pageSize, tipoOperacion, tipoPropiedad, comuna, dormitorios, banios) => async (dispatch) => {
  if (tipoProspecto === "VA" || tipoProspecto === "va") {
    dispatch(setRequestGetProspectosVA(REQUEST_STATE.LOADING));
    try {
      const res = await api.apiGetProspectos(tipoProspecto, page, pageSize, tipoOperacion, tipoPropiedad, comuna, dormitorios, banios);
      if (res && res.data) {
        if (res.data.estado === 1) {
          dispatch(setProspectosVA(res.data.data));
          dispatch(setRequestGetProspectosVA(REQUEST_STATE.SUCCESS));
        } else {
          dispatch(setErrorMessage(res.data.mensaje));
          dispatch(setRequestGetProspectosVA(REQUEST_STATE.ERROR));
        }
      } else {
        if (res.status) {
          dispatch(setErrorMessage(STANDARD_ERROR_MESSAGES[res.status]));
          dispatch(setRequestGetProspectosVA(REQUEST_STATE.ERROR));
        }
      }
    } catch (error) {
      console.error("error: ", error);
      dispatch(setErrorMessage("Ha habido un error"));
      dispatch(setRequestGetProspectosVA(REQUEST_STATE.ERROR));
    } finally {
      setTimeout(() => {
        dispatch(setRequestGetProspectosVA(REQUEST_STATE.IDLE));
      }, 500);
    }
  } else {
    dispatch(setRequestGetProspectosCA(REQUEST_STATE.LOADING));
    try {
      const res = await api.apiGetProspectos(tipoProspecto, page, pageSize, tipoOperacion, tipoPropiedad, comuna, dormitorios, banios);
      if (res && res.data) {
        if (res.data.estado === 1) {
          dispatch(setProspectosCA(res.data.data));
          dispatch(setRequestGetProspectosCA(REQUEST_STATE.SUCCESS));
        } else {
          dispatch(setErrorMessage(res.data.mensaje));
          dispatch(setRequestGetProspectosCA(REQUEST_STATE.ERROR));
        }
      } else {
        if (res.status) {
          dispatch(setErrorMessage(STANDARD_ERROR_MESSAGES[res.status]));
          dispatch(setRequestGetProspectosCA(REQUEST_STATE.ERROR));
        }
      }
    } catch (error) {
      console.error("error: ", error);
      dispatch(setErrorMessage("Ha habido un error"));
      dispatch(setRequestGetProspectosCA(REQUEST_STATE.ERROR));
    } finally {
      setTimeout(() => {
        dispatch(setRequestGetProspectosCA(REQUEST_STATE.IDLE));
      }, 500);
    }
  }
};

export const getProspecto = (tipoProspecto, id) => async (dispatch) => {
  dispatch(setRequestGetProspecto(REQUEST_STATE.LOADING));
  try {
    const res = await api.apiGetProspecto(tipoProspecto, id);
    if (res && res.data) {
      if (res.data.estado === 1) {
        dispatch(setProspecto(res.data.data));
        dispatch(setRequestGetProspecto(REQUEST_STATE.SUCCESS));
      } else {
        dispatch(setErrorMessage(res.data.mensaje));
        dispatch(setRequestGetProspecto(REQUEST_STATE.ERROR));
      }
    } else {
      if (res.status) {
        dispatch(setErrorMessage(STANDARD_ERROR_MESSAGES[res.status]));
        dispatch(setRequestGetProspecto(REQUEST_STATE.ERROR));
      }
    }
  } catch (error) {
    console.error("error: ", error);
    dispatch(setErrorMessage("Ha habido un error"));
    dispatch(setRequestGetProspecto(REQUEST_STATE.ERROR));
  } finally {
    setTimeout(() => {
      dispatch(setRequestGetProspecto(REQUEST_STATE.IDLE));
    }, 500);
  }
};

export const getRequerimientos = (page, pageSize, idProspecto, tipoOperacion, tipoPropiedad, comuna, dormitorios, banios) => async (dispatch) => {
  dispatch(setRequestGetRequerimientos(REQUEST_STATE.LOADING));
  try {
    const res = await api.apiGetRequerimientos(page, pageSize, idProspecto, tipoOperacion, tipoPropiedad, comuna, dormitorios, banios);
    if (res && res.data) {
      if (res.data.estado === 1) {
        dispatch(setRequerimientos(res.data.data));
        dispatch(setRequestGetRequerimientos(REQUEST_STATE.SUCCESS));
      } else {
        dispatch(setErrorMessage(res.data.mensaje));
        dispatch(setRequestGetRequerimientos(REQUEST_STATE.ERROR));
      }
    } else {
      if (res.status) {
        dispatch(setErrorMessage(STANDARD_ERROR_MESSAGES[res.status]));
        dispatch(setRequestGetRequerimientos(REQUEST_STATE.ERROR));
      }
    }
  } catch (error) {
    console.error("error: ", error);
    dispatch(setErrorMessage("Ha habido un error"));
    dispatch(setRequestGetRequerimientos(REQUEST_STATE.ERROR));
  } finally {
    setTimeout(() => {
      dispatch(setRequestGetRequerimientos(REQUEST_STATE.IDLE));
    }, 500);
  }
};

export const postRequerimiento = (data) => async (dispatch) => {
  dispatch(setRequestPostRequerimiento(REQUEST_STATE.LOADING));
  try {
    const res = await api.apiPostRequerimiento(data);
    if (res && res.data) {
      if (res.data.estado === 1) {
        dispatch(setRequerimiento(res.data.data));
        dispatch(setRequestPostRequerimiento(REQUEST_STATE.SUCCESS));
      } else {
        dispatch(setErrorMessage(res.data.mensaje));
        dispatch(setRequestPostRequerimiento(REQUEST_STATE.ERROR));
      }
    } else {
      if (res.status) {
        dispatch(setErrorMessage(STANDARD_ERROR_MESSAGES[res.status]));
        dispatch(setRequestPostRequerimiento(REQUEST_STATE.ERROR));
      }
    }
  } catch (error) {
    console.error("error: ", error);
    dispatch(setErrorMessage("Ha habido un error"));
    dispatch(setRequestPostRequerimiento(REQUEST_STATE.ERROR));
  } finally {
    setTimeout(() => {
      dispatch(setRequestPostRequerimiento(REQUEST_STATE.IDLE));
    }, 500);
  }
};

export const putProspectoCA = (id, data) => async (dispatch) => {
  dispatch(setRequestUpdateProspecto(REQUEST_STATE.LOADING));
  try {
    const res = await api.apiUpdateProspectoCA(id, data);
    if (res && res.data) {
      if (res.data.estado === 1) {
        dispatch(setProspecto(res.data.data));
        dispatch(setRequestUpdateProspecto(REQUEST_STATE.SUCCESS));
      } else {
        dispatch(setErrorMessage(res.data.mensaje));
        dispatch(setRequestUpdateProspecto(REQUEST_STATE.ERROR));
      }
    } else {
      if (res.status) {
        dispatch(setErrorMessage(STANDARD_ERROR_MESSAGES[res.status]));
        dispatch(setRequestUpdateProspecto(REQUEST_STATE.ERROR));
      }
    }
  } catch (error) {
    console.error("error: ", error);
    dispatch(setErrorMessage("Ha habido un error"));
    dispatch(setRequestUpdateProspecto(REQUEST_STATE.ERROR));
  } finally {
    setTimeout(() => {
      dispatch(setRequestUpdateProspecto(REQUEST_STATE.IDLE));
    }, 500);
  }
};

export const putProspectoVA = (id, data) => async (dispatch) => {
  dispatch(setRequestUpdateProspecto(REQUEST_STATE.LOADING));
  try {
    const res = await api.apiUpdateProspectoVA(id, data);
    if (res && res.data) {
      if (res.data.estado === 1) {
        dispatch(setProspecto(res.data.data));
        dispatch(setRequestUpdateProspecto(REQUEST_STATE.SUCCESS));
      } else {
        dispatch(setErrorMessage(res.data.mensaje));
        dispatch(setRequestUpdateProspecto(REQUEST_STATE.ERROR));
      }
    } else {
      if (res.status) {
        dispatch(setErrorMessage(STANDARD_ERROR_MESSAGES[res.status]));
        dispatch(setRequestUpdateProspecto(REQUEST_STATE.ERROR));
      }
    }
  } catch (error) {
    console.error("error: ", error);
    dispatch(setErrorMessage("Ha habido un error"));
    dispatch(setRequestUpdateProspecto(REQUEST_STATE.ERROR));
  } finally {
    setTimeout(() => {
      dispatch(setRequestUpdateProspecto(REQUEST_STATE.IDLE));
    }, 500);
  }
};

export const putRequerimiento = (id, data) => async (dispatch) => {
  dispatch(setRequestPutRequerimiento(REQUEST_STATE.LOADING));
  try {
    const res = await api.apiUpdateRequerimiento(id, data);
    if (res && res.data) {
      if (res.data.estado === 1) {
        dispatch(setRequerimiento(res.data.data));
        dispatch(setRequestPutRequerimiento(REQUEST_STATE.SUCCESS));
      } else {
        dispatch(setErrorMessage(res.data.mensaje));
        dispatch(setRequestPutRequerimiento(REQUEST_STATE.ERROR));
      }
    } else {
      if (res.status) {
        dispatch(setErrorMessage(STANDARD_ERROR_MESSAGES[res.status]));
        dispatch(setRequestPutRequerimiento(REQUEST_STATE.ERROR));
      }
    }
  } catch (error) {
    console.error("error: ", error);
    dispatch(setErrorMessage("Ha habido un error"));
    dispatch(setRequestPutRequerimiento(REQUEST_STATE.ERROR));
  } finally {
    setTimeout(() => {
      dispatch(setRequestPutRequerimiento(REQUEST_STATE.IDLE));
    }, 500);
  }
};

export const putPropiedadProspectoVA = (id, data) => async (dispatch) => {
  dispatch(setRequestUpdateProspecto(REQUEST_STATE.LOADING));
  try {
    const res = await api.apiUpdatePropiedadProspectoVA(id, data);
    if (res && res.data) {
      if (res.data.estado === 1) {
        dispatch(setRequerimiento(res.data.data));
        dispatch(setRequestUpdateProspecto(REQUEST_STATE.SUCCESS));
      } else {
        dispatch(setErrorMessage(res.data.mensaje));
        dispatch(setRequestUpdateProspecto(REQUEST_STATE.ERROR));
      }
    } else {
      if (res.status) {
        dispatch(setErrorMessage(STANDARD_ERROR_MESSAGES[res.status]));
        dispatch(setRequestUpdateProspecto(REQUEST_STATE.ERROR));
      }
    }
  } catch (error) {
    console.error("error: ", error);
    dispatch(setErrorMessage("Ha habido un error"));
    dispatch(setRequestUpdateProspecto(REQUEST_STATE.ERROR));
  } finally {
    setTimeout(() => {
      dispatch(setRequestUpdateProspecto(REQUEST_STATE.IDLE));
    }, 500);
  }
};

export const postAgendarVisita = (data) => async (dispatch) => {
  dispatch(setRequestAgendarVisita(REQUEST_STATE.LOADING));
  try {
    const res = await api.apiPostAgendaVisitaUsuario(data);

    if (res && res.data) {
      if (res.data.estado === 1) {
        //dispatch(setVisitaAgendada(res.data.data));
        dispatch(setRequestAgendarVisita(REQUEST_STATE.SUCCESS));
      } else {
        dispatch(setErrorMessage(res.data.mensaje));
        dispatch(setRequestAgendarVisita(REQUEST_STATE.ERROR));
      }
    } else {
      if (res.status) {
        dispatch(setErrorMessage(STANDARD_ERROR_MESSAGES[res.status]));
        dispatch(setRequestAgendarVisita(REQUEST_STATE.ERROR));
      }
    }
  } catch (error) {
    console.error("error: ", error);
    dispatch(setErrorMessage("Ha habido un error"));
    dispatch(setRequestAgendarVisita(REQUEST_STATE.ERROR));
  } finally {
    setTimeout(() => {
      dispatch(setRequestAgendarVisita(REQUEST_STATE.IDLE));
    }, 500);
}
};

export const putAgendarVisita = (id, data) => async (dispatch) => {
  dispatch(setRequestPutAgendarVisita(REQUEST_STATE.LOADING));
  try {
    const res = await api.apiPutAgendaVisitaUsuario(id, data);

    if (res && res.data) {
      if (res.data.estado === 1) {
        //dispatch(setVisitaAgendada(res.data.data));
        dispatch(setRequestPutAgendarVisita(REQUEST_STATE.SUCCESS));
      } else {
        dispatch(setErrorMessage(res.data.mensaje));
        dispatch(setRequestPutAgendarVisita(REQUEST_STATE.ERROR));
      }
    } else {
      if (res.status) {
        dispatch(setErrorMessage(STANDARD_ERROR_MESSAGES[res.status]));
        dispatch(setRequestPutAgendarVisita(REQUEST_STATE.ERROR));
      }
    }
  } catch (error) {
    console.error("error: ", error);
    dispatch(setErrorMessage("Ha habido un error"));
    dispatch(setRequestPutAgendarVisita(REQUEST_STATE.ERROR));
  } finally {
    setTimeout(() => {
      dispatch(setRequestPutAgendarVisita(REQUEST_STATE.IDLE));
    }, 500);
}
};
export const cancelarVisita = (id) => async (dispatch) => {
  dispatch(setRequestCancelarVisita(REQUEST_STATE.LOADING));
  try {
    const res = await api.apiCancelarVisitaUsuario(id);

    if (res && res.data) {
      if (res.data.estado === 1) {
        //dispatch(setVisitaAgendada(res.data.data));
        dispatch(setRequestCancelarVisita(REQUEST_STATE.SUCCESS));
      } else {
        dispatch(setErrorMessage(res.data.mensaje));
        dispatch(setRequestCancelarVisita(REQUEST_STATE.ERROR));
      }
    } else {
      if (res.status) {
        dispatch(setErrorMessage(STANDARD_ERROR_MESSAGES[res.status]));
        dispatch(setRequestCancelarVisita(REQUEST_STATE.ERROR));
      }
    }
  } catch (error) {
    console.error("error: ", error);
    dispatch(setErrorMessage("Ha habido un error"));
    dispatch(setRequestCancelarVisita(REQUEST_STATE.ERROR));
  } finally {
    setTimeout(() => {
      dispatch(setRequestCancelarVisita(REQUEST_STATE.IDLE));
    }, 500);
}
};

export const generarDocumento = (tipoDocumento, data) => async (dispatch) => {
  dispatch(setRequestGenerateDocument(REQUEST_STATE.LOADING));
  try {
    const res = await api.apiGenerateDocument(tipoDocumento, data);

    if (res && res.data) {
        dispatch(setDocument(res.data));
        dispatch(setRequestGenerateDocument(REQUEST_STATE.SUCCESS));
    } else {
      if (res.status) {
        dispatch(setErrorMessage(STANDARD_ERROR_MESSAGES[res.status]));
        dispatch(setRequestGenerateDocument(REQUEST_STATE.ERROR));
      }
    }
  } catch (error) {
    console.error("error: ", error);
    dispatch(setErrorMessage("Ha habido un error"));
    dispatch(setRequestGenerateDocument(REQUEST_STATE.ERROR));
  } finally {
    setTimeout(() => {
      dispatch(setRequestGenerateDocument(REQUEST_STATE.IDLE));
    }, 500);
}
};

export const postGetValoresPreliminaresTasacion =
  (datosPropiedad, tipo) => async (dispatch) => {
  dispatch(setRequestValoresPreliminaresTasacion(REQUEST_STATE.LOADING));
  try {
    const res = await api.apiGetValoresPreliminaresTasacion(
      datosPropiedad,
      tipo
    );
    if (res && res.data) {
      if (res.data.estado === 1) {
        dispatch(setValoresPreliminaresTasacion(res.data));
        dispatch(
          setRequestValoresPreliminaresTasacion(REQUEST_STATE.SUCCESS)
        );
      } else {
        dispatch(setErrorMessage(res.data.mensaje));
        dispatch(setRequestValoresPreliminaresTasacion(REQUEST_STATE.ERROR));
      }
    } else {
      if (res.status) {
        dispatch(setErrorMessage(STANDARD_ERROR_MESSAGES[res.status]));
        dispatch(setRequestValoresPreliminaresTasacion(REQUEST_STATE.ERROR));
      }
    }
  } catch (error) {
    console.error("error: ", error);
    dispatch(setErrorMessage("Ha habido un error"));
    dispatch(setRequestValoresPreliminaresTasacion(REQUEST_STATE.ERROR));
  } finally {
    setTimeout(() => {
      dispatch(setRequestValoresPreliminaresTasacion(REQUEST_STATE.IDLE));
    }, 500);
  }
};

export const postGetTasacion = (tipo, datosPropiedad) => async (dispatch) => {
  dispatch(setRequestStateTasacion(REQUEST_STATE.LOADING));
  try {
    const res = await api.apiPostTasarPropiedad(tipo, datosPropiedad);

    if (res && res.data) {
      if (res.data.estado === 1) {
        dispatch(setResultadoTasacion(res.data));
        dispatch(setRequestStateTasacion(REQUEST_STATE.SUCCESS));
      } else {
        dispatch(setErrorMessage(res.data.mensaje));
        dispatch(setRequestStateTasacion(REQUEST_STATE.ERROR));
      }
    } else {
      if (res.status) {
        dispatch(setErrorMessage(STANDARD_ERROR_MESSAGES[res.status]));
        dispatch(setRequestStateTasacion(REQUEST_STATE.ERROR));
      }
    }
  } catch (error) {
    console.error("error: ", error);
    dispatch(setErrorMessage("Ha habido un error"));
    dispatch(setRequestStateTasacion(REQUEST_STATE.ERROR));
  } finally {
    setTimeout(() => {
      dispatch(setRequestStateTasacion(REQUEST_STATE.IDLE));
    }, 500);
  }
};

export const postGetPropiedadesSimilaresTasacion =
  (tipo, datosPropiedad, pageSize, page) => async (dispatch) => {
  dispatch(
    setRequestStatePropiedadesSimilaresTasacion(REQUEST_STATE.LOADING)
  );
  try {
    const res = await api.apiGetPropiedadesSimilaresTasacion(
      tipo,
      datosPropiedad,
      pageSize,
      page
    );

    if (res && res.data) {
      if (res.data.estado === 1) {
        dispatch(setPropiedadesSimilaresTasacion(res.data.data));
        dispatch(
          setRequestStatePropiedadesSimilaresTasacion(REQUEST_STATE.SUCCESS)
        );
      } else {
        dispatch(setErrorMessage(res.data.mensaje));
        dispatch(
          setRequestStatePropiedadesSimilaresTasacion(REQUEST_STATE.ERROR)
        );
      }
    } else {
      if (res.status) {
        dispatch(setErrorMessage(STANDARD_ERROR_MESSAGES[res.status]));
        dispatch(
          setRequestStatePropiedadesSimilaresTasacion(REQUEST_STATE.ERROR)
        );
      }
    }
  } catch (error) {
    console.error("error: ", error);
    dispatch(setErrorMessage("Ha habido un error"));
    dispatch(
      setRequestStatePropiedadesSimilaresTasacion(REQUEST_STATE.ERROR)
    );
  } finally {
    setTimeout(() => {
      dispatch(setRequestStatePropiedadesSimilaresTasacion(REQUEST_STATE.IDLE));
    }, 500);
  }
};

export const getReportes = (page, pageSize) => async (dispatch) => {
  dispatch(setRequestGetReportes(REQUEST_STATE.LOADING));
  try {
    const res = await api.apiGetReportes(page, pageSize);

    /*
if (res && res.data) {
        dispatch(setDocument(res.data));
        dispatch(setRequestGenerateDocument(REQUEST_STATE.SUCCESS));
    } else {
      if (res.status) {
        dispatch(setErrorMessage(STANDARD_ERROR_MESSAGES[res.status]));
        dispatch(setRequestGenerateDocument(REQUEST_STATE.ERROR));
      }
    }
    */
    if (res && res.data) {
      if (res.data.estado === 1) {
        dispatch(setReportes(res.data.data));
        dispatch(setRequestGetReportes(REQUEST_STATE.SUCCESS));
      } else {
        dispatch(setErrorMessage(res.data.mensaje));
        dispatch(setRequestGetReportes(REQUEST_STATE.ERROR));
      }
    } else {
      if (res.status) {
        dispatch(setErrorMessage(STANDARD_ERROR_MESSAGES[res.status]));
        dispatch(setRequestGetReportes(REQUEST_STATE.ERROR));
      }
    }
  } catch (error) {
    console.error("error: ", error);
    dispatch(setErrorMessage("Ha habido un error"));
    dispatch(setRequestGetReportes(REQUEST_STATE.ERROR));
  } finally {
    setTimeout(() => {
      dispatch(setRequestGetReportes(REQUEST_STATE.IDLE));
    }, 500);
  }
};

export const postReporte = (data) => async (dispatch) => {
  dispatch(setRequestPostReporte(REQUEST_STATE.LOADING));
  try {
    const res = await api.apiPostReporte(data);

    if (res && res.data) {
      if (res.data.estado === 1) {
        dispatch(setReporte(res.data.data));
        dispatch(setRequestPostReporte(REQUEST_STATE.SUCCESS));
      } else {
        dispatch(setErrorMessage(res.data.mensaje));
        dispatch(setRequestPostReporte(REQUEST_STATE.ERROR));
      }
    } else {
      if (res.status) {
        dispatch(setErrorMessage(STANDARD_ERROR_MESSAGES[res.status]));
        dispatch(setRequestPostReporte(REQUEST_STATE.ERROR));
      }
    }
  } catch (error) {
    console.error("error: ", error);
    dispatch(setErrorMessage("Ha habido un error"));
    dispatch(setRequestPostReporte(REQUEST_STATE.ERROR));
  } finally {
    setTimeout(() => {
      dispatch(setRequestPostReporte(REQUEST_STATE.IDLE));
    }, 500);
  }
};

export const deleteProspecto = (tipoProspecto, id) => async (dispatch) => {
  dispatch(setRequestDeleteProspecto(REQUEST_STATE.LOADING));
  try {
    const res = await api.apiDeleteProspecto(tipoProspecto, id);
    if (res && res.data) {
      if (res.data.estado === 1) {
        dispatch(setRequestDeleteProspecto(REQUEST_STATE.SUCCESS));
      } else {
        dispatch(setErrorMessage(res.data.mensaje));
        dispatch(setRequestDeleteProspecto(REQUEST_STATE.ERROR));
      }
    } else {
      if (res.status) {
        dispatch(setErrorMessage(STANDARD_ERROR_MESSAGES[res.status]));
        dispatch(setRequestDeleteProspecto(REQUEST_STATE.ERROR));
      }
    }
  } catch (error) {
    console.error("error: ", error);
    dispatch(setErrorMessage("Ha habido un error"));
    dispatch(setRequestDeleteProspecto(REQUEST_STATE.ERROR));
  } finally {
    setTimeout(() => {
      dispatch(setRequestDeleteProspecto(REQUEST_STATE.IDLE));
    }, 500);
  }
};

export const getGraficas = (page, pageSize) => async (dispatch) => {
  dispatch(setRequestGetGraficas(REQUEST_STATE.LOADING));
  try {
    const res = await api.apiGetGraficas(page, pageSize);
    if (res && res.data) {
      if (res.data.estado === 1) {
        dispatch(setGraficas(res.data.data));
        dispatch(setRequestGetGraficas(REQUEST_STATE.SUCCESS));
      } else {
        dispatch(setErrorMessage(res.data.mensaje));
        dispatch(setRequestGetGraficas(REQUEST_STATE.ERROR));
      }
    } else {
      if (res.status) {
        dispatch(setErrorMessage(STANDARD_ERROR_MESSAGES[res.status]));
        dispatch(setRequestGetGraficas(REQUEST_STATE.ERROR));
      }
    }
  } catch (error) {
    console.error("error: ", error);
    dispatch(setErrorMessage("Ha habido un error"));
    dispatch(setRequestGetGraficas(REQUEST_STATE.ERROR));
  } finally {
    setTimeout(() => {
      dispatch(setRequestGetGraficas(REQUEST_STATE.IDLE));
    }, 500);
  }
};

export const getGrafica = (id) => async (dispatch) => {
  dispatch(setRequestGetGrafica(REQUEST_STATE.LOADING));
  try {
    const res = await api.apiGetGrafica(id);
    if (res && res.data) {
      if (res.data.estado === 1) {
        dispatch(setGrafica(res.data.data));
        dispatch(setRequestGetGrafica(REQUEST_STATE.SUCCESS));
      } else {
        dispatch(setErrorMessage(res.data.mensaje));
        dispatch(setRequestGetGrafica(REQUEST_STATE.ERROR));
      }
    } else {
      if (res.status) {
        dispatch(setErrorMessage(STANDARD_ERROR_MESSAGES[res.status]));
        dispatch(setRequestGetGrafica(REQUEST_STATE.ERROR));
      }
    }
  } catch (error) {
    console.error("error: ", error);
    dispatch(setErrorMessage("Ha habido un error"));
    dispatch(setRequestGetGrafica(REQUEST_STATE.ERROR));
  } finally {
    setTimeout(() => {
      dispatch(setRequestGetGrafica(REQUEST_STATE.IDLE));
    }, 500);
  }
};

export const addGrafica = (data) => async (dispatch) => {
  dispatch(setRequestPostGrafica(REQUEST_STATE.LOADING));
  try {
    const res = await api.apiPostGrafica(data);
    if (res && res.data) {
      if (res.data.estado === 1) {
        dispatch(setGrafica(res.data.data));
        dispatch(setRequestPostGrafica(REQUEST_STATE.SUCCESS));
      } else {
        dispatch(setErrorMessage(res.data.mensaje));
        dispatch(setRequestPostGrafica(REQUEST_STATE.ERROR));
      }
    } else {
      if (res.status) {
        dispatch(setErrorMessage(STANDARD_ERROR_MESSAGES[res.status]));
        dispatch(setRequestPostGrafica(REQUEST_STATE.ERROR));
      }
    }
  } catch (error) {
    console.error("error: ", error);
    dispatch(setErrorMessage("Ha habido un error"));
    dispatch(setRequestPostGrafica(REQUEST_STATE.ERROR));
  } finally {
    setTimeout(() => {
      dispatch(setRequestPostGrafica(REQUEST_STATE.IDLE));
    }, 500);
  }
};

export const getCapacitacion = (id) => async (dispatch) => {
  dispatch(setRequestGetCapacitacion(REQUEST_STATE.LOADING));
  try {
    const res = await api.apiGetCapacitacion(id);
    if (res && res.data) {
      if (res.data.estado === 1) {
        dispatch(setCapacitacion(res.data.data));
        dispatch(setRequestGetCapacitacion(REQUEST_STATE.SUCCESS));
      } else {
        dispatch(setErrorMessage(res.data.mensaje));
        dispatch(setRequestGetCapacitacion(REQUEST_STATE.ERROR));
      }
    } else {
      if (res.status) {
        dispatch(setErrorMessage(STANDARD_ERROR_MESSAGES[res.status]));
        dispatch(setRequestGetCapacitacion(REQUEST_STATE.ERROR));
      }
    }
  } catch (error) {
    console.error("error: ", error);
    dispatch(setErrorMessage("Ha habido un error"));
    dispatch(setRequestGetCapacitacion(REQUEST_STATE.ERROR));
  } finally {
    setTimeout(() => {
      dispatch(setRequestGetCapacitacion(REQUEST_STATE.IDLE));
    }, 500);
  }
};

export const getCapacitaciones = (page, pageSize, categoria) => async (dispatch) => {
  dispatch(setRequestGetCapacitaciones(REQUEST_STATE.LOADING));
  try {
    const res = await api.apiGetCapacitaciones(page, pageSize, categoria);
    if (res && res.data) {
      if (res.data.estado === 1) {
        dispatch(setCapacitaciones(res.data.data));
        dispatch(setRequestGetCapacitaciones(REQUEST_STATE.SUCCESS));
      } else {
        dispatch(setErrorMessage(res.data.mensaje));
        dispatch(setRequestGetCapacitaciones(REQUEST_STATE.ERROR));
      }
    } else {
      if (res.status) {
        dispatch(setErrorMessage(STANDARD_ERROR_MESSAGES[res.status]));
        dispatch(setRequestGetCapacitaciones(REQUEST_STATE.ERROR));
      }
    }
  } catch (error) {
    console.error("error: ", error);
    dispatch(setErrorMessage("Ha habido un error"));
    dispatch(setRequestGetCapacitaciones(REQUEST_STATE.ERROR));
  } finally {
    setTimeout(() => {
      dispatch(setRequestGetCapacitaciones(REQUEST_STATE.IDLE));
    }, 500);
  }
};

export const postCapacitacion = (data) => async (dispatch) => {
  dispatch(setRequestPostCapacitacion(REQUEST_STATE.LOADING));
  try {
    const res = await api.apiPostCapacitacion(data);
    if (res && res.data) {
      if (res.data.estado === 1) {
        dispatch(setCapacitacion(res.data.data));
        dispatch(setRequestPostCapacitacion(REQUEST_STATE.SUCCESS));
      } else {
        dispatch(setErrorMessage(res.data.mensaje));
        dispatch(setRequestPostCapacitacion(REQUEST_STATE.ERROR));
      }
    } else {
      if (res.status) {
        dispatch(setErrorMessage(STANDARD_ERROR_MESSAGES[res.status]));
        dispatch(setRequestPostCapacitacion(REQUEST_STATE.ERROR));
      }
    }
  } catch (error) {
    console.error("error: ", error);
    dispatch(setErrorMessage("Ha habido un error"));
    dispatch(setRequestPostCapacitacion(REQUEST_STATE.ERROR));
  } finally {
    setTimeout(() => {
      dispatch(setRequestPostCapacitacion(REQUEST_STATE.IDLE));
    }, 500);
  }
};

export const putCapacitacion = (id, data) => async (dispatch) => {
  dispatch(setRequestPutCapacitacion(REQUEST_STATE.LOADING));
  try {
    const res = await api.apiPutCapacitacion(id, data);
    if (res && res.data) {
      if (res.data.estado === 1) {
        dispatch(setCapacitacion(res.data.data));
        dispatch(setRequestPutCapacitacion(REQUEST_STATE.SUCCESS));
      } else {
        dispatch(setErrorMessage(res.data.mensaje));
        dispatch(setRequestPutCapacitacion(REQUEST_STATE.ERROR));
      }
    } else {
      if (res.status) {
        dispatch(setErrorMessage(STANDARD_ERROR_MESSAGES[res.status]));
        dispatch(setRequestPutCapacitacion(REQUEST_STATE.ERROR));
      }
    }
  } catch (error) {
    console.error("error: ", error);
    dispatch(setErrorMessage("Ha habido un error"));
    dispatch(setRequestPutCapacitacion(REQUEST_STATE.ERROR));
  } finally {
    setTimeout(() => {
      dispatch(setRequestPutCapacitacion(REQUEST_STATE.IDLE));
    }, 500);
  }
};

export const deleteCapacitacion = (id) => async (dispatch) => {
  dispatch(setRequestDeleteCapacitacion(REQUEST_STATE.LOADING));
  try {
    const res = await api.apiDeleteCapacitacion(id);
    if (res && res.data) {
      if (res.data.estado === 1) {
        dispatch(setCapacitacion(res.data.data));
        dispatch(setRequestDeleteCapacitacion(REQUEST_STATE.SUCCESS));
      } else {
        dispatch(setErrorMessage(res.data.mensaje));
        dispatch(setRequestDeleteCapacitacion(REQUEST_STATE.ERROR));
      }
    } else {
      if (res.status) {
        dispatch(setErrorMessage(STANDARD_ERROR_MESSAGES[res.status]));
        dispatch(setRequestDeleteCapacitacion(REQUEST_STATE.ERROR));
      }
    }
  } catch (error) {
    console.error("error: ", error);
    dispatch(setErrorMessage("Ha habido un error"));
    dispatch(setRequestDeleteCapacitacion(REQUEST_STATE.ERROR));
  } finally {
    setTimeout(() => {
      dispatch(setRequestDeleteCapacitacion(REQUEST_STATE.IDLE));
    }, 500);
  }
};

export const solicitarCanje = (idPropiedad) => async (dispatch) => {
  dispatch(setRequestSolicitarCanje(REQUEST_STATE.LOADING));
  try {
    const res = await api.apiSolicitarCanje(idPropiedad);
    if (res && res.data) {
      if (res.data.estado === 1) {
        dispatch(setRequestSolicitarCanje(REQUEST_STATE.SUCCESS));
      } else {
        dispatch(setErrorMessage(res.data.mensaje));
        dispatch(setRequestSolicitarCanje(REQUEST_STATE.ERROR));
      }
    } else {
      if (res.status) {
        dispatch(setErrorMessage(STANDARD_ERROR_MESSAGES[res.status]));
        dispatch(setRequestSolicitarCanje(REQUEST_STATE.ERROR));
      }
    }
  } catch (error) {
    console.error("error: ", error);
    dispatch(setErrorMessage("Ha habido un error"));
    dispatch(setRequestSolicitarCanje(REQUEST_STATE.ERROR));
  } finally {
    setTimeout(() => {
      dispatch(setRequestSolicitarCanje(REQUEST_STATE.IDLE));
    }, 500);
  }
};

export const descargarVistaPreviaDocumento = (tipoDocumento) => async (dispatch) => {
  dispatch(setRequestPreviewDocument(REQUEST_STATE.LOADING));
  try {
    const res = await api.apiDescargarVistaPreviaDocumento(tipoDocumento);
    dispatch(setPreviewDocument(res));
    dispatch(setRequestPreviewDocument(REQUEST_STATE.SUCCESS));
  } catch (error) {
    console.error("error: ", error);
    dispatch(setRequestPreviewDocument(REQUEST_STATE.ERROR));
  } finally {
    dispatch(setRequestPreviewDocument(REQUEST_STATE.IDLE));
  }
};

export const exportarTasacion = (data, tipo) => async (dispatch) => {
  dispatch(setRequestExportTasacion(REQUEST_STATE.LOADING));
  try {
    const res = await api.apiExportarTasacion(data, tipo);
    dispatch(setExportTasacion(res));
    dispatch(setRequestExportTasacion(REQUEST_STATE.SUCCESS));
  } catch (error) {
    console.error("error: ", error);
    dispatch(setRequestExportTasacion(REQUEST_STATE.ERROR));
  } finally {
    dispatch(setRequestExportTasacion(REQUEST_STATE.IDLE));
  }
};

export const descargarFichaPropiedad = (idPropiedad) => async (dispatch) => {
  dispatch(setRequestDescargaFichaPropiedad(REQUEST_STATE.LOADING));
  try {
    const res = await api.apiDescargarFichaPropiedad(idPropiedad);
    dispatch(setFichaPropiedad(res));
    dispatch(setRequestDescargaFichaPropiedad(REQUEST_STATE.SUCCESS));
  } catch (error) {
    console.error("error: ", error);
    dispatch(setRequestDescargaFichaPropiedad(REQUEST_STATE.ERROR));
  } finally {
    dispatch(setRequestDescargaFichaPropiedad(REQUEST_STATE.IDLE));
  }
};

export const agendarVisitaReunion = (data) => async (dispatch) => {
  dispatch(setRequestAgendarVisita(REQUEST_STATE.LOADING));
  try {
    const res = await api.apiAgendarVisitaReunion(data);
    if (res && res.data) {
      if (res.data.estado === 1) {
        dispatch(setRequestAgendarVisita(REQUEST_STATE.SUCCESS));
      } else {
        dispatch(setErrorMessage(res.data.mensaje));
        dispatch(setRequestAgendarVisita(REQUEST_STATE.ERROR));
      }
    } else {
      if (res.status) {
        dispatch(setErrorMessage(STANDARD_ERROR_MESSAGES[res.status]));
        dispatch(setRequestAgendarVisita(REQUEST_STATE.ERROR));
      }
    }
  } catch (error) {
    console.error("error: ", error);
    dispatch(setErrorMessage("Ha habido un error"));
    dispatch(setRequestAgendarVisita(REQUEST_STATE.ERROR));
  } finally {
    setTimeout(() => {
      dispatch(setRequestAgendarVisita(REQUEST_STATE.IDLE));
    }, 500);
  }
};

export const getReuniones = (idBroker) => async (dispatch) => {
  dispatch(setRequestGetReuniones(REQUEST_STATE.LOADING));
  try {
    const res = await api.apiGetReuniones(idBroker);
    if (res && res.data) {
      if (res.data.estado === 1) {
        dispatch(setRequestGetReuniones(REQUEST_STATE.SUCCESS));
        dispatch(setReuniones(res.data.data));
      } else {
        dispatch(setErrorMessage(res.data.mensaje));
        dispatch(setRequestGetReuniones(REQUEST_STATE.ERROR));
      }
    } else {
      if (res.status) {
        dispatch(setErrorMessage(STANDARD_ERROR_MESSAGES[res.status]));
        dispatch(setRequestGetReuniones(REQUEST_STATE.ERROR));
      }
    }
  } catch (error) {
    console.error("error: ", error);
    dispatch(setErrorMessage("Ha habido un error"));
    dispatch(setRequestGetReuniones(REQUEST_STATE.ERROR));
  } finally {
    dispatch(setRequestGetReuniones(REQUEST_STATE.IDLE));
  }
};

export const actualizarVisitaReunion = (data) => async (dispatch) => {
  dispatch(setRequestActualizarReunion(REQUEST_STATE.LOADING));
  try {
    const res = await api.apiActualizarVisitaReunion(data);
    if (res && res.data) {
      if (res.data.estado === 1) {
        dispatch(setRequestActualizarReunion(REQUEST_STATE.SUCCESS));
      } else {
        dispatch(setErrorMessage(res.data.mensaje));
        dispatch(setRequestActualizarReunion(REQUEST_STATE.ERROR));
      }
    } else {
      if (res.status) {
        dispatch(setErrorMessage(STANDARD_ERROR_MESSAGES[res.status]));
        dispatch(setRequestActualizarReunion(REQUEST_STATE.ERROR));
      }
    }
  } catch (error) {
    console.error("error: ", error);
    dispatch(setErrorMessage("Ha habido un error"));
    dispatch(setRequestActualizarReunion(REQUEST_STATE.ERROR));
  } finally {
    setTimeout(() => {
      dispatch(setRequestActualizarReunion(REQUEST_STATE.IDLE));
    }, 500);
  }
};

export const getProyectosPaginados = (page, pageSize) => async (dispatch) => {
  dispatch(setRequestGetProyectos(REQUEST_STATE.LOADING));
  try {
    const res = await api.apiGetProyectosPaginados(page, pageSize);
    if (res && res.data) {
      if (res.data.estado === 1) {
        dispatch(setProyectos(res.data.data));
        dispatch(setRequestGetProyectos(REQUEST_STATE.SUCCESS));
      } else {
        dispatch(setErrorMessage(res.data.mensaje));
        dispatch(setRequestGetProyectos(REQUEST_STATE.ERROR));
      }
    } else {
      if (res.status) {
        dispatch(setErrorMessage(STANDARD_ERROR_MESSAGES[res.status]));
        dispatch(setRequestGetProyectos(REQUEST_STATE.ERROR));
      }
    }
  } catch (error) {
    console.error("error: ", error);
    dispatch(setErrorMessage("Ha habido un error"));
    dispatch(setRequestGetProyectos(REQUEST_STATE.ERROR));
  } finally {
    setTimeout(() => {
      dispatch(setRequestGetProyectos(REQUEST_STATE.IDLE));
    }, 500);
  }
};

export const getProyecto = (id) => async (dispatch) => {
  dispatch(setRequestGetProyecto(REQUEST_STATE.LOADING));
  try {
    const res = await api.apiGetProyecto(id);
    if (res && res.data) {
      if (res.data.estado === 1) {
        dispatch(setProyecto(res.data.data));
        dispatch(setRequestGetProyecto(REQUEST_STATE.SUCCESS));
      } else {
        dispatch(setErrorMessage(res.data.mensaje));
        dispatch(setRequestGetProyecto(REQUEST_STATE.ERROR));
      }
    } else {
      if (res.status) {
        dispatch(setErrorMessage(STANDARD_ERROR_MESSAGES[res.status]));
        dispatch(setRequestGetProyecto(REQUEST_STATE.ERROR));
      }
    }
  } catch (error) {
    console.error("error: ", error);
    dispatch(setErrorMessage("Ha habido un error"));
    dispatch(setRequestGetProyecto(REQUEST_STATE.ERROR));
  } finally {
    setTimeout(() => {
      dispatch(setRequestGetProyecto(REQUEST_STATE.IDLE));
    }, 500);
  }
};

export const uploadArchivoProspectos = (file) => async (dispatch) => {
  dispatch(setRequestUploadArchivoProspectos(REQUEST_STATE.LOADING));
  try {
    const res = await api.apiUploadArchivoProspectos(file);
    if (res && res.data) {
      if (res.data.estado === 1) {
        dispatch(setRequestUploadArchivoProspectos(REQUEST_STATE.SUCCESS));
      } else {
        dispatch(setErrorMessage(res.data.mensaje));
        dispatch(setRequestUploadArchivoProspectos(REQUEST_STATE.ERROR));
      }
    } else {
      if (res.status) {
        dispatch(setErrorMessage(STANDARD_ERROR_MESSAGES[res.status]));
        dispatch(setRequestUploadArchivoProspectos(REQUEST_STATE.ERROR));
      }
    }
  } catch (error) {
    console.error("error: ", error);
    dispatch(setErrorMessage("Ha habido un error"));
    dispatch(setRequestUploadArchivoProspectos(REQUEST_STATE.ERROR));
  } finally {
    setTimeout(() => {
      dispatch(setRequestUploadArchivoProspectos(REQUEST_STATE.IDLE));
    }, 500);
  }
};

export const getMetas = (periodo) => async (dispatch) => {
  dispatch(setRequestGetMetas(REQUEST_STATE.LOADING));
  try {
    const res = await api.apiGetMetas(periodo);
    if (res && res.data) {
      if (res.data.estado === 1) {
        console.log("si!")
        console.log(res.data.data)
        dispatch(setAsdf(res.data.data));
        dispatch(setMetas(res.data.data));
        dispatch(setRequestGetMetas(REQUEST_STATE.SUCCESS));
      } else {
        dispatch(setErrorMessage(res.data.mensaje));
        dispatch(setRequestGetMetas(REQUEST_STATE.ERROR));
      }
    } else {
      if (res.status) {
        dispatch(setErrorMessage(STANDARD_ERROR_MESSAGES[res.status]));
        dispatch(setRequestGetMetas(REQUEST_STATE.ERROR));
      }
    }
  } catch (error) {
    console.error("error: ", error);
    dispatch(setErrorMessage("Ha habido un error"));
    dispatch(setRequestGetMetas(REQUEST_STATE.ERROR));
  } finally {
    setTimeout(() => {
      dispatch(setRequestGetMetas(REQUEST_STATE.IDLE));
    }, 500);
  }
};

export const uploadArchivoMetas = (file, periodo) => async (dispatch) => {
  dispatch(setRequestUploadArchivoMetas(REQUEST_STATE.LOADING));
  try {
    const res = await api.apiUploadArchivoMetas(file, periodo);
    if (res && res.data) {
      if (res.data.estado === 1) {
        dispatch(setRequestUploadArchivoMetas(REQUEST_STATE.SUCCESS));
      } else {
        dispatch(setErrorMessage(res.data.mensaje));
        dispatch(setRequestUploadArchivoMetas(REQUEST_STATE.ERROR));
      }
    } else {
      if (res.status) {
        dispatch(setErrorMessage(STANDARD_ERROR_MESSAGES[res.status]));
        dispatch(setRequestUploadArchivoMetas(REQUEST_STATE.ERROR));
      }
    }
  } catch (error) {
    console.error("error: ", error);
    dispatch(setErrorMessage("Ha habido un error"));
    dispatch(setRequestUploadArchivoMetas(REQUEST_STATE.ERROR));
  } finally {
    setTimeout(() => {
      dispatch(setRequestUploadArchivoMetas(REQUEST_STATE.IDLE));
    }, 500);
  }
};
