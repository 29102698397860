import { Box, Card, CardContent, Grid } from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { fetchClearUserSession, setComunas } from "../../action";

const style = {
  position: "absolute",
  top: "30%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  p: 4,
};

const Logout = () => {
  const dispatch = useDispatch();
  dispatch(setComunas(new Set()));
  localStorage.removeItem("user");
  localStorage.removeItem("bearer");

  localStorage.removeItem("filtroPropiedadTipoOperacion");
  localStorage.removeItem("filtroPropiedadTipoPropiedad");
  localStorage.removeItem("filtroPropiedadComuna");
  localStorage.removeItem("filtroPropiedadCodigo");
  localStorage.removeItem("filtroPropiedadEstado");
  localStorage.removeItem("filtroPropiedadTipoMoneda");
  localStorage.removeItem("filtroPropiedadBuscador");

  const user = localStorage.getItem("user");
  const history = useHistory();
  dispatch(fetchClearUserSession());
  useEffect(() => {
    if (user === null) {
      setTimeout(() => {
        history.push("/login");
      }, 500);
    }
  }, [user]);

  return (
    <Box className="sign-in">
      <Box sx={style}>
        <Card variant="outlined" sx={{ border: "none" }}>
          <React.Fragment>
            <CardContent>
              <Grid container justifyContent={"center"}>
                <Grid item>Cerrando sesión...</Grid>
              </Grid>
            </CardContent>
          </React.Fragment>
        </Card>
      </Box>
    </Box>
  );
};

export default Logout;