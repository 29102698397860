import { Edit } from "@mui/icons-material";
import {
  Chip,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import { useState } from "react";
import { useSelector } from "react-redux";
import FormRequerimiento from "./FormRequerimiento";
import utilsFunc from "../../../utils/utilsFunctions";
import ListOtrasPropiedades from "../../Propiedades/ListOtrasPropiedades";

const isMobile =
  !!navigator.userAgent.match(/iphone|android|blackberry/gi) || false;

const columns = [
  { id: "origen", label: "Origen", minWidth: 100, align: "center" },
  { id: "operacion", label: "Operación", minWidth: 100, align: "center" },
  { id: "precio", label: "Precio", minWidth: 100, align: "center" },
  { id: "comuna", label: "Comuna", minWidth: 140, align: "center" },
  { id: "tipo", label: "Tipo", minWidth: 140, align: "center" },
  { id: "tipologia", label: "Tipología", minWidth: 80, align: "center" },
  { id: "ficha", label: "Ficha", minWidth: 30, align: "center" },
];

const RequerimientoItem = ({ requerimiento, reloadMethod }) => {
  const [openTab, setOpenTab] = useState("none");
  const [isOpenEditModal, setIsOpenEditModal] = useState(false);
  const handleCloseEditModal = () => setIsOpenEditModal(false);
  const regiones = useSelector(
    (state) =>
      state.app.itemRegiones &&
      state.app.itemRegiones.length > 0 &&
      state.app.itemRegiones[0].regiones
  );

  const { formatToThousandSeparator } = utilsFunc;

  if (requerimiento) {
    return (
      <div>
        <Grid
          container
          justifyContent={"space-between"}
          sx={{
            borderTop: "1px solid #CDCDCD",
            borderLeft: "1px solid #CDCDCD",
            borderRight: "1px solid #CDCDCD",
            borderRadius: "1px 1px 0px 0px",
            p: 2,
            mt: 2,
          }}
        >
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Grid container justifyContent={"space-between"} spacing={0}>
              <Grid item xs={6} sm={4} md={4} lg={2} xl={2}>
                <Grid container justifyContent={"space-between"}>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <label className="otrolabel">Tipo propiedad</label>
                    <label className="otrolabel2">
                      {requerimiento.tipoPropiedad}
                    </label>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <label className="otrolabel">Operación</label>
                    <label className="otrolabel2">
                      {requerimiento.tipoOperacion === 0 ? "Arriendo" : "Venta"}
                    </label>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={6} sm={4} md={4} lg={2} xl={2}>
                <Grid container justifyContent={"space-between"}>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <label className="otrolabel">Dormitorios</label>
                    <label className="otrolabel2">
                      {requerimiento.dormitorios}
                    </label>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <label className="otrolabel">Baños</label>
                    <label className="otrolabel2">{requerimiento.banios}</label>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={6} sm={4} md={4} lg={2} xl={2}>
                <Grid container justifyContent={"space-between"}>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <label className="otrolabel">Estacionamientos</label>
                    <label className="otrolabel2">
                      {requerimiento.estacionamientos}
                    </label>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <label className="otrolabel">Precio</label>
                    <label className="otrolabel2">
                      {formatToThousandSeparator(requerimiento.precio)}{" "}
                      {requerimiento.tipoMoneda}
                    </label>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={6} sm={4} md={4} lg={2} xl={2}>
                <Grid container justifyContent={"space-between"}>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <label className="otrolabel">Superficie total</label>
                    <label className="otrolabel2">
                      {requerimiento.superficieTotal}
                    </label>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <label className="otrolabel">Superficie útil</label>
                    <label className="otrolabel2">
                      {requerimiento.superficieUtil}
                    </label>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={6} sm={4} md={4} lg={2} xl={2}>
                <label className="otrolabel">Comunas</label>
                <label className="tipoMatch">
                  {requerimiento.comunaPreferencialUno}
                </label>
                <label className="tipoMatch">
                  {requerimiento.comunaPreferencialDos}
                </label>
                <label className="tipoMatch">
                  {requerimiento.comunaPreferencialTres}
                </label>
              </Grid>

              <Grid item xs={6} sm={4} md={4} lg={2} xl={2}>
                <Grid container justifyContent={"center"}>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <label className="otrolabel">Región</label>
                    <label className="otrolabel2">
                      {regiones &&
                        regiones.some(
                          (r) => r.numero === requerimiento.idRegion
                        ) &&
                        regiones.find((r) => r.numero === requerimiento.idRegion)
                          .region}
                    </label>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Tooltip title="Editar" aria-label="editar">
                      <IconButton
                        color="primary"
                        aria-label="editar"
                        component="span"
                        onClick={() => setIsOpenEditModal(true)}
                      >
                        <Edit />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid container justifyContent={"space-between"}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}></Grid>
            </Grid>
          </Grid>
        </Grid>
        <div className="botones">
          <button
            className={
              requerimiento.propiedadesMatch &&
              requerimiento.propiedadesMatch.length > 0
                ? "botonMatch"
                : "botonMatchDis"
            }
            disabled={
              !(
                requerimiento.propiedadesMatch &&
                requerimiento.propiedadesMatch.length > 0
              )
            }
            onClick={() =>
              openTab === "matches" ? setOpenTab("") : setOpenTab("matches")
            }
            style={{ width: "100%" }}
          >
            Match propiedades
            <span className="bubble">
              {!requerimiento.propiedadesMatch ||
              requerimiento.propiedadesMatch.length === 0
                ? "0"
                : requerimiento.propiedadesMatch.length}
            </span>
          </button>
          <button
            onClick={() => {
              openTab === "search" ? setOpenTab("") : setOpenTab("search");
            }}
            //className={buscar ? "botonMatch" : "botonMatchDis"}
            style={{ width: "100%" }}
          >
            Buscar propiedades
          </button>
        </div>
        <div
        //className={matches ? "matches" : "noMostrar"}
        >
          {openTab === "matches" &&
            (!requerimiento.propiedadesMatch ||
              requerimiento.propiedadesMatch.length === 0) && (
              <div>
                No se encontraron propiedades similares al requerimiento
              </div>
            )}
          {openTab === "matches" && requerimiento.propiedadesMatch && (
            <Paper
              sx={{
                width: "100%",
                overflow: "auto",
                maxHeight: isMobile ? "400px" : "400px",
              }}
            >
              <TableContainer>
                <Table
                  stickyHeader
                  aria-label="sticky table"
                  size={isMobile ? "small" : "medium"}
                >
                  <TableHead>
                    <TableRow>
                      {columns.map((column) => (
                        <TableCell
                          key={column.id}
                          align={column.align}
                          style={{ minWidth: column.minWidth }}
                        >
                          {column.label}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {requerimiento.propiedadesMatch.length > 0 &&
                      requerimiento.propiedadesMatch.map((m) => (
                        <TableRow>
                          <TableCell align="center">
                            <Chip
                              label={m.origen}
                              color={
                                m.origen && m.origen.toLowerCase() === "propins"
                                  ? "success"
                                  : "default"
                              }
                            />
                          </TableCell>
                          <TableCell align="center">
                            {requerimiento.tipoOperacion === 0
                              ? "Arriendo"
                              : "Venta"}
                          </TableCell>
                          <TableCell align="center">{m.precio}</TableCell>
                          <TableCell align="center">{m.comuna}</TableCell>
                          <TableCell align="center">
                            {requerimiento.tipoPropiedad}
                          </TableCell>
                          <TableCell align="center">
                            {m.dormitorios}D {m.banios}B
                          </TableCell>
                          <TableCell align="center">
                            <a className="link" href={`${m.link}`}>
                              Ver
                            </a>
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          )}
          {openTab === "search" && <ListOtrasPropiedades />}
        </div>
        <FormRequerimiento
          isOpen={isOpenEditModal}
          idProspecto={requerimiento.idProspecto}
          handleClose={handleCloseEditModal}
          reloadMethod={reloadMethod}
          initialValues={requerimiento}
        />
      </div>
    );
  } else {
    return <div></div>;
  }
};

export default RequerimientoItem;
